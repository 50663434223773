import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getPublicBotDeals = async (botUuid: string) => {
  
  const endPoint = 'bot/getPublicBotTrades';
    
  const requestData = {
    botUuid,
    page: 1,
  };
    
  return fetchPaginatedSmartTrades(axios, endPoint, requestData);
};
