import { CSSProperties } from 'react';

export const purchase: CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
  maxWidth: 254,
  padding: '16px 0',
  textAlign: 'center',
  height: 'auto',
  alignSelf: 'center',
  flex: 1,
  width: '100%',
};
