import { IPieChart } from '../interfaces';

export const getSeries = (data: IPieChart['data'], selectedCurrency: string): echarts.SeriesOption[] => {

  const isBtc = selectedCurrency === 'BTC';
  
  const series: echarts.SeriesOption[] = [{
    type: 'pie',
    radius: [
      '60%',
      '93%',
    ],
    avoidLabelOverlap: false,
    padAngle: 0.2,
    itemStyle: {
      borderRadius: 2,
      borderColor: 'transparent',
      borderWidth: 1,
    },
    label: {
      show: true,
      position: 'inside',
      fontSize: 8,
      fontWeight: 'lighter',
      color: '#fff',
      formatter: (params) => {
        //@ts-ignore
        if ((isBtc ? params?.data.overallBtcShare?.toFixed(1) : params?.data.overallUsdtShare?.toFixed(1)) > 5) {
          return params.name;
        }

        return '';
      },
    },
    labelLine: {
      show: false,
    },
    data,
  },
  ];

  return series;
};
