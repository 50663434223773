import dayjs, { Dayjs } from 'dayjs';
import { TSubscription } from '../types';

export const getSubscriptionsByDate = (subscriptions: TSubscription[], dates: [Dayjs | null, Dayjs | null]) => {
  return subscriptions.filter((subscription: TSubscription) => {
    const selectedStartDate = dayjs(dates[0]).startOf('day');
    const selectedEndDate = dayjs(dates[1]).endOf('day');

    const dateStart = dayjs(subscription.paymentDate || subscription.createdDate);
    const dateEnd = dayjs(subscription.finishedDate);

    return dateStart.isSame(selectedStartDate, 'day') && dateEnd.isSame(selectedEndDate, 'day');
  });
};
