import { CSSProperties } from 'react';

export const modal: CSSProperties = {
  width: '100%',
  maxWidth: 1000,
  // backgroundColor: '#F8FBF9',
  borderRadius: '16px',
  overflow: 'hidden',
  padding: '24px 10px',
};
