import { 
  getChange24h,
  getChangeColor, 
  getChangeIcon, 
  getChangeText, 
  getTotalAssets, 
  getValueText,
} from 'widgets/area-chart/helpers/chart-helpers';

interface GraphicProps {
  totalUsdt: number;
  totalBtc: number;
  usdtDailyChangePercent: string;
  btcDailyChangePercent: string; 
  selectedCurrency: string;
}

export const getGraphic = (props: GraphicProps) => {
  const {
    totalBtc, 
    totalUsdt,
    usdtDailyChangePercent, 
    btcDailyChangePercent,
    selectedCurrency,
  } = props;
  
  const totalAssets = getTotalAssets(totalBtc, totalUsdt, selectedCurrency);
  const change24h = getChange24h(usdtDailyChangePercent, btcDailyChangePercent, selectedCurrency);
  const changeIcon = getChangeIcon(change24h);
  const changeColor = getChangeColor(change24h);
  const valueText = getValueText(totalAssets, selectedCurrency);
  const changeText = getChangeText(change24h);
  
  const graphic: echarts.GraphicComponentOption[] = [{
    type: 'text',
    left: 'center',
    top: 'center',
    z: 100,
    style: {
      text: `{title|Total Assets}\n{value|${valueText}}\n{title|Change 24h}\n${change24h ? '{icon|}' : ''}{change|${changeText}}`,
      rich: {
        title: {
          fontSize: 12,
          fontWeight: 400,
          align: 'center',
          fill: '#9E9E9E',
          padding: [5, 0, 4, 0],
        },
        value: {
          fontSize: 14,
          fontWeight: 600,
          fill: '#505053',
          padding: [0, 0, 10, 0],
          align: 'center',
        },
        change: {
          fontSize: 14,
          fontWeight: 400,
          align: 'center',
          fill: changeColor,
          padding: [4, 0, 0, 0],
        },
        ...(changeIcon && {
          icon: {
            height: 16,
            width: 16,
            backgroundColor: {
              image: changeIcon,
            },
            align: 'center',
          },
        }),
      },
      fill: '#333',
      fontSize: 16,
      fontWeight: 'bold',
    },
  }];

  return graphic;
};
