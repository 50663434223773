export const items = [{
  icon: '/images/subscriptions/i-2.svg',
  title: ' Marketplace',
  list: [
    'Full auto mode',
    'Best from community',
    'Select trading style',
    'High-water mark',
  ],
}, {
  icon: '/images/subscriptions/i-3.svg',
  title: ' Custom bots',
  list: [
    'Connect own strategy',
    'Preset risk management',
    'Control with script alerts',
    'Share for extra revenue',
  ],
}, {
  icon: '/images/subscriptions/i-4.svg',
  title: ' Manual trading',
  list: [
    'Create multi orders positions',
    'Set conditional triggers',
    'Track and manage trades',
    'Share positions presets',
  ],
}];
