import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import {
  button,
  description,
  inner,
  modal,
  modalChildren,
  title,
  topWrapper,
  wrapper,
} from '../styles';
import { ISubscriptionMessage } from '../interfaces';

export const SubscriptionMessage = (props: ISubscriptionMessage) => {
  const {
    isOpen,
    closeHandler,
    text,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      width='100%'
      centered={true}
      onCancel={closeHandler}
    >
      <Box sx={wrapper}>
        <Box sx={topWrapper}>
          <Title styles={title}>
            {text[0]}
          </Title>

          <Text styles={description}>
            {text[1]}
          </Text>
        </Box>

        <Box sx={inner}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
          >
            Ok
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
