import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getTwoFactorToken = async () => {
  const skyrexUserUuid = getSkyrexUuid();
    
  const response = await axios.request({
    url: 'user/generateToken2fa',
    data: {
      skyrexUserUuid,
    },
  });
    
  return response?.data;
};
