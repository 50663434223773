import { AxiosInstance } from 'axios';
import { TradeDetails } from 'entities/terminal/model/types/terminal-schema';

export const fetchPaginatedSmartTrades = async (
  axiosInstance: AxiosInstance,
  endpoint: string,
  requestData: any,
): Promise<TradeDetails[]> => {
  try {
    const initialResponse = await axiosInstance.request({
      url: endpoint,
      method: 'post',
      data: {
        ...requestData,
        page: 1,
      },
    });

    const initialData = initialResponse.data.data.smartTrades;
    const pagination = initialResponse.data.data.pagination;
    const totalPages = pagination ? pagination.totalPages : 1;
    let allData = initialData;

    if (totalPages > 1) {
      const requests = [];
      for (let page = 2; page <= totalPages; page++) {
        requests.push(
          axiosInstance.request({
            url: endpoint,
            method: 'post',
            data: {
              ...requestData,
              page: page,
            },
          }),
        );
      }

      try {
        const responses = await Promise.all(requests);

        for (const response of responses) {
          if (response && response.data && response.data.data && response.data.data.smartTrades) {
            allData = allData?.concat(response.data.data.smartTrades);
          } else {
            console.warn('One of the responses is undefined or does not contain the expected data structure.', response);
          }
        }
      } catch (err) {
        console.error('======Error in Promise.all:', err);
      }
    }

    return allData as TradeDetails[];
  } catch (error) {
    console.error('======Error in fetchPaginatedSmartTrades:', error);
    throw error;
  }
};
