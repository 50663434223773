import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { formateDate } from 'pages/subscriptions/subscription/helpers';
import { checkPendingStatus } from 'shared/helpers';
import { WalletBlueIcon } from 'shared/icons';
import { Text } from 'shared/ui';

export const columns = (makePayment: (subscriptionUuid: string) => () => void): TableColumnsType => {
  const items: TableColumnsType = [{
    title: 'Name',
    dataIndex: 'planName',
    key: 'name',
    width: 140,
    render: (name: string, record) => (
      <Text>
        {name || record.name}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    render: () => (
      <Text>
        Copy bot
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: 'Date start',
    dataIndex: 'dateStart',
    key: 'dateStart',
    width: 120,
    render: (dateStart: string, record) => (
      <Text type='secondary'>
        {checkPendingStatus(record.status) ? '-' : formateDate(record.paymentDate || record.createdDate || dateStart)}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: 'Date end',
    dataIndex: 'dateEnd',
    key: 'dateEnd',
    width: 120,
    render: (dateEnd: string, record) => (
      <Text type='secondary'>
        {checkPendingStatus(record.status) ? '-' : formateDate(record.finishedDate || dateEnd)}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: 'Fee',
    dataIndex: 'fee',
    key: 'fee',
    width: 80,
    render: (fee: string, record) => {
      const value = record.originalFee || record.fee || fee;

      return (
        <Text>
          {value ? `$${value}` : '-'}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      let type: BaseType | undefined = undefined;

      switch (status) {
      case 'active':
        type = 'success';
        break;
      case 'pendingPayment':
        type = 'danger';
        status = 'pending';
        break;
      default:
        type = undefined;
        break;
      }

      return (
        <Text
          type={type}
          styles={{
            textTransform: 'capitalize',
          }}
        >
          {status}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'High-water mark',
    dataIndex: 'highWatermark',
    key: 'waterMark',
    width: 145,
    render: (waterMark: string) => {
      return (
        <Text>
          {waterMark ?? '-'}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'Result',
    dataIndex: 'result',
    key: 'result',
    width: 100,
    render: (result: string) => {
      return (
        <Text>
          {result ?? '-'}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'Net result',
    dataIndex: 'netResult',
    key: 'netResult',
    width: 100,
    render: (netResult: string) => {
      return (
        <Text>
          {netResult ?? '-'}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'Commission',
    dataIndex: 'copyCommissionPercent',
    key: 'comission',
    width: 105,
    render: (copyCommissionPercent: string) => {
      return (
        <Text>
          {copyCommissionPercent ? `${copyCommissionPercent}%` : '-'}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: 'Actions',
    dataIndex: 'status',
    key: 'actions',
    width: 100,
    render: (status: string, record) => {
      if (status === 'active' || status === 'finished') {
        return (
          <Text>-</Text>
        );
      }

      return (
        <div onClick={makePayment(record.subscriptionUuid)}>
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            sx={{
              cursor: 'pointer',
            }}
          >
            {WalletBlueIcon}

            <Text>Pay</Text>
          </Box>
        </div>
      );
    },
    showSorterTooltip: false,
  }];

  return items;
};
