import { IPlans } from '../../plans/interfaces';

export const getItems = (item: IPlans['items'][0], index: number) => {
  const level = ++index;
  const smartBotCommissionPercent = item.smartBotCommissionPercent;
  const exchangeAccountsConnectedLimit = item.exchangeAccountsConnectedLimit;
  const activeSmartTradesLimit = item.activeSmartTradesLimit;
  const activeBotsLimit = item.activeBotsLimit;

  switch (true) {
  case item.planName === 'basic':
    return [
      `Level ${level}: ${smartBotCommissionPercent}% profit sharing at Skyrex AI bots`,
      `${exchangeAccountsConnectedLimit} exchange account connected`,
      `${activeSmartTradesLimit} active Smart terminal position`,
      `${activeBotsLimit} active non-Skyrex copy or custom bot`,
      'Custom bot configurator',
      'Real-time portfolio dashboard',
      '24 hours response support',
    ];
  case item.planName.includes('advanced'):
    return [
      'Everything from basic',
      null,
      `Level ${level}: ${smartBotCommissionPercent}% profit sharing at Skyrex AI bots`,
      `${exchangeAccountsConnectedLimit} exchange account connected`,
      `${activeSmartTradesLimit} active Smart terminal positions`,
      `${activeBotsLimit} active non-Skyrex copy or custom bots`,
      'Custom bot configurator',
      'Real-time portfolio dashboard',
      'Real-time support',
    ];
  case item.planName.includes('pro'):
    return [
      'Everything from Basic and Advanced',
      null,
      `Level ${level}: ${smartBotCommissionPercent}% profit sharing at Skyrex AI bots`,
      `${exchangeAccountsConnectedLimit} exchange account connected`,
      `${activeSmartTradesLimit} active Smart terminal positions`,
      `${activeBotsLimit} active non-Skyrex copy or custom bots`,
      'Private TradingView strategies access',
      'Custom bot configurator',
      'Custom bot sharing at the Marketplace',
      'Premium dedicated support',
    ];
  default:
    return [];
  }
};
