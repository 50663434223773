import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  padding: '12px',
  textAlign: 'center',
  flex: 1,
  fontSize: 14,
  fontWeight: 500,
  width: '100%',
  maxWidth: 117,
};
