import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout } from 'features/auth/model/actions/logout';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { BotData } from 'pages/trading-bots/my-bot/ui/my-bot';
import { BotSchema } from '../types/bot-schema';

const initialState: BotSchema = {
  allBots: [],
}; 

export const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    setAllBots: (state, action: PayloadAction<Bot[]>) => {
      state.allBots = action.payload;  
    },
    setCurrentBot: (state, action: PayloadAction<BotData | null>) => {
      state.currentBot = action.payload;
    },
    startCurrentBot(state) {
      if (state.currentBot) {
        state.currentBot.status = 'on';
      }
    },
    stopCurrentBot(state) {
      if (state.currentBot) {
        state.currentBot.status = 'off';
      }
    },
    updateCurrentBot: (state, action: PayloadAction<BotData>) => {
      const updatedBot = action.payload;
      state.currentBot = {
        ...state.currentBot,
        ...updatedBot,
      };
    },
    setBotStart: (state, action: PayloadAction<string>) => {
      const botUuid = action.payload;
      const bot = state.allBots.find(b => b.botUuid === botUuid);
      if (bot) {
        bot.status = 'on';
      }
    },
    setBotStop: (state, action: PayloadAction<string>) => {
      const botUuid = action.payload;
      const bot = state.allBots.find(b => b.botUuid === botUuid);
      if (bot) {
        bot.status = 'off';
      }
    },
    deleteBot: (state, action: PayloadAction<string>) => {
      const botUuid = action.payload;
      state.allBots = state.allBots.filter(b => b.botUuid !== botUuid);
    },
    updateBot: (state, action: PayloadAction<Bot>) => {
      const updatedBot = action.payload;
      const index = state.allBots.findIndex(b => b.botUuid === updatedBot.botUuid);
      if (index !== -1) {
        state.allBots[index] = {
          ...state.allBots[index],
          ...updatedBot,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  actions: botActions,
} = botSlice;

export const {
  reducer: botReducer,
} = botSlice;
