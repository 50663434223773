export const Bot = (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='9.33329' cy='16' r='2.66667' fill='#2ECD99' />
    <circle cx='22.6667' cy='16' r='2.66667' fill='#2ECD99' />
    <line x1='10.3334' y1='24.3333' x2='21.6667' y2='24.3333' stroke='#2ECD99' strokeWidth='2' strokeLinecap='round' />
    <path d='M2.33337 10C2.33337 9.44772 2.78109 9 3.33337 9H28.6667C29.219 9 29.6667 9.44772 29.6667 10V26.8282C29.6667 27.2613 29.3879 27.6452 28.9761 27.7792L26.6906 28.5228L23.3256 29.6176C23.2257 29.6501 23.1213 29.6667 23.0162 29.6667H8.98388C8.87882 29.6667 8.7744 29.6501 8.67449 29.6176L5.30943 28.5228L3.02398 27.7792C2.61215 27.6452 2.33337 27.2613 2.33337 26.8282V10Z' stroke='#2ECD99' strokeWidth='2' />
    <circle cx='16' cy='2.66667' r='2.66667' fill='#2ECD99' />
    <line x1='16.3334' y1='9.33334' x2='16.3334' y2='2.66667' stroke='#2ECD99' strokeWidth='2' />
  </svg>
);
