import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  backgroundColor: '#F2F2F2',
  padding: {
    xs: '44px 12px',
    sm: '44px 20px',
  },
  borderRadius: 4,
  alignItems: 'center',
};
