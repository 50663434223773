import { Box } from '@mui/material';
import EChart from 'widgets/echart/echart';
import { chart, wrapper } from '../styles';

interface LineChartData {
  date: string;
  return: number;
}

interface LineChartProps {
  data: LineChartData[];
  totalPnl: number;
  roi: number;
}

export const LineChart: React.FC<LineChartProps> = ({
  data,
  totalPnl,
  roi,
}) => {
  const seriesData = data?.map(item => ({
    name: item.date,
    value: (item.return / totalPnl) * roi,
  }));

  return (
    <Box sx={wrapper}>
      <EChart
        style={chart}
        yAxis={{
          show: false,
          type: 'value',
        }}
        xAxis={{
          type: 'category',
          show: false,
          data: data?.map(item => item.date),
        }}
        grid={{
          top: 10,
          bottom: 3,
          left: 0,
          right: 0,
        }}
        series={[
          {
            data: seriesData,
            type: 'line',
            smooth: true,
            areaStyle: undefined,
            lineStyle: {
              color: '#2ECD99',
            },
            itemStyle: {
              color: '#2ECD99',
            },
            showSymbol: false,
          },
        ]}
        tooltip={{
          trigger: 'axis',
          formatter: (params: any) => {
            const { 
              name,
              value,
            } = params[0];
            return `Date: ${name}<br/>Return: ${value.toFixed(2)}%`;
          },
        }}
      />
    </Box>
  );
};
