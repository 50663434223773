import { SxProps, Theme } from '@mui/material';

export const account: SxProps<Theme> = {
  textAlign: {
    xs: 'center',
    md: 'end',
  },
  paddingTop: {
    xs: 0,
    sm: 2.5,
  },
};
