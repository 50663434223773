import { SxProps, Theme } from '@mui/material';

export const layoutWrapper: SxProps<Theme> = {
  flex: '1 1 auto',
  display: 'flex',
  justifyContent: 'center',
  padding: {
    xs: '28px 0 21px',
    sm: '25px 0 50px',
  },
};
