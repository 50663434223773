import { Dropdown } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { NavLink } from 'react-router-dom';
import { Down } from 'shared/icons';
import { MainButton, TextButton } from 'shared/ui';
import { IMenuItem } from '../../../interfaces';
import { MenuItemDropDown } from '../../menu-item-dropdown';
import { activeButton, button } from '../styles';

interface IRenderMenu {
  menuItems: IMenuItem[];
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  inviteFriends: () => void;
}
export const renderMenu = (params: IRenderMenu) => {
  const {
    menuItems,
    setIsOpened,
    inviteFriends,
  } = params;

  const items = menuItems.map((item: IMenuItem) => {
    const action = {
      label: item.action?.label!,
      handler: () => {
        setIsOpened(true);
      },
    };

    if (item.children) {
      return (
        <Dropdown
          key={item.key}
          dropdownRender={() => (
            <MenuItemDropDown
              items={item.children!}
              action={item.action && action}
            />
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <TextButton
              icon={Down}
              styles={button}
            >
              {item.label}
            </TextButton>
          </a>
        </Dropdown>
      );
    }

    if (item.key === 'invite-friends') {
      return (
        <MainButton
          key={item.key}
          type='text'
          styles={button}
          onClick={inviteFriends}
        >
          {item.label}
        </MainButton>
      );
    }

    return (
      <NavLink
        key={item.key}
        to={item.link}
      >
        {({
          isActive,
        }) => (
          <MainButton
            type='text'
            styles={{
              ...button,
              ...(isActive && activeButton),
            }}
          >
            {item.label}
          </MainButton>
        )}
      </NavLink>
    );
  });

  return items;
};
