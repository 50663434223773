import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 2,
  width: '100%',
};
