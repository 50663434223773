import { CSSProperties } from 'react';

export const item: CSSProperties = {
  borderWidth: 3,
  borderStyle: 'solid',
  borderRadius: 8,
  backgroundColor: '#FFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'auto',
  width: '100%',
  flex: 1,
  padding: 0,
};
