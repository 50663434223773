export const ConfettiLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" fill="none">
    <path d="M63.6365 50.4627L65.2451 53.5967C63.2066 50.2131 50.532 55.025 47.301 52.9726C47.0652 52.8201 46.885 52.6121 46.7602 52.3625L45.3596 49.5891C47.5783 52.5428 61.1959 47.19 63.6365 50.4627Z" fill="#FF9522" />
    <path d="M50.7955 34.5293C51.7523 34.4184 52.4179 34.46 52.8478 34.6264C53.042 34.6957 53.1945 34.8482 53.2916 35.0285L54.8031 37.9268C54.4703 37.5107 53.6937 37.3582 52.307 37.5246C47.2177 38.1348 42.6832 38.2596 41.9066 36.5539L40.3951 33.5586C41.1578 35.2781 45.7062 35.1533 50.7955 34.5293Z" fill="#FF9522" />
    <path d="M46.9404 25.6266L48.4519 28.6219C42.9744 32.7959 41.2964 35.2365 41.9066 36.5539L40.3951 33.5586C39.7849 32.2412 41.4628 29.8006 46.9404 25.6266Z" fill="#FEBD55" />
    <path d="M53.4719 35.3891L54.8031 37.9406C56.5504 40.1039 45.7479 49.1314 46.6908 52.2377L45.3596 49.6029C43.4321 47.0514 53.8324 38.2596 53.4719 35.3891Z" fill="#FEBD55" />
    <path d="M63.997 51.4195C63.9831 51.267 63.9415 51.1145 63.8999 50.9896L65.3699 53.8463C65.4392 54.0127 65.4947 54.2068 65.5085 54.4148C66.0771 59.5457 52.3763 68.4068 52.3763 68.4068L50.8648 65.4115C50.8509 65.4115 64.5517 56.5365 63.997 51.4195Z" fill="#FEBD55" />
    <path d="M20.038 35.3336L17.3478 37.6217C20.1767 34.8621 12.5914 23.6297 13.8394 20.0103C13.9365 19.7469 14.1029 19.5111 14.3109 19.3309L16.6822 17.3201C14.3386 20.1906 22.6728 32.2135 20.038 35.3336Z" fill="#FF9522" />
    <path d="M32.6017 19.1922C32.9345 20.0936 33.0455 20.7453 32.9761 21.2029C32.9484 21.4109 32.8375 21.5912 32.6849 21.7299L30.2166 23.8654C30.5355 23.4494 30.5216 22.659 30.0363 21.3416C28.2752 16.5297 27.1103 12.1338 28.5941 10.9967L31.1595 8.8334C29.6757 9.98437 30.8406 14.3803 32.6017 19.1922Z" fill="#FF9522" />
    <path d="M40.3951 13.3957L37.8297 15.559C32.5047 11.1908 29.759 10.1092 28.608 10.9967L31.1596 8.8334C32.3106 7.9459 35.0701 9.02754 40.3951 13.3957Z" fill="#FEBD55" />
    <path d="M32.3938 21.9934L30.2166 23.8793C28.511 26.0703 17.2508 17.6391 14.4358 19.2615L16.6961 17.3617C18.7346 14.8934 29.6758 23.0057 32.3938 21.9934Z" fill="#FEBD55" />
    <path d="M19.1922 35.9021C19.3448 35.8467 19.4696 35.7912 19.5944 35.708L17.1537 37.7881C17.0012 37.899 16.8348 37.9822 16.6407 38.0516C11.7733 39.7711 2.47955e-05 28.4693 2.47955e-05 28.4693L2.56545 26.3061C2.56545 26.3338 14.3387 37.6355 19.1922 35.9021Z" fill="#FEBD55" />
    <path d="M50.851 11.5791C52.3209 11.5791 53.4996 12.7717 53.4996 14.2277C53.4996 15.6977 52.3071 16.8764 50.851 16.8764C49.3949 16.8764 48.2024 15.6838 48.2024 14.2277C48.2024 12.7578 49.3949 11.5791 50.851 11.5791Z" fill="#FEBD55" />
    <path d="M17.1399 56.6059C15.723 56.6059 14.5745 55.4573 14.5745 54.0404C14.5745 52.6236 15.723 51.475 17.1399 51.475C18.5567 51.475 19.7053 52.6236 19.7053 54.0404C19.7053 55.4573 18.5567 56.6059 17.1399 56.6059Z" fill="#2ECD99" />
    <path d="M70.9584 36.1795C71.0693 36.3875 70.8891 36.6371 70.6533 36.6094L67.0062 36.027C66.9092 36.0131 66.8121 36.0408 66.7566 36.1102L64.2051 38.7727C64.0387 38.9529 63.7475 38.8559 63.7059 38.6201L63.1373 34.973C63.1234 34.876 63.068 34.7928 62.9709 34.7512L59.6428 33.1564C59.4209 33.0455 59.4209 32.7404 59.6428 32.6434L62.9432 30.9793C63.0264 30.9377 63.0818 30.8545 63.0957 30.7574L63.5949 27.0965C63.6227 26.8607 63.9139 26.7637 64.0941 26.9301L66.7012 29.5371C66.7705 29.6064 66.8676 29.6342 66.9646 29.6203L70.584 28.9547C70.8197 28.9131 71 29.1627 70.8891 29.3707L69.1973 32.6572C69.1557 32.7404 69.1557 32.8375 69.1973 32.9207L70.9584 36.1795Z" fill="#FF6161" />
    <path d="M13.3541 2.59316C14.3526 7.03066 15.1707 7.83496 19.5944 8.8334C15.1569 9.83183 14.3526 10.65 13.3541 15.0736C12.3557 10.6361 11.5375 9.83183 7.11389 8.8334C11.5375 7.83496 12.3557 7.03066 13.3541 2.59316Z" fill="#0CA8E5" />
    <path d="M34.0301 53.042C34.9037 56.8971 35.6109 57.6043 39.4521 58.4641C35.5971 59.3377 34.8898 60.0449 34.0301 63.8861C33.1564 60.0311 32.4492 59.3238 28.608 58.4641C32.4492 57.6043 33.1564 56.8971 34.0301 53.042Z" fill="#26BAFF" />
    <path d="M31.1127 33.3169L26.8185 37.6111L31.1127 41.9053L35.4069 37.6111L31.1127 33.3169Z" fill="#F32222" />
  </svg>
);
