import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: 5,
    md: 10,
  },
};
