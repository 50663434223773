export const Delete = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4408_6055)'>
      <path d='M3.33325 13.333C3.33325 14.0683 3.93125 14.6663 4.66659 14.6663H11.3333C12.0686 14.6663 12.6666 14.0683 12.6666 13.333V5.33301H3.33325V13.333ZM4.66659 6.66634H11.3333L11.3339 13.333H4.66659V6.66634Z' fill='#F32222' />
      <path d='M10 3.33333V2H6V3.33333H2V4.66667H14V3.33333H10Z' fill='#F32222' />
      <path d='M7.33333 8H6V12H7.33333V8Z' fill='#F32222' />
      <path d='M10.0001 8H8.66675V12H10.0001V8Z' fill='#F32222' />
    </g>
    <defs>
      <clipPath id='clip0_4408_6055'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
