import { SxProps, Theme } from '@mui/material';

export const stepDescription: SxProps<Theme> = {
  '&>span': {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 'normal',
    textTransform: 'capitalize',
  },
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  justifyContent: 'center',
  '@media(max-width: 575px)': {
    justifyContent: 'initial',
  },
};
