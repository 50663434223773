import { SxProps, Theme } from '@mui/material';

export const textWrapper: SxProps<Theme> = {
  textAlign: 'center',
  maxWidth: {
    xs: '330px',
    md: '100%',
  },
  '&:first-of-type': {
    padding: {
      xs: 0,
      md: '0px 16px',
    },
  },

  '&>span': {
    fontSize: {
      xs: 11,
      sm: 14,
    },
  },
};
