import dayjs from 'dayjs';
import { getStatByDates } from 'pages/trading-bots/my-bots/api/get-stat-by-dates';
import { TradingStatisticCalculations } from 'pages/trading-bots/my-bots/types/bots-calculations';

export const getTradingStatisticByDates = async (
  exchangeId: string, 
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null)
  : Promise<TradingStatisticCalculations[]> => {
  try {
    
    const formattedDateCloseFrom = dates && dates[0] ? dayjs(dates[0]).format('YYYY-MM-DD HH:mm:ss') : null;
    const formattedDateCloseTo = dates && dates[1] ? dayjs(dates[1]).format('YYYY-MM-DD HH:mm:ss') : null;
    
    const exchangeTradingStatisticsData = await getStatByDates({
      exchangeAccountUuids: [exchangeId],
      sideFirstStep: 'BUY',
      dateCloseTo: formattedDateCloseTo,
      dateCloseFrom: formattedDateCloseFrom,
    });
  
    return exchangeTradingStatisticsData as TradingStatisticCalculations[];
  } catch (error) {
    console.error('Error fetching trading statistics:', error);
    return [];
  }
};
