import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  position: 'relative',
  display: 'flex',
  gap: {
    xs: 3,
    md: 7,
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};
