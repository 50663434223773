import { Trial } from 'shared/ui';
import { Layout } from '../../shared/layout';
import { Footer, Header } from '../../widgets';
import { IBaseLayout } from './interfaces';

export const BaseLayout = (props: IBaseLayout) => {
  const {
    children,
  } = props;

  return (
    <Layout
      header={(<Header />)}
      footer={(<Footer />)}
    >
      <Trial
        isOpened={false}
        closeHandler={() => {}}
      />
      
      {children}
    </Layout>
  );
};
