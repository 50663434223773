import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
  
// @TODO use intl
export const formatterByQuote = (value: number, quoteSymbol: string, exchangeSymbols: CurrentSymbol[]) => {
  const symbol = exchangeSymbols.find((element) => element.quoteAsset === quoteSymbol?.toUpperCase());
  const precision = symbol?.chartPrecision ?? 4;
  
  const factor = Math.pow(10, precision);
  const roundedValue = Math.round(value * factor) / factor;
  
  let formattedValue = roundedValue.toFixed(precision);
  
  if (formattedValue.includes('.')) {
    formattedValue = formattedValue.replace(/\.?0+$/, '');
  }
  
  return formattedValue;
};
  
