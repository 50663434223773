import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '14px 18px',
  backgroundColor: '#FFF',
  border: '1px solid rgba(58, 58, 60, 0.15)',
  borderRadius: '5px',
  gap: '15px',
  '&>pre': {
    margin: 0,
    textAlign: 'left',
  },
  fontSize: 12,
  fontWeight: 400,
  color: '#3A3A3C',
};
