import { SxProps, Theme } from '@mui/material';

export const planPrice: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  fontSize: 12,
  fontWeight: 400,
  '&>span': {
    fontSize: 24,
  },
  '&>strong': {
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'line-through',
    textDecorationLine: 'line-through',
  },
};
