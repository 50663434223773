import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});
  
export const getUser = async (skyrexUserUuids: string[]) => {
  const response = await axios.request({
    url: '/user/getByFilter',
    data: {
      skyrexUserUuids,
    },
  });
    
  return response.data;
};
