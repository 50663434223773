export const CryptoCardIcon = (
  <svg width='97' height='55' viewBox='0 0 97 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.851807' width='95.2966' height='54.7448' rx='4' fill='white' />
    <rect x='1.35181' y='0.5' width='94.2966' height='53.7448' rx='3.5' stroke='#3A3A3C' strokeOpacity='0.15' />
    <g clipPath='url(#clip0_6934_5505)'>
      <path d='M49.5 43C58.0604 43 65 36.0604 65 27.5C65 18.9396 58.0604 12 49.5 12C40.9396 12 34 18.9396 34 27.5C34 36.0604 40.9396 43 49.5 43Z' fill='#26A17B' />
      <path fillRule='evenodd' clipRule='evenodd' d='M51.3621 28.8398V28.8379C51.2555 28.8456 50.7062 28.8786 49.4808 28.8786C48.5023 28.8786 47.8136 28.8495 47.5714 28.8379V28.8408C43.8049 28.6751 40.9936 28.0193 40.9936 27.2346C40.9936 26.4509 43.8049 25.7951 47.5714 25.6265V28.1879C47.8174 28.2053 48.5227 28.247 49.4972 28.247C50.6665 28.247 51.2526 28.1985 51.3621 28.1888V25.6284C55.1208 25.796 57.9254 26.4519 57.9254 27.2346C57.9254 28.0193 55.1208 28.6732 51.3621 28.8398ZM51.3621 25.362V23.07H56.6069V19.5747H42.3266V23.07H47.5714V25.3611C43.3089 25.5567 40.1033 26.4015 40.1033 27.4129C40.1033 28.4242 43.3089 29.268 47.5714 29.4647V36.8097H51.3621V29.4627C55.6178 29.267 58.8156 28.4233 58.8156 27.4129C58.8156 26.4025 55.6178 25.5587 51.3621 25.362Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_6934_5505'>
        <rect width='31' height='31' fill='white' transform='translate(34 12)' />
      </clipPath>
    </defs>
  </svg>
);
