import { Box } from '@mui/material';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Text, Title, Tooltip } from 'shared/ui';
import { ILineChart } from '../interfaces';
import { wrapper, item as itemStyles } from '../styles';

export const LineChart = (props: ILineChart) => {
  const {
    items,
  } = props;

  return (

    <div style={wrapper}>
      {items?.map((item: ILineChart['items'][0]) => (
        <Tooltip
          key={item.symbol}
          title={(
            <Box
              display='flex'
              flexDirection='column'
              gap={1}
            >
              <Box
                display='flex'
                alignItems='center'
                gap={1}
              >
                <Title level={5}>{`${item.symbol}`}</Title>
                <Title level={5}>{`${currencyFormatter(+item.share, 2)}%`}</Title>
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                gap={0.2}
              >
                <Text type='secondary'>Amount</Text>
                <Text type='secondary'>{item.total}</Text>
                <Text type='secondary'>₿ {currencyFormatter(item.btc.total, 6)}</Text>
                <Text type='secondary'>$ {currencyFormatter(item.usdt.total, 2)}</Text>
              </Box>
            </Box>
          )}
        >
          <div
            style={{
              ...itemStyles,
              backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              width: `${item.share}%`,
            }}
          />
        </Tooltip>
      ))}
    </div>
  );
};
