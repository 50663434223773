import { SxProps, Theme } from '@mui/material';

export const textWrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  alignItems: {
    xs: 'center',
    md: 'initial',
  },
};
