import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/model/actions/logout';
import { ExchangeAccount } from '../types/exchange-account';
import { ExchangeSchema } from '../types/exchange-schema';

const initialState: ExchangeSchema = {
  exchangeName: '',
  exchangeData: [],
  shortExchangesData: [],
  singleExchangePageSymbol: 'USD',
  singleExchange: {
    skyrexUserUuid: '',
    exchangeAccountUuid: '',
    accountName: '',
    exchangeCode: 'binance',
    status: '',
    createdDate: '',
    lastChangeDate: '',
    balances: [],
    totalUsdt: '',
    totalBtc: '',
    freeUsdt: '',
    freeBtc: '',
    usdtDailyChangePercent: '',
    usdtDailyChangeValue: '',
    btcDailyChangePercent: '',
    btcDailyChangeValue: '',
    snapshotsArray: [],
  },
  homePageData: {
    balances: [],
    totalBtc: 0,
    totalUsdt: 0,
    usdtDailyChangePercent: '',
    usdtDailyChangeValue: 0,
    btcDailyChangePercent: '',
    btcDailyChangeValue: 0,
    statistic: [],
  },
  snapshotsHomePage: [],
  snapshotsSingleExchangePage: [],
};

export const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {
    setExchange: (state, action: PayloadAction<string>) => {
      state.exchangeName = action.payload;
    },
    setAllExchanges: (state, action: PayloadAction<ExchangeAccount[]>) => {
      state.exchangeData = action.payload;
    },
    setSingleExchangePage: (state, action: PayloadAction<ExchangeAccount>) => {
      state.singleExchange = action.payload;
    },
    deleteExchange: (state, action: PayloadAction<string>) => {
      state.exchangeData = state
        .exchangeData
        .filter((exchange: ExchangeAccount) => exchange.exchangeAccountUuid !== action.payload);
    },
    setSingleExchangePageSymbol: (state, action: PayloadAction<string>) => {
      state.singleExchangePageSymbol = action.payload;
    },
    setHomePageData: (state, action: PayloadAction<any>) => {
      state.homePageData = action.payload;
    },
    setSnapshotsHomePageData: (state, action: PayloadAction<any>) => {
      state.snapshotsHomePage = action.payload;
    },
    setSnapshotsSingleExchange: (state, action: PayloadAction<any>) => {
      state.snapshotsSingleExchangePage = action.payload;
    },
    setAllExchangesShortData: (state, action: PayloadAction<any>) => {
      state.shortExchangesData = action.payload;
    },
    updateExchange: (state, action: PayloadAction<{
      exchangeAccountUuid: string;
      data: ExchangeAccount;
    }>) => {
      const {
        exchangeAccountUuid, data,
      } = action.payload;
      const index = state
        .exchangeData
        .findIndex((exchange: ExchangeAccount) => exchange
          .exchangeAccountUuid === exchangeAccountUuid);
      if (index !== -1) {
        state.exchangeData[index] = {
          ...state.exchangeData[index],
          ...data,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  actions: exchangeActions,
} = exchangeSlice;
export const {
  reducer: exchangeReducer,
} = exchangeSlice;
