import { SxProps, Theme } from '@mui/material';

export const wrapper = (isReverse: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '47px',
    position: 'relative',
    borderRadius: '18px',
    border: '1px solid #E9E9E9',
    backgroundColor: isReverse ? '#323749' : '#fff',
    padding: '24px',
    color: isReverse ? '#fff' : '#3A3A3C',
  };

  return styles;
};
