import { SxProps, Theme } from '@mui/material';
import { ISingleSelect } from '../interfaces';

export const wrapper = (maxWidth: ISingleSelect['maxWidth']) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    flex: 1,
    width: '100%',
    maxWidth: {
      xs: '100%',
      sm: maxWidth,
    },
  };

  return styles;
};
