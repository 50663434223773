import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

const getStartAndEndOfCurrentMonth = () => {
  const now = new Date();

  const startOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const endOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  endOfCurrentMonth.setHours(23, 59, 59, 999);

  return {
    startOfCurrentMonth: startOfCurrentMonth.toISOString(),
    endOfCurrentMonth: endOfCurrentMonth.toISOString(),
  };
};

export const getSnapshots = async (skyrexUserUuid: string, exchangeAccountUuids: string[]) => {
  try {
    const {
      startOfCurrentMonth,
      endOfCurrentMonth,
    } = getStartAndEndOfCurrentMonth();

    const response = await axios.request({
      url: '/exchangeAccount/getByFilter',
      data: {
        table: 'exchange_account_snapshots',
        skyrexUserUuids: [skyrexUserUuid],
        exchangeAccountUuids: exchangeAccountUuids,
        createdDateFrom: startOfCurrentMonth,
        createdDateTo: endOfCurrentMonth,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching snapshots:', error);
    return {
      data: {
        accounts: [], 
      }, 
    };
  }
};
