import { CSSProperties } from 'react';

export const button: CSSProperties = {
  fontSize: 14,
  fontWeight: 500,
  height: 'auto',
  padding: '16px 0',
  width: '100%',
  maxWidth: 210,
  alignSelf: 'center',
};
