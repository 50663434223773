import { Box } from '@mui/material';
import { Modal } from 'antd';
import { SuccessCheck } from 'shared/icons';
import { MainButton, Text, Title } from 'shared/ui';
import { ITwoFactorMessage } from '../interfaces';
import {
  button,
  inner,
  modal,
  modalChildren,
  text as textStyles,
  title as titleStyles,
  wrapper,
} from '../styles';

export const TwoFactorMessage = (props: ITwoFactorMessage) => {
  const {
    isOpen,
    title,
    text,
    withIcon = true,
    handleClose,
  } = props;
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      onOk={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {withIcon && (
          <Box>
            {SuccessCheck}
          </Box>
        )}

        <Box sx={inner}>
          <Title styles={titleStyles}>
            {title}
          </Title>

          {text && (
            <Text styles={textStyles}>
              {text}
            </Text>
          )}
        </Box>

        <MainButton
          type='primary'
          styles={button}
          ghost={true}
          onClick={handleClose}
        >
          Okay
        </MainButton>
      </Box>
    </Modal>
  );
};
