import { useLocation } from 'react-router-dom';
import { authTemplates } from 'shared/consts';
import { BaseLayout } from 'app/layouts';
import AppRouter from 'app/providers/router/ui/app-router';

function App() {
  const location = useLocation();

  const withLayout = !authTemplates.includes(location.pathname);
  
  return (
    withLayout ? (
      <BaseLayout>
        <AppRouter />
      </BaseLayout>
    ) : (
      <AppRouter />
    )
  );
}

export default App;
