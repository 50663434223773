import { Typography } from 'antd';
import { IProps } from '../interfaces';

export const Title = (props: IProps) => {
  const {
    level,
    styles,
    children,
    onClick,
    overflow,
  } = props;

  return (
    <Typography.Title
      style={styles}
      level={level}
      onClick={onClick}
      ellipsis={overflow}
    >
      {children}
    </Typography.Title>
  );
};
