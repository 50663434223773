import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createCopyBotSubscription = async ({
  sourceUuid,
  botUuid,
}: Props) => {
  const skyrexUserUuid = getSkyrexUuid();
      
  const response = await axios.request({
    url: '/subscription/createBotSuccessSubscription',
    data: {
      skyrexUserUuid,
      sourceUuid,
      botUuid,
    },
  });

  return response?.data;
};

type Props = {
  sourceUuid: string;
  botUuid: string;  
};
