import { SxProps, Theme } from '@mui/material';
import { IRangePicker } from '../interfaces';

export const wrapper = (maxWidth?: IRangePicker['maxWidth']) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    maxWidth: {
      xs: '100%',
      sm: maxWidth || 'max-content',
    },
    '&>.ant-picker-panel-layout': {
      '&>div>.ant-picker-panels': {
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        flexWrap: {
          xs: 'wrap',
          md: 'nowrap',
        },
        '&>.ant-picker-panel': {
          '&>.ant-picker-date-panel': {
            width: {
              xs: 280,
              sm: 372,
            },
            '&>.ant-picker-body': {
              '&>.ant-picker-content': {
                '&>tbody>tr>td': {
                  minWidth: {
                    xs: 25,
                    sm: 45,
                  },
                  '&>.ant-picker-cell-inner': {
                    minWidth: {
                      xs: 25,
                      sm: 45,
                    },
                    height: {
                      xs: 25,
                      sm: 45,
                    },
                    lineHeight: {
                      xs: '25px',
                      sm: '45px',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return styles;
};
