import { CSSProperties } from 'react';

export const applyButton: CSSProperties = {
  height: '100%',
  minHeight: '100%',
  padding: '5px 33px 4px',
  fontSize: 14,
  fontWeight: 500,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
};
