import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
  borderColor: '#E9E9E9',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 193,
};
