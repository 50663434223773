import { CSSProperties } from 'react';

export const chart: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};
