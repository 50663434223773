import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { InfoError } from 'shared/icons';
import { LabelInput, MainButton, Text, Title, Tooltip } from 'shared/ui';
import { changePassword } from '../api/change-password';
import { IChangePassword } from '../interfaces';
import {
  button,
  buttons,
  cancelButton,
  description,
  fields,
  modal,
  modalContent,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

export const ChangePasswordModal = (props: IChangePassword) => {
  const {
    isOpen,
    handleClose,
    handleSuccess,
    handleFailure,
  } = props;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  
  const handleChangePassword = async () => {
    const requestData = {
      oldPassword,
      newPassword,
    };
    
    const responseFromChangePassword = await changePassword(requestData);
    if (!responseFromChangePassword.success) {
      handleClose();
      return handleFailure();
    }

    handleClose();    
    return handleSuccess();
  };
  
  const isFormValid = () => (
    oldPassword.length > 0 &&
    newPassword.length > 0 &&
    passwordConfirmation.length > 0 &&
    newPassword === passwordConfirmation
  );
  
  useEffect(() => {
    if (!isOpen) {
      setOldPassword('');
      setNewPassword('');
      setPasswordConfirmation('');
    }
  }, [isOpen]);
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose
      onCancel={handleClose}
      onOk={handleClose}
      width='100%'
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>Change password</Title>
          <Text styles={description}>Use strong passwords to protect yourself </Text>
        </Box>

        <Box sx={fields}>
          <LabelInput
            label='Current password'
            placeholder='Enter current password'
            value={oldPassword}
            onChange={(value) => setOldPassword(value)}
            type='password'
          />

          <LabelInput
            label='New password'
            placeholder='Enter new password'
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
            type='password'
          />

          <LabelInput
            label='Password confirmation'
            placeholder='Enter password confirmation'
            value={passwordConfirmation}
            onChange={(value) => setPasswordConfirmation(value)}
            type='password'
            status={passwordConfirmation !== newPassword ? 'error' : undefined}
            suffix={
              passwordConfirmation !== newPassword 
                ? (
                  <Tooltip title='Passwords don’t match!'>
                    {InfoError}
                  </Tooltip>
                ) : null
            }
          />
        </Box>

        <Box sx={buttons}>
          <MainButton
            ghost={true}
            type='primary'
            styles={button}
            onClick={handleChangePassword}
            disabled={!isFormValid()}
          >
            Change password
          </MainButton>

          <MainButton
            ghost={true}
            type='link'
            styles={cancelButton}
            onClick={handleClose}
          >
            Cancel
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
