export const AccountSolid = (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4408_5082)'>
      <path d='M8.3788 8.18895C9.50381 8.18895 10.478 7.78545 11.274 6.98936C12.0699 6.1934 12.4734 5.21948 12.4734 4.09434C12.4734 2.96959 12.0699 1.99555 11.2738 1.19933C10.4777 0.403494 9.50368 0 8.3788 0C7.25365 0 6.27974 0.403494 5.48377 1.19946C4.68781 1.99542 4.28418 2.96946 4.28418 4.09434C4.28418 5.21948 4.68781 6.19353 5.4839 6.98949C6.28 7.78532 7.25404 8.18895 8.3788 8.18895Z' fill='#2ECD99' />
      <path d='M15.5433 13.0721C15.5204 12.7409 15.474 12.3795 15.4056 11.998C15.3366 11.6135 15.2478 11.2501 15.1414 10.918C15.0315 10.5746 14.8821 10.2356 14.6974 9.91071C14.5057 9.57349 14.2806 9.27985 14.0279 9.03822C13.7637 8.78544 13.4403 8.5822 13.0662 8.43395C12.6935 8.28648 12.2804 8.21178 11.8385 8.21178C11.6649 8.21178 11.4971 8.28298 11.173 8.494C10.9735 8.62409 10.7402 8.77454 10.4797 8.94095C10.257 9.08284 9.95536 9.21578 9.58273 9.33614C9.21918 9.45378 8.85006 9.51344 8.48573 9.51344C8.12141 9.51344 7.75241 9.45378 7.38847 9.33614C7.01624 9.21591 6.71455 9.08297 6.49212 8.94108C6.23415 8.77623 6.00069 8.62578 5.79823 8.49387C5.47449 8.28285 5.30653 8.21165 5.133 8.21165C4.69098 8.21165 4.27802 8.28648 3.90539 8.43408C3.53159 8.58207 3.20799 8.78531 2.94353 9.03835C2.69101 9.28011 2.46572 9.57362 2.27428 9.91071C2.08972 10.2356 1.94031 10.5745 1.83032 10.9181C1.7241 11.2502 1.63525 11.6135 1.56625 11.998C1.4979 12.379 1.45147 12.7405 1.42851 13.0725C1.40594 13.3978 1.39453 13.7354 1.39453 14.0764C1.39453 14.9638 1.67663 15.6822 2.23291 16.212C2.78232 16.7348 3.50928 17.0001 4.39332 17.0001H12.5789C13.463 17.0001 14.1897 16.735 14.7392 16.212C15.2956 15.6826 15.5777 14.964 15.5777 14.0763C15.5776 13.7337 15.566 13.3959 15.5433 13.0721Z' fill='#2ECD99' />
    </g>
    <defs>
      <clipPath id='clip0_4408_5082'>
        <rect width='17' height='17' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
