import { Box, Hidden } from '@mui/material';
import { useState } from 'react';
import { AddNewBot } from 'shared/ui';
import { menuItems } from '../../../consts';
import { renderMenu } from '../helpers';
import { IHorizontalMenu } from '../interfaces';

export const HorizontalMenu = (props: IHorizontalMenu) => {
  const {
    inviteFriends,
  } = props;

  const [isOpened, setIsOpened] = useState(false);

  return (
    <Hidden lgDown>
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        {renderMenu({
          menuItems,
          setIsOpened,
          inviteFriends,
        })}
      </Box>

      <AddNewBot
        isOpen={isOpened}
        closeModal={() => {
          setIsOpened(false);
        }}
      />
    </Hidden>
  );
};
