export const TikTok = (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <g clipPath='url(#clip0_5977_3786)'>
      <path d='M12.5638 5.96245C11.4223 5.98781 10.357 5.6327 9.4438 4.97319V9.51363C9.4438 11.2385 8.37845 12.7604 6.75506 13.3692C5.15703 13.978 3.3307 13.5214 2.21462 12.2277C1.07317 10.9087 0.870245 9.05705 1.68194 7.53511C2.49364 6.03855 4.16777 5.20148 5.89263 5.42977V7.71267C5.10629 7.45902 4.24386 7.73804 3.76192 8.39754C3.30534 9.08241 3.30534 9.97021 3.78728 10.6297C4.26923 11.2892 5.13166 11.5682 5.89263 11.3146C6.67896 11.0609 7.21164 10.3253 7.21164 9.51363V0.635681H9.4438C9.4438 0.838606 9.4438 1.01616 9.49454 1.21909C9.64673 2.05615 10.1287 2.79175 10.8643 3.24833C11.3462 3.57809 11.955 3.75565 12.5638 3.75565V5.96245Z' fill='#9E9E9E' />
    </g>
    <defs>
      <clipPath id='clip0_5977_3786'>
        <rect width='13' height='13' fill='white' transform='translate(0.5 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
