import { PlusOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { IMenuItemChildren, IMenuItemDropDown } from 'widgets/header/interfaces';
import { MainButton } from 'shared/ui';
import { ItemNavLink } from '../components';
import { actionItem, plusIcon, wrapper } from '../styles';

export const MenuItemDropDown = (props: IMenuItemDropDown) => {
  const {
    items,
    action,
  } = props;

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={4}
      sx={wrapper}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap={4}
      >
        {items.map((item: IMenuItemChildren) => (
          <ItemNavLink
            key={item.label}
            label={item.label}
            link={item.link}
            icon={(
              <Box
                width={32}
                height={32}
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                {item.icon}
              </Box>
            )}
            description={item.description}
          />
        ))}
      </Box>

      {action && (
        <MainButton
          type='primary'
          size='large'
          styles={actionItem}
          onClick={action.handler}
        >
          <PlusOutlined style={plusIcon} />

          {action.label}
        </MainButton>
      )}
    </Box>
  );
};
