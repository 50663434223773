import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createCoinBaseCheckoutPayment = async (subscriptionUuid: string, table?: string) => {
  const userId = getSkyrexUuid();

  const data = {
    skyrexUserUuid: userId,
    subscriptionUuid,
    table: table || 'bot_success_subscriptions',
    paymentAsset: 'usdt.trc20',
  };

  const response = await axios.request({
    url: 'payment/createCoinPaymentsTransaction',
    data,
  });

  return response.data;
};
