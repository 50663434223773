import { memo, useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageWrapper } from 'widgets';
import { AppRoutesProps, routeConfig } from 'shared/config/route-config/route-config';
import { GuestRoute } from './guest-route';
import { ProtectedRoute } from './protected-route';
import SuspenseWrapper from './suspense-wrapper';

const AppRouter = () => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
      <SuspenseWrapper>
        {route.authOnly ? (
          <ProtectedRoute>
            <PageWrapper title={`${route.title} - Skyrexio`}>
              {route.element}
            </PageWrapper>
          </ProtectedRoute>
        ) : (
          <GuestRoute>
            {route.element}
          </GuestRoute>
        )}
      </SuspenseWrapper>
    );

    return (
      <Route
        key={route.path}
        path={route.path}
        element={element}
      />
    );
  }, []);

  return (
    <Routes>
      <Route
        path='/'
        element={(
          <Navigate to='/home' />
        )}
      />

      {Object.values(routeConfig).map(renderWithWrapper)}
    </Routes>
  );
};

export default memo(AppRouter);
