import { SxProps, Theme } from '@mui/material';

export const list: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: '18px',
    md: '32px',
  },
};
