import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const link: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  fontSize: getFontSize(10, 12),
  fontWeight: 400,
};
