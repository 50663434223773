import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getInviteFriendsUrl = async (url: string) => {
  const userId = getSkyrexUuid();
  
  const response = await axios.request({
    url: 'user/inviteFriends',
    data: {
      skyrexUserUuid: userId,
      returnUrl: url,
    },
  });
  
  return response?.data;
};
