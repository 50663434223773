import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const text: SxProps<Theme> = {
  '&>span, &>span>a': {
    fontSize: getFontSize(9, 13),
    fontWeight: 400,
    lineHeight: 'normal',
  },
};
