import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  padding: '30px 25px',
  backgroundColor: '#fff',
  border: '1px solid #B8B8B8',
  borderRadius: 2,
  width: '100%',
  maxWidth: 350,
};
