import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  padding: 0.5,
  backgroundColor: '#F6F6F6',
  borderRadius: 2,
  flex: 1,
  width: '100%',
};
