export const yAxis: echarts.YAXisComponentOption = {
  type: 'value',
  position: 'right',
  min: (value) => {
    return value.min > 0 ? value.min * 0.9 : value.min * 1.1;
  },
  max: (value) => {
    return value.max > 0 ? value.max * 1.1 : value.max * 0.9;
  },
  axisLabel: {
    formatter: (value: number) => {
      return value.toFixed(2);
    },
  },
  axisLine: {
    lineStyle: {
      color: '#9E9E9E',
      type: 'dotted',
      cap: 'round',
      join: 'round',
    },
  },
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
