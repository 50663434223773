import { SxProps, Theme } from '@mui/material';

export const filters: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  gap: 1,
};
