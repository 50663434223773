import { SxProps, Theme } from '@mui/material';

export const list: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    md: 'repeat(auto-fill, minmax(316px, 1fr))',
  },
  gap: '30px 20px',
};
