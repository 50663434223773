import { Box } from '@mui/material';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ICheckBox } from '../interfaces';
import { wrapper } from '../styles';

export const CheckBox = (props: ICheckBox) => {
  const {
    preffix,
    value,
    suffix,
    onChange,
    aligment,
  } = props;

  const onChangeHandler = (event: CheckboxChangeEvent) => {
    onChange?.(!event.target.value);
  };

  return (
    <Box sx={wrapper(aligment)}>
      {preffix}

      <Checkbox
        value={value}
        onChange={onChangeHandler}
      />

      {suffix}
    </Box>
  );
};
