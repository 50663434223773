import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import { IConnectedExchangeAccountStatus } from '../interfaces';
import {
  button,
  modal,
  modalChildren,
  text,
  title as titleStyles,
} from '../styles';

export const ConnectedExchangeAccountStatus = (props: IConnectedExchangeAccountStatus) => {
  const {
    buttonStyles,
    buttonTitle,
    isOpen,
    title,
    description,
    action,
    onClose,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={true}
      style={modal}
      onCancel={onClose}
      styles={modalChildren}
      width='100%'
      closable={true}
      destroyOnClose
      centered={true}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
      >
        <Box
          display='flex'
          flexDirection='column'
          gap={2}
        >
          <Title
            level={4}
            styles={titleStyles}
          >
            {title}
          </Title>

          <Text styles={text}>
            {description}
          </Text>
        </Box>

        <MainButton
          ghost
          type='primary'
          styles={buttonStyles || button}
          onClick={action}
        >
          {buttonTitle || 'Okay'}
        </MainButton>
      </Box>
    </Modal>
  );
};
