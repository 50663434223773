import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const text: SxProps<Theme> = {
  fontSize: getFontSize(10, 13),
  fontWeight: 400,
  alignSelf: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
};
