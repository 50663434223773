export const stringSorter = (a: string, b: string): number => {
  return a.localeCompare(b);
};
  
export const numberSorter = (a: string, b: string): number => {
  const isANull = a === '-';
  const isBNull = b === '-';

  if (isANull && isBNull) {
    return 0;
  } else if (isANull) {
    return -1;
  } else if (isBNull) {
    return 1;
  }

  const numberA = parseFloat(a);
  const numberB = parseFloat(b);

  return numberA - numberB;
};

export const dateSorter = (a: string, b: string): number => {
  const aDate = new Date(a).getTime();
  const bDate = new Date(b).getTime();

  return aDate - bDate;
};

interface IPairSorter {
  aFirstPair: string;
  aSecondPair: string;
  bFirstPair: string;
  bSecondPair: string;
}
export const pairSorter = (params: IPairSorter): number => {
  const {
    aFirstPair,
    aSecondPair,
    bFirstPair,
    bSecondPair,
  } = params;

  const aPairValue = `${aFirstPair} ${aSecondPair}`;
  const bPairValue = `${bFirstPair} ${bSecondPair}`;

  return stringSorter(aPairValue, bPairValue);
};
