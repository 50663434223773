import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  padding: {
    xs: '40px 0',
    md: '70px 0',
  },
  gap: {
    xs: 5,
    sm: 10,
  },
};
