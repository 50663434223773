import { SxProps, Theme } from '@mui/material';

export const left: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  width: '100%',
  maxWidth: 263,
  alignItems: 'center',
};
