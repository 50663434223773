//@ts-nocheck

import axios from 'axios';
import { adaptBinanceSymbol, adaptBybitSymbol, adaptOkxSymbol } from '../helpers/adapt-symbols';


export async function fetchBinanceExchangeInfo() {
  const response = await axios.get(
    'https://api.binance.com/api/v3/exchangeInfo',
  );
  return response.data.symbols
    .filter((symbol) => symbol.status === 'TRADING')
    .map(adaptBinanceSymbol);
}
  
// Fetch information from Bybit (implement similar to Binance)
export async function fetchBybitExchangeInfo() {
  const response = await axios.get(
    'https://api.bybit.com/v5/market/instruments-info?category=spot',
  );
    // return response.data.result.list.filter(symbol => symbol.status === 'Trading');
  return response.data.result.list
    .filter((symbol) => symbol.status === 'Trading')
    .map(adaptBybitSymbol);
  // Implement the fetching logic here
  // Ensure the output format is similar to fetchBinanceExchangeInfo()
}
  
// Fetch information from OKX (implement similar to Binance)
export async function fetchOkxExchangeInfo() {
  const response = await axios.get(
    'https://www.okx.com/api/v5/public/instruments?instType=SPOT',
  );
  return response.data.data
    .filter((symbol) => symbol.state === 'live')
    .map(adaptOkxSymbol);
}
