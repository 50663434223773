interface IDescription {
  basic: string;
  advanced: string;
  pro: string;
}
export const descriptions: IDescription = {
  basic :'Enjoy main Features with no subcription fee',
  advanced :'Get success fee reduction and unlimited Exchange accounts',
  pro :'Lowest success fee, unlimited access for all features',
};
