import { SxProps, Theme } from '@mui/material';

export const listItem: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  position: 'relative',
  fontSize: 14,
  fontWeight: 400,
  color: '#9E9E9E',
};
