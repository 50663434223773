import { SxProps, Theme } from '@mui/material';

export const icon: SxProps<Theme> = {
  maxWidth: {
    xs: 61,
    md: 120,
  },
  '&>svg': {
    width: '100%',
    height: '100%',
  },
  alignSelf: 'center',
};
