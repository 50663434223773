import { Box } from '@mui/material';
import { CryptoCardSmallIcon } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { IBot } from '../interfaces';
import { inner, planName, price, title, titleWrapper, wrapper } from '../styles';

export const Bot = (props: IBot) => {
  const {
    subscription,
  } = props;

  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={planName}>
          {subscription.name}
        </Title>

        <Text styles={price}>
          ${subscription.fee}
        </Text>
      </Box>

      <Box sx={inner}>
        <Text styles={title}>
          Payment method
        </Text>

        <Box>
          {CryptoCardSmallIcon}
        </Box>
      </Box>
    </Box>
  );
};
