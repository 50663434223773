import { Rule } from 'antd/es/form';

export const botNameValidator: Rule[] = [{
  required: true,
  message: 'Please input the bot name!',
}, {
  validator: (_, value) => {
    if (value && value.length > 20) {
      return Promise.reject(new Error('Bot name cannot be more than 20 characters.'));
    }
    return Promise.resolve();
  },
}];
