import { Text } from 'shared/ui';
import { IProps } from '../interfaces';

export const CopyRight = (props: IProps) => {
  const {
    styles,
  } = props;

  return (
    <Text
      type='secondary'
      styles={styles}
    >
      &#169; {new Date().getFullYear()} All rights reserved
    </Text>
  );
};
