export const MoreSecondary = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_57_6802)">
      <path d="M8.00001 4.66728C8.73638 4.66728 9.33334 4.07032 9.33334 3.33394C9.33334 2.59756 8.73638 2.00061 8.00001 2.00061C7.26363 2.00061 6.66667 2.59756 6.66667 3.33394C6.66667 4.07032 7.26363 4.66728 8.00001 4.66728Z" fill="#2ECD99" />
      <path d="M8.00001 9.33394C8.73638 9.33394 9.33334 8.73699 9.33334 8.00061C9.33334 7.26423 8.73638 6.66728 8.00001 6.66728C7.26363 6.66728 6.66667 7.26423 6.66667 8.00061C6.66667 8.73699 7.26363 9.33394 8.00001 9.33394Z" fill="#2ECD99" />
      <path d="M8.00001 14.0006C8.73638 14.0006 9.33334 13.4037 9.33334 12.6673C9.33334 11.9309 8.73638 11.3339 8.00001 11.3339C7.26363 11.3339 6.66667 11.9309 6.66667 12.6673C6.66667 13.4037 7.26363 14.0006 8.00001 14.0006Z" fill="#2ECD99" />
    </g>
    <defs>
      <clipPath id="clip0_57_6802">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
