interface IOrder {
  pendingPayment: number;
  active: number;
  finished: number;
};

const order: IOrder = {
  pendingPayment: 1,
  active: 2,
  finished: 3,
};

export const sortSubscriptions = (a: any, b: any) => {
  const aStatus = a.status as keyof IOrder;
  const bStatus = b.status as keyof IOrder;

  const statusSorting = order[aStatus] - order[bStatus];
  
  if (!statusSorting) {
    const aDate = new Date(a.paymentDate || a.createdDate || a.dateStart).getTime();
    const bDate = new Date(b.paymentDate || b.createdDate || b.dateStart).getTime();

    return bDate - aDate;
  }

  return statusSorting;
};
