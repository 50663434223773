export const getTitle = (title: string) => {
  switch (true) {
  case title.includes('advanced'):
    return 'Advanced';
  case title.includes('pro'):
    return 'Pro';
  default:
    return title;
  }
};
