import { IPayment } from '../interfaces';

export const getSubscriptionPaymentInfo = (paymentMethod: IPayment['title']) => {
  if (paymentMethod === 'crypto') {
    return {
      method: 'coinPayments',
      asset: 'usdt.trc20',
    };
  }

  return {
    method: 'stripe',
    asset: 'usd',
  };
};
