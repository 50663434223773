import { SxProps, Theme } from '@mui/material';

export const fieldsWrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: {
    xs: 'auto',
    sm: 381,
  },
  gap: 4,
};
