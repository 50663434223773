import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { formatterByQuote } from 'pages/home/helpers/formatter-by-quote';
import { Colors } from 'shared/consts/colors';
import { currencyFormatter } from 'shared/helpers/currency-formatter';

export const getTooltip = (
  selectedCurrency: string,
  exchangeSymbols: CurrentSymbol[],
  isMainChart?: boolean,
  isCopyBot?: boolean,
) => {
  const btc = selectedCurrency === 'BTC';

  const getValueColor = (params: any) => {
    if (isMainChart) {
      return `${btc ? Colors.BLUE : Colors.GREEN}`;
    }

    const values = params[0].data[1];


    if (values > 0) {
      return Colors.GREEN;
    }
    if (values < 0) {
      return Colors.RED;
    }

    return Colors.GREY;
  };

  const getChartValue = (params: any) => {
    if (isMainChart) {
      return `${btc ? currencyFormatter(+params[0].data[1], 6) : currencyFormatter(+params[0].data[1], 2)} ${btc ? 'BTC' : 'USDT'}`;
    }

    return `${formatterByQuote(params[0].data[1], selectedCurrency, exchangeSymbols)} ${isCopyBot ? '%' : selectedCurrency}`;
  };

  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'axis',
    padding: 0,
    position: function (point, _, __, ___, size) {
      const x = point[0];
      const y = point[1];
      const tooltipWidth = size.contentSize[0];
      const chartWidth = size.viewSize[0];

      if (x + tooltipWidth > chartWidth) {
        return [x - (tooltipWidth - 30), y];
      }

      if (x < tooltipWidth / 2) {
        return [x + 10, y];
      }

      return [x, y];
    },
    formatter: (params: any) => {
      return `
      <div style='display: flex; flex-direction: column; align-items: center; gap: 4px; background-color: #fff; padding: 14px 25px; font-family: Poppins;'>
        <h3 style='fontSize: 12px; font-weight: 400; color: #3A3A3C; margin: 0;'>
                ${params[0].data[0]}
        </h3>

        <strong style='color: ${getValueColor(params)}; font-size: 14px; font-weight: 600;'>
            ${getChartValue(params)}
        </strong>
      </div>
    `;
    },
  };

  return tooltip;
}; 
