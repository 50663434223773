import { SxProps, Theme } from '@mui/material';

export const Wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  padding: 1.8,
  border: {
    xs: 'none',
    sm: '1px solid #F2F2F2',
  },
  borderRadius: 2,
  cursor: 'pointer',
};
