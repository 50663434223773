export const PlusGreen = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4623_1062)'>
      <path d='M14 7.33431H8.66667V2.00098H7.33333V7.33431H2V8.66764H7.33333V14.001H8.66667V8.66764H14V7.33431Z' fill='#2ECD99' />
    </g>
    <defs>
      <clipPath id='clip0_4623_1062'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
