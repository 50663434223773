import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getTitle } from 'shared/helpers';
import { Input, MainButton, Text, Title } from 'shared/ui';
import { applyPromoCode, getSubscriptionDays } from '../../../api';
import { payments } from '../../../consts';
import { IPayment } from '../../../interfaces';
import { getPeriod } from '../helpers';
import { ISkyrex } from '../interfaces';
import {
  applyButton,
  inner,
  inputError,
  inputWrapper,
  item,
  list,
  notify,
  planInfo,
  planPrice,
  title,
  wrapper,
} from '../styles';
import { planTitle } from '../styles/plan-title';

export const Skyrex = (props: ISkyrex) => {
  const {
    subscription,
    isUpdate,
    plan,
    promo,
    changePromo,
    selectedPayentMethod,
    changePaymentMethod,
  } = props;

  const [days, setDays] = useState<number>(0);
  const [discount, setDiscount] = useState<number | null>(0);

  const applyPromo = async () => {
    if (!promo) {
      return;
    }

    const promoData = await applyPromoCode({
      promocode: promo,
      planName: (plan || subscription).planName,
    });
    
    const discount = promoData.data.discountedFee;
    if (!discount) {
      changePromo('');
      setDiscount(null);

      return;
    }

    setDiscount(discount);
  };

  const getPrice = () => {
    let price = (plan || subscription).originalFee;
    const isAppliedDiscount = !!discount;

    if (isAppliedDiscount) {
      const ceiledDiscount = Math.floor(+discount);
      price = ceiledDiscount;
    }

    return (
      <Box sx={planPrice}>
        {isAppliedDiscount && (
          <strong>
            ${(plan || subscription).originalFee}
          </strong>
        )}

        <Text>${price}</Text> / {getPeriod((plan || subscription).originalSubscriptionDays)}
      </Box>
    );
  };

  const getNotify = () => {
    if (!isUpdate || !days) {
      return;
    }

    const finishedDate = dayjs(subscription.finishedDate).diff(dayjs(), 'days');

    return (
      <Box sx={notify}>
        Your {finishedDate} days left in {getTitle(subscription.planName)} plan will be converted into <strong>{days} days</strong> of {getTitle(plan.planName)} plan after purchase
      </Box>
    );
  };

  const cancelHandler = () => {
    changePromo('');
    setDiscount(0);
  };

  const getSubscriptionCalculatedDays = async () => {
    const subscriptionDaysdata = await getSubscriptionDays((plan || subscription).planName);
    const days = subscriptionDaysdata.data.subscriptionDays;

    setDays(days);
  };

  useEffect(() => {
    if (!plan || !isUpdate) {
      return;
    }

    getSubscriptionCalculatedDays();
  }, [plan]);
  
  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Box sx={planInfo}>
          <Title styles={planTitle}>
            {getTitle((plan || subscription).planName)} Plan
          </Title>

          {getPrice()}
        </Box>

        <Box sx={inputWrapper}>
          <Input
            value={promo}
            onChange={changePromo}
            placeholder='Discount code'
            disabled={!!discount}
            addonAfter={(
              <MainButton
                size='small'
                type='primary'
                styles={applyButton}
                onClick={discount ? cancelHandler : applyPromo}
              >
                {discount ? 'Cancel' : 'Apply'}
              </MainButton>
            )}
          />

          {discount === null && (
            <Text
              type='danger'
              styles={inputError}
            >
              Wrong code
            </Text>
          )}
        </Box>

        {getNotify()}
      </Box>

      <Box sx={inner}>
        <Text styles={title}>
          Choose a payment method
        </Text>

        <Box sx={list}>
          {payments.map((payment: IPayment) => (
            <div
              key={payment.title}
              onClick={changePaymentMethod(payment.title)}
            >
              <Box sx={item(payment.title === selectedPayentMethod)}>
                {payment.icon}
              </Box>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
