export function sanitizeSymbol(symbol: string) {
  return symbol.replace(/-/g, '');
}
  
export function getPrecisionNumber(tickSize: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20,
  });
  
  const formattedValue = formatter.format(+tickSize);
  
  const result = formattedValue?.toString().includes('.')
    ? formattedValue?.toString().split('.')[1].length
    : 0;
  
  return result;
}
  
export class ExchangeSymbolInfo {
  symbol: any;
  baseAsset: any;
  baseAssetPrecision: any;
  quoteAsset: any;
  quoteAssetPrecision: any;
  priceTickSize: any;
  priceMin: any;
  priceMax: any;
  minPrice: any;
  maxPrice: any;
  minNotional: any;
  maxNotional: any;
  lotMax: any;
  lotMin: any;
  lotTickSize: any;
  marketLotMax: any;
  marketLotMin: any;
  marketLotTickSize: any;
  chartPrecision: any;
  baseCurrencyChartPrecision: any;
  okxInstId: any;
    
    
  constructor({
    symbol,
    baseAsset,
    baseAssetPrecision,
    quoteAsset,
    quoteAssetPrecision,
    priceTickSize,
    priceMin,
    priceMax,
    minPrice,
    maxPrice,
    minNotional,
    maxNotional,
    lotMax,
    lotMin,
    lotTickSize,
    chartPrecision,
    baseCurrencyChartPrecision,
    marketLotMax,
    marketLotMin,
    marketLotTickSize,
    okxInstId,
  }: {
    symbol: any;
    baseAsset: any;
    baseAssetPrecision: any;
    quoteAsset: any;
    quoteAssetPrecision: any;
    priceTickSize: any;
    priceMin: any;
    priceMax: any;
    minPrice: any;
    maxPrice: any;
    minNotional: any;
    maxNotional: any;
    lotMax: any;
    lotMin: any;
    lotTickSize: any;
    chartPrecision: any;
    baseCurrencyChartPrecision: any;
    marketLotMax: any;
    marketLotMin: any;
    marketLotTickSize: any;
    okxInstId: any;
  }) {
    this.symbol = symbol;
    this.baseAsset = baseAsset;
    this.baseAssetPrecision = baseAssetPrecision;
    this.quoteAsset = quoteAsset;
    this.quoteAssetPrecision = quoteAssetPrecision;
    this.priceTickSize = priceTickSize;
    this.priceMin = priceMin;
    this.priceMax = priceMax;
    this.minPrice = minPrice;
    this.maxPrice = maxPrice;
    this.minNotional = minNotional;
    this.maxNotional = maxNotional;
    this.lotMax = lotMax;
    this.lotMin = lotMin;
    this.lotTickSize = lotTickSize;
    this.marketLotMax = marketLotMax;
    this.marketLotMin = marketLotMin;
    this.marketLotTickSize = marketLotTickSize;
    this.chartPrecision = chartPrecision;
    this.baseCurrencyChartPrecision = baseCurrencyChartPrecision;
    this.okxInstId = okxInstId;
  }
}
  
