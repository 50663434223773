import { Box } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { errorText } from '../styles';

type Props = {
  bybit: boolean;
};

export const Error = ({
  bybit, 
}: Props) => (
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    gap={2}
    padding='28px 0'
  >
    <Title level={5}>Account is empty</Title>

    <Text
      type='danger'
      styles={errorText}
    >
      {bybit
        ? 'Convert to Unified account, update API or deposit'
        : 'Update API keys or deposit funds to continue trading'}
    </Text>
  </Box>
);
