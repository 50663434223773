import { preCode } from '../styles/pre-code';

export const renderJson = (data: any) => {
  let row = '';

  if (typeof data === 'string') {
    row = data;
  } else {
    row = JSON.stringify(data, null, 2);
  }

  return (
    <pre style={preCode}>
      {row}
    </pre>
  );
};
