import { SxProps, Theme } from '@mui/material';

export const icon: SxProps<Theme> = {
  width: 245,
  height: {
    xs: 100,
    md: 140,
  },
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
