import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  padding: '16px 0',
  flex: 1,
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
};
