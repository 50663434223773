import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { TooltipProps } from '../interfaces/tooltip.types';

const getFormattedShare = (selectedCurrency: string, data: TooltipProps) => {
  const isBtc = selectedCurrency === 'BTC';
  return isBtc ? data.overallUsdtShare?.toFixed(2) : data.overallBtcShare?.toFixed(2);
};

export const getTooltip = (selectedCurrency: string) => {

  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'item',
    padding: 0,
    formatter: (params: any) => {
      return `
            <div style='display: flex; flex-direction: column; gap: 9px; background-color: #fff; padding: 14px 12px;'>
              <h3 style='fontSize: 10px; font-weight: 600; color: #3A3A3C;'>
                ${params.name} ${getFormattedShare(selectedCurrency, params.data)}%
              </h3>
      
              <div style='display: flex; flex-direction: column; gap: 2px;'>
                <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
                  Amount
                </span>
      
                <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
                  ${currencyFormatter(+params?.data?.amountForTooltip, 6)}
                </span>
      
                <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
                  ₿ ${currencyFormatter(+params.data.totalBtc, 6)}
                </span>
      
                <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
                  $ ${currencyFormatter(+params.data.totalUsdt, 2)}
                </span>
              </div>
            </div>
          `;
    },
  };

  return tooltip;
};
