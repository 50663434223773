import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { formatterByQuote } from 'pages/home/helpers/formatter-by-quote';
import { Colors } from 'shared/consts/colors';

export const getTooltip = ({
  exchangeSymbols, 
  quoteAsset, 
  isCopyBot,
}: Props) => {
  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'axis',
    padding: 0,
    formatter: (params: any) => {      
      const value = params[0].value;
      const color = value >= 0 ? Colors.GREEN : Colors.RED;
      
      return `
        <div style='display: flex; flex-direction: column; align-items: center; gap: 4px; background-color: #fff; padding: 14px 25px; font-family: Poppins;'>
          <h3 style='fontSize: 12px; font-weight: 400; color: #3A3A3C; margin: 0;'>
            ${params[0].name}
          </h3>

          <strong style='color: ${color}; font-size: 14px; font-weight: 600;'>
            ${formatterByQuote(params[0].value, quoteAsset, exchangeSymbols)} ${isCopyBot ? '%' : quoteAsset}
          </strong>
        </div>
      `;
    },
  };
  
  return tooltip;
};

type Props = {
  exchangeSymbols: CurrentSymbol[]; 
  quoteAsset: string;
  isCopyBot?: boolean;
};
