import { Box } from '@mui/material';
import { Skeleton } from 'antd';
import { button } from '../styles';

export const Loading = () => (
  <Box
    display='flex'
    flexDirection='column'
    justifyContent='space-between'
    gap='31px'
    flex={1}
  >
    <Box
      display='flex'
      flexDirection='column'
      gap={1.55}
    >
      <Skeleton.Input
        active
        size='small'
      />

      <Skeleton.Input
        active
        size='small'
      />
    </Box>

    <Skeleton.Input
      block
      active
      size='small'
    />

    <Box
      display='flex'
      flexDirection='column'
      gap={1}
    >
      <Skeleton.Input
        active
        block
        size='small'
      />
      <Skeleton.Input
        active
        block
        size='small'
      />
    </Box>

    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
    >
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Skeleton.Button
          active
          size='small'
          style={button}
        />

        <Skeleton.Button
          active
          size='small'
          style={button}
        />
      </Box>

      <Skeleton.Button
        active
        size='small'
        style={button}
      />
    </Box>
  </Box>
);
