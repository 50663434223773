import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

export const inner = (horizontalAligment?: CSSProperties['alignItems']) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    alignItems: horizontalAligment || 'center',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: {
      xs: 2,
      sm: 5,
    },
  };

  return styles;
};
