import { SxProps, Theme } from '@mui/material';

export const titleWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 3,
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};
