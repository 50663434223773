import { CSSProperties } from 'react';

export const popup: CSSProperties = {
  width: '100%',
  height: '100dvh',
  padding: '20px 16px',
  backgroundColor: '#29A97F',
  overflow: 'scroll',
  display: 'flex',
};
