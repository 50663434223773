import { SxProps, Theme } from '@mui/material';

export const wrapper = (isAbsolute: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  };

  if (isAbsolute) {
    styles.position = 'absolute';
    styles.height = '100%';
  }

  return styles;
};
