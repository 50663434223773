import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const priceWrapper: SxProps<Theme> = {
  lineHeight: 'normal',
  '&>strong': {
    fontSize: getFontSize(26, 41),
    fontWeight: 700,
  },
  '&>span': {
    fontSize: 15,
    fontWeight: 500,
  },
};
