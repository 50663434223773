import { Box } from '@mui/material';
import { Divider } from 'antd';
import { MainButton, Text } from 'shared/ui';
import { ICard } from '../interfaces';
import {
  badge,
  button as buttonStyles,
  descriptionStyles,
  inner,
  list,
  listItem,
  listWrapper,
  priceWrapper,
  titleStyles,
  titleWrapper,
  wrapper,
} from '../styles';
import { SuccessPlanCheckIcon } from 'shared/icons';

export const Card = (props: ICard) => {
  const {
    title,
    description,
    price,
    period,
    items,
    isBset,
    button,
  } = props;

  const renderItem = (item: (string | null), index: number) => {
    if (item === null) {
      return (
        <Divider
          key={index}
          style={{
            margin: 0,
            backgroundColor: '#E9E9E9',
          }}
        />
      );
    }

    return (
      <Box
        key={index}
        sx={listItem}
      >
        {SuccessPlanCheckIcon}
        
        {item}
      </Box>
    );
  };

  const getPrice = () => {
    if (price === null) {
      return null;
    }
    
    if (!price) {
      return (
        <Box sx={priceWrapper}>
          <strong>Free</strong>  
        </Box>
      );
    }

    return (
      <Box sx={priceWrapper}>
        <strong>${price}</strong>
        
        <Text type={isBset ? 'success' : undefined}>
          /{period}
        </Text>
      </Box>
    );
  };

  const getButtonStyles = () => {
    if (isBset) {
      return {
        ...buttonStyles,
        color: '#fff',
      };
    }

    return buttonStyles;
  };

  return (
    <Box sx={wrapper(!!isBset)}>
      {isBset && (
        <Box sx={badge}>
          Best value
        </Box>
      )}

      <Box sx={inner}>
        <Box sx={titleWrapper}>
          <Text
            type={isBset ? 'success' : undefined}
            styles={titleStyles}
          >
            {title}
          </Text>

          <Text styles={descriptionStyles(!!isBset)}>
            {description}
          </Text>
        </Box>

        {getPrice()}

        <Box sx={listWrapper}>
          <Text styles={descriptionStyles(!!isBset)}>
            What’s included
          </Text>

          <Box sx={list}>
            {items.map((item: string | null, index: number) => renderItem(item, index))}
          </Box>
        </Box>
      </Box>

      {button && (
        <MainButton
          type='primary'
          styles={getButtonStyles()}
          onClick={button.handler}
          disabled={button.disabled}
        >
          {button.title}
        </MainButton>
      )}
    </Box>
  );
};
