import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { formatNullValues } from 'pages/manual-trading/trading-history/helpers/format-null-values';
import { getTextType } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-text-type';
import { getMappedStatusColor } from 'pages/trading-bots/my-bot/helpers/get-mapped-status-color';
import { capitalizeFirstLetter } from 'pages/trading-bots/trading-history/helpers/get-capitalized-first-letter';
import { dateSorter, pairSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

export const columnsCopyBot: TableColumnsType = [{
  title: 'Pair',
  dataIndex: 'pair',
  key: 'pair',
  sorter: (a, b) => pairSorter({
    aFirstPair: a.pair.first,
    aSecondPair: a.pair.second,
    bFirstPair: b.pair.first,
    bSecondPair: b.pair.second,
  }),
  width: 120,
  render: (pair: any) => (
    <Box
      display='flex'
      alignItems='center'
      gap={0.5}
    >
      <Text>{pair.first}</Text>
      <Text type='secondary'>
        {pair.second}
      </Text>
    </Box>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Create date',
  dataIndex: 'create',
  key: 'create',
  sorter: (a, b) => dateSorter(a.create, b.create),
  width: 140,
  render: (date: string) => (
    <Text type='secondary'>
      {dayjs(date).format('DD.MM.YYYY, HH:mm:ss')}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
  defaultSortOrder: 'descend',
}, {
  title: 'Close date',
  dataIndex: 'closeDate',
  key: 'closeDate',
  sorter: (a, b) => dateSorter(a.closeDate, b.closeDate),
  width: 140,
  render: (date: string) => (
    <Text type='secondary'>
      {date ? dayjs(date).format('DD.MM.YYYY, HH:mm:ss') : '-'}
    </Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  sorter: (a, b) => stringSorter(a.status, b.status),
  width: 100,
  render: (status: string) => {
    const textType = getMappedStatusColor(status);
    return (
      <Text type={textType}>
        {capitalizeFirstLetter(status)}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Entry price',
  dataIndex: 'entryPrice',
  key: 'entryPrice',
  width: 100,
  render: (entryPrice: string) => (
    <Text>{entryPrice}</Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Close price',
  dataIndex: 'closePrice',
  key: 'closePrice',
  width: 100,
  render: (closePrice: string) => (
    <Text>{closePrice}</Text>
  ),
  showSorterTooltip: false,
}, {
  title: 'Return %',
  dataIndex: 'returnPercent',
  key: 'returnPercent',
  sorter: (a, b) => stringSorter(a.returnPercent, b.returnPercent),
  width: 100,
  render: (value: any) => {
    const formattedValue = formatNullValues(value);
    
    if (formattedValue === '-') {
      return <Text>-</Text>;
    }
    
    const textType = getTextType(+value);
    return (
      <Text type={textType}>
        {`${formatNullValues(value)}%`}
      </Text>
    );
  },
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];
