import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: 1.5,
  padding: 2,
  flex: 1,
  gap: 3.8,
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: '0px 1px 25px 0px rgba(0, 0, 0, 0.08)',
};
