import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: '14px',
    sm: '28px',
  },
  textAlign: 'center',
  alignItems: 'center',
};
