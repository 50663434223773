import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getUserSkyrexUuid } from 'entities/user/model/selectors/get-user-skyrex-uuid/get-user-skyrex-uuid';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getExchanges,
  getExchangesIds,
} from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import {
  logo,
  platformLogo,
  getWrapperStyles,
  title,
} from 'widgets/exchanges/styles';
import { BinanceLogo, Verify } from 'shared/icons';
import { Loader, Title } from 'shared/ui';
import {
  ConnectedExchangeAccountStatus as ExchangeUpdatedModalStatus,
  TwoFactorFailed as ErrorConnectModal,
} from 'shared/ui/modals';

export const CreateBinance = (props: CreateExchangeProps) => {
  const {
    isModalView,
    returnToAllExchanges,
    handleClose,
  } = props;
  const uuid = useSelector(getUserSkyrexUuid);

  const [isApiKeysConnection, setIsApiKeysConnection] = useState<boolean>(false);
  const [exchangeTitle, setExchangeTitle] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');
  const [apiSecret, setApiSecret] = useState<string>('');
  const [modalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectExchangeError, setExchangeConnectError] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleCloseConnectErrorModal = () => setExchangeConnectError(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    handleClose?.();
  };

  const connectBinanceWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForConnectOauth = {
        uuid,
        exchangeTitle,
        exchangeName: 'binance',
        apiKey,
        apiSecret,
      };

      const binanceConnectResponse = await connectExchange(dataForConnectOauth);

      if (binanceConnectResponse.success) {
        const exchangesIdsResponse = await getExchangesIds(uuid);
        if (exchangesIdsResponse.success) {
          const {
            accounts,
          } = exchangesIdsResponse.data;
          const accountsIds = accounts.map(
            (account: ExchangeAccount) => account.exchangeAccountUuid,
          );

          const allAccountsData = await getExchanges(accountsIds);
          dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));
          setIsLoading(false);
          setIsModalOpen(true);
          
          return;
        }

        setExchangeConnectError(true);
        setIsModalOpen(true);
      }

      setExchangeConnectError(true);
      setIsModalOpen(true);
    } catch (error) {
      setExchangeConnectError(true);
      setIsModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTitle = (value: string) => {
    setExchangeTitle(value);
  };

  const handleChangeApiKey = (value: string) => {
    setApiKey(value);
  };

  const handleChangeApiSecret = (value: string) => {
    setApiSecret(value);
  };

  const connectBinance = () => {
    const randomHash = generateRandomHash(48);
    const binanceUrl = `https://accounts.binance.com/oauth/authorize?client_id=9PUQLoXYrS&redirect_uri=https%3A%2F%2Fapp.skyrexio.com%2Foauth%2Fcreate%2Fbinance&response_type=code&scope=user%3AopenId%2Ccreate%3Aapikey%2Caccount%3Astatus&state=${randomHash}`;
    window.open(binanceUrl, '_self');
  };

  const switchForm = () => {
    setIsApiKeysConnection((prev) => !prev);
  };

  const getContent = () => {
    if (isApiKeysConnection) {
      return (
        <Form
          returnToAllExchanges={returnToAllExchanges}
          type='binance'
          fields={[{
            label: 'Enter any name',
            value: exchangeTitle,
            placeholder: 'Name',
            onChange: handleChangeTitle,
          }, {
            label: 'Paste your API key',
            value: apiKey,
            placeholder: 'Key',
            onChange: handleChangeApiKey,
          }, {
            label: 'Paste your API secret',
            mask: true,
            value: apiSecret,
            placeholder: 'Secret',
            onChange: handleChangeApiSecret,
          }]}
          actionBlock={(
            <ActionBlock
              button={{
                label: 'Connect Binance',
                action: connectBinanceWithApiKeys,
              }}
              link={{
                label: (
                  <Box display='flex' alignItems='center' gap={1}>
                    Connect with OAuth
                    {Verify}
                  </Box>
                ),
                action: switchForm,
              }}
              createAccount={{
                label: 'Create Binance account',
                link: 'https://accounts.binance.com/register?ref=QB4A8B0X',
              }}
            />
          )}
        />
      );
    }

    return (
      <Info
        returnToAllExchanges={returnToAllExchanges}
        steps={[
          'Click on Connect Binance button',
          'You will be redirected to Binance.com',
          'Confirm your connection to Skyrex',
        ]}
        actionBlock={(
          <ActionBlock
            button={{
              label: 'Connect Binance',
              action: connectBinance,
            }}
            link={{
              label: 'Connect with API keys',
              action: switchForm,
            }}
            createAccount={{
              label: 'Create Binance account',
              link: 'https://accounts.binance.com/register?ref=QB4A8B0X',
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      <ExchangeUpdatedModalStatus
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={'Exchange account connected successfully'}
        description={'Now you are in one click to start trading bot or create manual trade using your account'}
        action={handleCloseModal}
      />

      <ErrorConnectModal
        isOpen={connectExchangeError}
        title='Failed to connect account'
        description='Check API keys settings are correct and try again'
        closeHandler={handleCloseConnectErrorModal}
      />

      <Box position='relative'>
        {isLoading && (
          <Loader />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title styles={title}>
              Connect
            </Title>

            <Box sx={platformLogo}>
              {BinanceLogo}
            </Box>

            <Title styles={title}>
              exchange
            </Title>
          </Box>

          {getContent()}
        </Box>
      </Box>
    </>
  );
};
