import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: {
    xs: '30px',
    md: '50px',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  '&>img': {
    width: {
      xs: '100%',
      md: 'auto',
    },
    maxWidth: 217,
  },
  textAlign: {
    xs: 'center',
    md: 'left',
  },
};
