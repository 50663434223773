import { Modal } from 'antd';
import { WhatIncluded } from 'widgets';
import { getTitle } from 'shared/helpers';
import { descriptions, getItems } from '../consts';
import { IPlan } from '../interfaces';
import { modal, modalChildren } from '../styles';
import { TPlan } from '../types';

export const Plan = (props: IPlan) => {
  const {
    plan,
    isOpen,
    closeHandler,
  } = props;

  const getIndex = () => {
    const title = getTitle(plan.planName).toLowerCase();
    switch (title) {
    case 'basic':
      return 0;
    case 'advanced':
      return 1;
    case 'pro':
      return 2;
    default:
      return 0;
    }
  };

  if (!plan) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <WhatIncluded
        title={getTitle(plan.planName)}
        description={descriptions[getTitle(plan.planName).toLowerCase() as TPlan]}
        price={null}
        isBset={plan.planName.includes('pro')}
        items={getItems(plan, getIndex())}
        button={null}
      />
    </Modal>
  );
};
