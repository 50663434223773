import { SxProps, Theme } from '@mui/material';

export const left: SxProps<Theme> = {
  display: 'flex'  ,
  flexDirection: 'column',
  flex: 1,
  gap: 1,
  maxWidth: {
    xs: 144,
    md: '100%',
  },
};
