import { SxProps, Theme } from '@mui/material';

export const actionWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'initial',
    sm: 'center',
  },
  gap: 1,
  flexDirection: {
    xs: 'column-reverse',
    sm: 'row',
  },
};
