import { SxProps, Theme } from '@mui/material';

export const block: SxProps<Theme> = {
  padding: '32px 20px',
  backgroundColor: '#F2F2F2',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
};
