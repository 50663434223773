import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ActiveDeal } from './fetch-all-deals';

export const calculateAndFormat = (exchangeSymbols: any[], symbol: string, executedQuote: string, executedBase: string): string => {
  const precision = exchangeSymbols.find((element) => element.symbol === symbol);
    
  const quoteValue = parseFloat(executedQuote);
  const baseValue = parseFloat(executedBase);
  
  if (quoteValue === 0 || baseValue === 0) {
    return '-';
  }
    
  const result = quoteValue / baseValue;
  if (isNaN(result)) {
    return '-';
  }
  
  return currencyFormatter(result, precision?.chartPrecision!);
};
  

export const getEntryPrice = (trade: ActiveDeal, exchangeCode: string, binanceSymbols: CurrentSymbol[], bybitSymbols: CurrentSymbol[], okxSymbols: CurrentSymbol[]): string => {
  const symbol = `${trade.baseSymbol}${trade.quoteSymbol}`;
  
  switch (exchangeCode) {
  case 'binance':
    return calculateAndFormat(binanceSymbols, symbol, trade.executedQuoteFirst, trade.executedBaseFirst);
  case 'bybit':
    return calculateAndFormat(bybitSymbols, symbol, trade.executedQuoteFirst, trade.executedBaseFirst);
  case 'okx':
    return calculateAndFormat(okxSymbols, symbol, trade.executedQuoteFirst, trade.executedBaseFirst);
  default:
    return '';
  }
};
