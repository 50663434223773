import { Box, Hidden } from '@mui/material';
import { Layout } from 'antd';
import { Logo } from 'shared/icons';
import { CopyRight } from 'widgets';
import {
  layoutFooter,
  layoutInner,
  layoutWrapper,
  left
} from '../styles';

export const Footer = () => (
  <Layout.Footer style={layoutFooter}>
    <Box sx={layoutWrapper}>
      <div className='container'>
        <Box sx={layoutInner}>
          <Box sx={left}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-between'
              minHeight='100%'
            >
              {Logo}

            </Box>

            <Hidden smDown>
              <CopyRight />
            </Hidden>
          </Box>

          <Hidden smUp>
            <Box textAlign='center'>
              <CopyRight />
            </Box>
          </Hidden>
        </Box>
      </div>
    </Box>
  </Layout.Footer>
);
