import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const middleItemValue: CSSProperties = {
  fontSize: getFontSize(12, 15),
  fontWeight: 400,
  lineHeight: 'normal',
  flex: 1,
  display: 'block',
};
