import { Button } from 'antd';
import { IProps } from '../interfaces';

export const TextButton = (props: IProps) => {
  const {
    children,
    size,
    styles,
    icon,
    onClick,
  } = props;

  return (
    <Button
      type='text'
      size={size}
      style={styles}
      onClick={onClick}
    >
      {children}

      {icon}
    </Button>
  );
};
