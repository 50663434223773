import { SxProps, Theme } from '@mui/material';

export const badge: SxProps<Theme> = {
  position: 'absolute',
  right: '25px',
  top: '-15px',
  padding: '7px',
  backgroundColor: '#2ECD99',
  borderRadius: '45px',
  fontSize: 11,
  fontWeight: 700,
  color: '#fff',
};
