import { SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';

export const wrapper = (aligment?: CSSProperties['alignItems']): SxProps<Theme> => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    alignItems: aligment || 'center',
    gap: 1,
  };

  return styles;
};
