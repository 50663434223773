import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { ICreateSkyrexSubscription } from '../interfaces';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createSkyrexSubscription = async (params: ICreateSkyrexSubscription) => {
  const response = await axios.request({
    url: 'subscription/createSkyrexSubscription',
    data: params,
  });

  return response.data;
};
