import { AppleCardIcon, BankCardIcon, CryptoCardIcon } from 'shared/icons';
import { IPayment } from '../interfaces';

export const payments: IPayment[] = [{
  title: 'card',
  icon: BankCardIcon,
}, {
  title: 'apple',
  icon: AppleCardIcon,
}, {
  title: 'crypto',
  icon: CryptoCardIcon,
}];
