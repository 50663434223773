export const getCalculatedWinRate = (totalTrades: number, positiveTrades: number): string => {
  if (totalTrades === 0) {
    return '0%';
  }
  
  const winRate = (positiveTrades / totalTrades) * 100;
  
  if (isNaN(winRate) || !isFinite(winRate)) {
    return '0%';
  }
  
  return `${winRate.toFixed(2)}%`;
};
