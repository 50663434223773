import { TextProps } from 'antd/es/typography/Text';

export const getTextType = (value?: number): TextProps['type'] => {
  if (value === undefined || isNaN(value) || value === 0) {
    return 'secondary';
  }
  return value < 0 ? 'danger' : 'success';
};
  
export const formatChangeText = (value?: number, percent?: number): string => {
  
  if (value === undefined || isNaN(value) || percent === undefined || isNaN(percent)) {
    return '-';
  }
  const formattedValue = `${value > 0 ? '+' : ''}${value}`;
  const formattedPercent = `${percent > 0 ? '+' : ''}${percent}%`;
  return `${formattedValue} (${formattedPercent})`;
};
