export const getMappedStatusColor = (status: string): 'danger' | 'success' | undefined => {
  switch (status.toLowerCase()) {
  case 'failed':
    return 'danger';
  case 'active':
    return 'success';
  default:
    return undefined;
  }
};
