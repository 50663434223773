export const ConfettiRight = (
  <svg xmlns="http://www.w3.org/2000/svg" width="71" height="71" viewBox="0 0 71 71" fill="none">
    <path d="M7.36348 50.4627L5.75488 53.5967C7.79336 50.2131 20.468 55.025 23.699 52.9726C23.9348 52.8201 24.115 52.6121 24.2398 52.3625L25.6404 49.5891C23.4217 52.5428 9.8041 47.19 7.36348 50.4627Z" fill="#FF9522" />
    <path d="M20.2045 34.5293C19.2477 34.4184 18.5821 34.46 18.1522 34.6264C17.958 34.6957 17.8055 34.8482 17.7084 35.0285L16.1969 37.9268C16.5297 37.5107 17.3063 37.3582 18.693 37.5246C23.7823 38.1348 28.3168 38.2596 29.0934 36.5539L30.6049 33.5586C29.8422 35.2781 25.2938 35.1533 20.2045 34.5293Z" fill="#FF9522" />
    <path d="M24.0596 25.6266L22.5481 28.6219C28.0256 32.7959 29.7036 35.2365 29.0934 36.5539L30.6049 33.5586C31.2151 32.2412 29.5372 29.8006 24.0596 25.6266Z" fill="#FEBD55" />
    <path d="M17.5281 35.3891L16.1969 37.9406C14.4496 40.1039 25.2521 49.1314 24.3092 52.2377L25.6404 49.6029C27.5679 47.0514 17.1676 38.2596 17.5281 35.3891Z" fill="#FEBD55" />
    <path d="M7.00299 51.4195C7.01685 51.267 7.05845 51.1145 7.10006 50.9896L5.63013 53.8463C5.5608 54.0127 5.50533 54.2068 5.49146 54.4148C4.92291 59.5457 18.6237 68.4068 18.6237 68.4068L20.1352 65.4115C20.1491 65.4115 6.4483 56.5365 7.00299 51.4195Z" fill="#FEBD55" />
    <path d="M50.962 35.3336L53.6522 37.6217C50.8233 34.8621 58.4086 23.6297 57.1606 20.0103C57.0635 19.7469 56.8971 19.5111 56.6891 19.3309L54.3178 17.3201C56.6614 20.1906 48.3272 32.2135 50.962 35.3336Z" fill="#FF9522" />
    <path d="M38.3983 19.1922C38.0655 20.0936 37.9545 20.7453 38.0239 21.2029C38.0516 21.4109 38.1625 21.5912 38.3151 21.7299L40.7834 23.8654C40.4645 23.4494 40.4784 22.659 40.9637 21.3416C42.7248 16.5297 43.8897 12.1338 42.4059 10.9967L39.8405 8.8334C41.3243 9.98437 40.1594 14.3803 38.3983 19.1922Z" fill="#FF9522" />
    <path d="M30.6049 13.3957L33.1703 15.559C38.4953 11.1908 41.241 10.1092 42.392 10.9967L39.8404 8.8334C38.6894 7.9459 35.9299 9.02754 30.6049 13.3957Z" fill="#FEBD55" />
    <path d="M38.6062 21.9934L40.7834 23.8793C42.489 26.0703 53.7492 17.6391 56.5642 19.2615L54.3039 17.3617C52.2654 14.8934 41.3242 23.0057 38.6062 21.9934Z" fill="#FEBD55" />
    <path d="M51.8078 35.9021C51.6552 35.8467 51.5304 35.7912 51.4056 35.708L53.8463 37.7881C53.9988 37.899 54.1652 37.9822 54.3593 38.0516C59.2267 39.7711 71 28.4693 71 28.4693L68.4345 26.3061C68.4345 26.3338 56.6613 37.6355 51.8078 35.9021Z" fill="#FEBD55" />
    <path d="M20.149 11.5791C18.6791 11.5791 17.5004 12.7717 17.5004 14.2277C17.5004 15.6977 18.6929 16.8764 20.149 16.8764C21.6051 16.8764 22.7976 15.6838 22.7976 14.2277C22.7976 12.7578 21.6051 11.5791 20.149 11.5791Z" fill="#FEBD55" />
    <path d="M53.8601 56.6059C55.277 56.6059 56.4255 55.4573 56.4255 54.0404C56.4255 52.6236 55.277 51.475 53.8601 51.475C52.4433 51.475 51.2947 52.6236 51.2947 54.0404C51.2947 55.4573 52.4433 56.6059 53.8601 56.6059Z" fill="#2ECD99" />
    <path d="M0.0416062 36.1795C-0.0693313 36.3875 0.110942 36.6371 0.346684 36.6094L3.99375 36.027C4.09082 36.0131 4.1879 36.0408 4.24336 36.1102L6.79493 38.7727C6.96133 38.9529 7.25254 38.8559 7.29415 38.6201L7.8627 34.973C7.87657 34.876 7.93204 34.7928 8.02911 34.7512L11.3572 33.1564C11.5791 33.0455 11.5791 32.7404 11.3572 32.6434L8.05684 30.9793C7.97364 30.9377 7.91817 30.8545 7.9043 30.7574L7.40508 27.0965C7.37735 26.8607 7.08614 26.7637 6.90586 26.9301L4.29883 29.5371C4.2295 29.6064 4.13243 29.6342 4.03536 29.6203L0.41602 28.9547C0.180278 28.9131 4.65009e-06 29.1627 0.110942 29.3707L1.80274 32.6572C1.84434 32.7404 1.84434 32.8375 1.80274 32.9207L0.0416062 36.1795Z" fill="#FF6161" />
    <path d="M57.6459 2.59316C56.6474 7.03066 55.8293 7.83496 51.4056 8.8334C55.8431 9.83183 56.6474 10.65 57.6459 15.0736C58.6443 10.6361 59.4625 9.83183 63.8861 8.8334C59.4625 7.83496 58.6443 7.03066 57.6459 2.59316Z" fill="#0CA8E5" />
    <path d="M36.9699 53.042C36.0963 56.8971 35.3891 57.6043 31.5479 58.4641C35.4029 59.3377 36.1102 60.0449 36.9699 63.8861C37.8436 60.0311 38.5508 59.3238 42.392 58.4641C38.5508 57.6043 37.8436 56.8971 36.9699 53.042Z" fill="#26BAFF" />
    <path d="M39.8873 33.3169L44.1815 37.6111L39.8873 41.9053L35.5931 37.6111L39.8873 33.3169Z" fill="#F32222" />
  </svg>
);
