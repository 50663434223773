import { currencyFormatter } from 'shared/helpers/currency-formatter';

export const getDecimalPlaces = (selectedCurrency: string): number => {
  return selectedCurrency === 'USD' ? 2 : 6;
};

export const getTotalAssets = (totalBtc: number, totalUsdt: number, selectedCurrency: string): string => {
  const decimalPlaces = getDecimalPlaces(selectedCurrency);
  
  return currencyFormatter(
    selectedCurrency === 'USD' 
      ? totalUsdt 
      : totalBtc,
    decimalPlaces,
  );
};
  
export const getChange24h = (usdtDailyChangePercent: string, btcDailyChangePercent: string, selectedCurrency: string): string | null => {
  const decimalPlaces = getDecimalPlaces(selectedCurrency);

  const rawChange24h = selectedCurrency === 'USD' 
    ? usdtDailyChangePercent 
    : btcDailyChangePercent;

  const parsedChange = parseFloat(rawChange24h);

  if (parsedChange === 0) {
    return '0';
  }
  
  return isNaN(parsedChange) 
    ? null 
    : currencyFormatter(parsedChange, decimalPlaces);
};

export const getChangeIcon = (change24h: string | null): string => {
  if (change24h === null || parseFloat(change24h) === 0) {
    return '';
  }

  return parseFloat(change24h) < 0 
    ? '/images/arrow-down.svg' 
    : '/images/arrow-up.svg';
};

export const getChangeColor = (change24h: string | null): string => {
  if (!change24h || parseFloat(change24h) === 0) {
    return '#505053';
  }

  if (parseFloat(change24h) < 0) {
    return '#F32222';
  }

  return '#2ECD99';
};

export const getValueText = (totalAssets: string, selectedCurrency: string): string => {
  const currencyLabel = selectedCurrency === 'USD' 
    ? 'USD' 
    : 'BTC';
    
  return `${totalAssets} ${currencyLabel}`;
};

export const getChangeText = (change24h: string | null): string => {
  return change24h 
    ? `${change24h} %` 
    : '-';
};
