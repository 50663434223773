import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/model/actions/logout';
import { 
  CurrentSymbol, 
  TerminalSchema, 
  TradeDetails, 
  WalletData, 
} from '../types/terminal-schema';

const initialState: TerminalSchema = {
  currentSymbol: {},
  limitLastPrice: 0,
  chartLastPrice: 56522,
  baseOrderPrice: 56522,
  walletData: [],
  userWalletBaseAsset: [],
  userWalletTradingSymbol: {},
  activeTrades: [],
  exchangeSymbols: [],
  chosenExchange: {},
  dailyChange: {},
  binanceSymbols: [],
  okxSymbols: [],
  bybitSymbols: [],
};

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    setCurrentSymbol: (state, action: PayloadAction<CurrentSymbol>) => {
      state.currentSymbol = action.payload;
    },
    setChartLastPrice: (state, action: PayloadAction<number>)  => {
      state.chartLastPrice = action.payload;  
    },
    setLimitLastPrice: (state, action: PayloadAction<number>) => {
      state.limitLastPrice = action.payload;   
    },
    setWalletData: (state, action: PayloadAction<WalletData[]>) => {
      state.walletData = action.payload;
    },
    setUserWalletCurrentTradingSymbol: (state, action: PayloadAction<WalletData[]>) => {
      state.userWalletTradingSymbol = action.payload;
    },
    setUserWalletBaseAsset: (state, action: PayloadAction<WalletData>) => {
      state.userWalletBaseAsset = action.payload;
    },
    setBinanceSymbols: (state, action: PayloadAction<CurrentSymbol[]>) => {
      state.binanceSymbols = action.payload;
    },
    setOkxSymbols: (state, action: PayloadAction<CurrentSymbol[]>) => {
      state.okxSymbols = action.payload;
    },
    setBybitSymbols: (state, action: PayloadAction<CurrentSymbol[]>) => {
      state.bybitSymbols = action.payload;
    },
    setBaseOrderPrice: (state, action: PayloadAction<number>) => {
      state.baseOrderPrice = action.payload;
    },
    setAddNewTrade: (state, action: PayloadAction<TradeDetails>) => {
      state.activeTrades.push(action.payload);
    },
    setAllActiveTrades: (state, action: PayloadAction<TradeDetails[]>) => {
      state.activeTrades = action.payload;
    },
    setExchangeSymbols: (state, action: PayloadAction<any>) => {
      state.exchangeSymbols = action.payload;
    },
    setChosenExchange: (state, action: PayloadAction<any>) => {
      state.chosenExchange = action.payload;
    },
    setDailyChange: (state, action: PayloadAction<any>) => {
      state.dailyChange = action.payload;
    },
    closeTrade: (state, action: PayloadAction<{ smartTradeUuid: string; }>) => {
      state.activeTrades = state.activeTrades.map(trade => 
        trade.smartTradeUuid === action.payload.smartTradeUuid
          ? {
            ...trade, hideTrade: true, 
          }
          : trade,
      );
    },
    updateTrade: (state, action: PayloadAction<any>) => {
      state.activeTrades = state.activeTrades.map(trade => 
        trade.smartTradeUuid === action.payload.smartTradeUuid
          ? {
            ...trade, ...action.payload, 
          }
          : trade,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  actions: terminalActions,
} = terminalSlice;
export const {
  reducer: terminalReducer,
} = terminalSlice;
