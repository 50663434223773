import { CSSProperties } from 'react';

export const action: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
