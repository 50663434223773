import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: 5,
    md: 7,
  },
  padding: {
    xs: 1,
    sm: '63px 0',
  },
};
