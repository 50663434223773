import { SxProps, Theme } from '@mui/material';

export const topWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: {
    xs: '8px',
    sm: '16px',
    md: '32px',
  },
  '&>svg': {
    display: {
      xs: 'none',
      md: 'block',
    },
  },
};
