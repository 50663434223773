import { SxProps, Theme } from '@mui/material';

export const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  padding: '40px 14px 50px',
  borderRadius: '8px',
  border: '1px solid #E9E9E9',
  backgroundColor: '#fff',
};
