import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const deleteExchange = async (uuid: string) => {
  const response = await axios.request({
    url: '/exchangeAccount/remove',
    data: {
      exchangeAccountUuid: uuid,
    },
  });

  return response.data;
};
