export const Discord = (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4408_5061)'>
      <path d='M11.6841 2.69608C10.8227 2.30018 9.89893 2.00849 8.93305 1.84141C8.91547 1.83818 8.89789 1.84625 8.88883 1.86235C8.77002 2.07401 8.63842 2.3501 8.54627 2.56713C7.50743 2.41134 6.47389 2.41134 5.45635 2.56713C5.36417 2.34529 5.2278 2.07401 5.10845 1.86235C5.0994 1.84677 5.08182 1.83871 5.06424 1.84141C4.09891 2.00793 3.17514 2.29963 2.31314 2.69608C2.30567 2.69931 2.29928 2.70468 2.29503 2.71166C0.542835 5.33367 0.062813 7.89125 0.298283 10.4171C0.299339 10.4294 0.306282 10.4413 0.315864 10.4488C1.47191 11.2991 2.59177 11.8154 3.6908 12.1576C3.70838 12.1629 3.72702 12.1565 3.73821 12.142C3.99818 11.7864 4.22993 11.4114 4.42862 11.0171C4.44034 10.994 4.42915 10.9666 4.40518 10.9575C4.03759 10.8178 3.68758 10.6475 3.35087 10.4542C3.32424 10.4386 3.3221 10.4004 3.34662 10.3822C3.41747 10.329 3.48835 10.2737 3.55601 10.2178C3.56826 10.2076 3.58531 10.2054 3.5997 10.2119C5.81164 11.2234 8.20634 11.2234 10.3922 10.2119C10.4066 10.2049 10.4236 10.207 10.4364 10.2172C10.5041 10.2731 10.5749 10.329 10.6463 10.3821C10.6708 10.4004 10.6692 10.4386 10.6426 10.4541C10.3059 10.6513 9.95587 10.8178 9.58775 10.9569C9.56378 10.9661 9.55312 10.994 9.56486 11.0171C9.76784 11.4109 9.99959 11.7858 10.2547 12.1414C10.2654 12.1565 10.2846 12.1629 10.3022 12.1575C11.4065 11.8154 12.5264 11.2991 13.6824 10.4487C13.6926 10.4412 13.699 10.4299 13.7 10.4176C13.9818 7.49744 13.228 4.96083 11.7017 2.71216C11.698 2.70468 11.6916 2.69931 11.6841 2.69608ZM4.759 8.87911C4.09305 8.87911 3.54432 8.26673 3.54432 7.51465C3.54432 6.76256 4.08239 6.15019 4.759 6.15019C5.44088 6.15019 5.98431 6.76796 5.97365 7.51465C5.97365 8.26673 5.43555 8.87911 4.759 8.87911ZM9.25001 8.87911C8.5841 8.87911 8.03536 8.26673 8.03536 7.51465C8.03536 6.76256 8.57343 6.15019 9.25001 6.15019C9.93193 6.15019 10.4753 6.76796 10.4647 7.51465C10.4647 8.26673 9.93195 8.87911 9.25001 8.87911Z' fill='#9E9E9E' />
    </g>
    <defs>
      <clipPath id='clip0_4408_5061'>
        <rect width='13.5158' height='13.5377' fill='white' transform='translate(0.242188 0.231125)' />
      </clipPath>
    </defs>
  </svg>
);
