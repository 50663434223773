import { SxProps, Theme } from '@mui/material';

export const subTitle: SxProps<Theme> = {
  fontWeight: 500,
  textAlign: 'center',
  maxWidth: {
    xs: '162px',
    sm: '100%',
  },

  '&>span': {
    fontSize: {
      xs: 12,
      sm: 18,
    },
  },
};
