import { Box } from '@mui/material';
import { Dropdown } from 'antd';
import { UserMenuDropdown } from 'widgets/header/components';
import { IUserMenuProps } from 'widgets/header/interfaces';
import {
  Account,
  ArrowDown,
} from 'shared/icons';
import { Wrapper } from '../styles';

export const UserMenu = (props: IUserMenuProps) => {
  const {
    handleOpenChangePasswordModal,
  } = props;

  return (
    <Dropdown
      dropdownRender={() => (
        <UserMenuDropdown
          handleOpenChangePasswordModal={handleOpenChangePasswordModal}
        />
      )}
    >
      <Box sx={Wrapper}>
        {Account}
        {ArrowDown}
      </Box>
    </Dropdown>
  );
};
