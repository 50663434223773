import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: '38px',
  maxHeight: 500,
  overflowX: 'hidden',
  padding: {
    xs: '0 10px 20px 25px',
    md: '0 20px 40px 50px',
  },
  marginRight: {
    xs: '10px',
    md: '20px',
  },
};
