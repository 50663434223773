export const BankCardIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='97' height='55' viewBox='0 0 97 55' fill='none'>
    <rect x='0.851807' width='95.2966' height='54.7448' rx='4' fill='white' />
    <rect x='1.35181' y='0.5' width='94.2966' height='53.7448' rx='3.5' stroke='#3A3A3C' strokeOpacity='0.15' />
    <path fillRule='evenodd' clipRule='evenodd' d='M16.6844 37.1406H12.6075L9.55036 25.0554C9.40525 24.4995 9.09715 24.008 8.64394 23.7764C7.51292 23.1943 6.2666 22.731 4.90698 22.4973V22.0321H11.4745C12.3809 22.0321 13.0607 22.731 13.174 23.5427L14.7602 32.2602L18.8351 22.0321H22.7987L16.6844 37.1406ZM25.0646 37.1406H21.2144L24.3848 22.0321H28.2351L25.0646 37.1406ZM33.2164 26.2176C33.3297 25.4038 34.0095 24.9386 34.8026 24.9386C36.0489 24.8217 37.4066 25.0554 38.5396 25.6355L39.2194 22.3825C38.0864 21.9172 36.8401 21.6836 35.709 21.6836C31.9721 21.6836 29.2528 23.7764 29.2528 26.6808C29.2528 28.8904 31.179 30.0506 32.5386 30.7495C34.0095 31.4464 34.576 31.9117 34.4627 32.6086C34.4627 33.654 33.3297 34.1193 32.1987 34.1193C30.839 34.1193 29.4794 33.7708 28.2351 33.1887L27.5553 36.4437C28.9149 37.0238 30.3858 37.2574 31.7455 37.2574C35.9356 37.3722 38.5396 35.2815 38.5396 32.1434C38.5396 28.1915 33.2164 27.9599 33.2164 26.2176ZM52.0147 37.1406L48.9575 22.0321H45.6738C44.9939 22.0321 44.3141 22.4973 44.0875 23.1943L38.4264 37.1406H42.39L43.1811 34.933H48.0511L48.5043 37.1406H52.0147ZM46.2401 26.1007L47.3712 31.7949H44.2007L46.2401 26.1007Z' fill='#172B85' />
    <path fillRule='evenodd' clipRule='evenodd' d='M76.1427 36.0019C74.5607 37.3354 72.5086 38.1404 70.2662 38.1404C65.2625 38.1404 61.2063 34.1324 61.2063 29.1884C61.2063 24.2443 65.2625 20.2363 70.2662 20.2363C72.5086 20.2363 74.5607 21.0413 76.1428 22.3748C77.7248 21.0413 79.7769 20.2364 82.0194 20.2364C87.023 20.2364 91.0792 24.2443 91.0792 29.1884C91.0792 34.1325 87.023 38.1404 82.0194 38.1404C79.7769 38.1404 77.7248 37.3354 76.1427 36.0019Z' fill='#ED0006' />
    <path fillRule='evenodd' clipRule='evenodd' d='M76.1428 36.0019C78.0908 34.3599 79.326 31.9167 79.326 29.1884C79.326 26.4601 78.0908 24.0168 76.1428 22.3749C77.7249 21.0413 79.777 20.2363 82.0195 20.2363C87.0231 20.2363 91.0793 24.2443 91.0793 29.1884C91.0793 34.1324 87.0231 38.1404 82.0195 38.1404C79.777 38.1404 77.7249 37.3354 76.1428 36.0019Z' fill='#F9A000' />
    <path fillRule='evenodd' clipRule='evenodd' d='M76.1428 36.0018C78.0907 34.3598 79.3258 31.9166 79.3258 29.1884C79.3258 26.4602 78.0907 24.017 76.1428 22.375C74.1949 24.017 72.9597 26.4602 72.9597 29.1884C72.9597 31.9166 74.1949 34.3598 76.1428 36.0018Z' fill='#FF5E00' />
  </svg>
);
