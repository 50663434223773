import { Colors } from 'shared/consts/colors';

export const getSeries = (data: [string, number][], quoteAsset: string, isMainChart?: boolean): echarts.SeriesOption[] => {
  const primaryDynamicColor = (quoteAsset === 'BTC' && isMainChart) ? '#18A0FB' : Colors.GREEN;
  const gradientDynamicColor = (quoteAsset === 'BTC' && isMainChart) ? 'rgba(35, 165, 250, 0.00)' : 'rgba(46, 205, 153, 0.00)';
  
  const series: echarts.SeriesOption[] = [{
    type: 'line',
    smooth: true,
    lineStyle: {
      color: primaryDynamicColor,
      cap: 'round',
      join: 'round',
      shadowColor: primaryDynamicColor,
    },
    symbol: 'none',
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0,
          color: primaryDynamicColor,
        }, {
          offset: 1,
          color: gradientDynamicColor,
        }],
        global: false,
      },
    },
    data,
  }];

  return series;
};
