import { TableColumnsType } from 'antd';
import { getTextType } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-text-type';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { numberSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

export const columns: TableColumnsType = [{
  title: 'Token',
  dataIndex: 'symbol',
  key: 'symbol',
  sorter: (a, b) => stringSorter(a.symbol, b.symbol),
  width: 80,
  render: (token: any) => (
    <Text>{token}</Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Share',
  dataIndex: 'share',
  key: 'share',
  sorter: (a, b) => numberSorter(a.share, b.share),
  width: 80,
  render: (share: string) => (
    <Text>{Number(share).toFixed(2)}</Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Chg 24h',
  dataIndex: 'usdt',
  key: 'usdtChange',
  sorter: (a, b) => numberSorter(a?.usdt?.priceChangePercent, b?.usdt?.priceChangePercent),
  // sorter: (a, b) => a - b,
  width: 100,
  render: (value: any) => {
    const priceChangePercent = parseFloat(value.priceChangePercent);
    const textType = getTextType(priceChangePercent);
    return (
      <Text type={textType}>
        {priceChangePercent.toFixed(2)}
        %
      </Text>
    );
  },
  // render: (change: string) => (
  //   <Text>{change}</Text>
  // ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}, {
  title: 'Price',
  dataIndex: 'usdt',
  key: 'usdtPrice',
  width: 95,
  render: (value: any) => {
    const price = parseFloat(value.price);
    return (
      <Text>{currencyFormatter(price, 2)}</Text>
    );
  },
  showSorterTooltip: false,
}, {
  title: 'Amount',
  dataIndex: 'total',
  key: 'usdtAmount',
  width: 140,
  render: (value: any) => {
    const amount = parseFloat(value);
    return (
      <Text>{amount.toFixed(2)}</Text>
    );
  },
  showSorterTooltip: false,
}, {
  title: 'Total',
  dataIndex: 'usdt',
  key: 'total',
  sorter: (a, b) => numberSorter(a.usdt.total, b.usdt.total),
  width: 80,
  render: (value: any) => (
    // const fomattedTotal = currencyFormatter(total)
    
    <Text>{currencyFormatter(+value.total, 2)}</Text>
  ),
  sortIcon: (props) => (
    <SortIcon sortOrder={props.sortOrder} />
  ),
  showSorterTooltip: false,
}];
