import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const disableTwoFactorRequest = async (otp: string) => {
  const skyrexUserUuid = getSkyrexUuid();
    
  const response = await axios.request({
    url: 'user/disable2fa',
    data: {
      skyrexUserUuid,
      OTP: +otp,
    },
  });
  
  return response.data;
};
