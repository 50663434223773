import { Box } from '@mui/material';
import { BinanceLogo, BybitLogo, OkxLogo } from 'shared/icons';
import { MainButton } from 'shared/ui';
import { ITabs } from '../interfaces';
import { background, binanceLogo, bybitLogo, icon, item, okxLogo } from '../styles';

export const Tabs = (props: ITabs) => {
  const {
    onChoose,
  } = props;

  const onClickHandler = (platform: string) => () => {
    onChoose(platform);
  };

  return (
    <Box sx={background}>
      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('binance')}
      >
        <Box sx={icon}>
          <div style={binanceLogo}>
            {BinanceLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('okx')}
      >
        <Box sx={icon}>
          <div style={okxLogo}>
            {OkxLogo}
          </div>
        </Box>
      </MainButton>

      <MainButton
        styles={item}
        ghost={true}
        onClick={onClickHandler('bybit')}
      >
        <Box sx={icon}>
          <div style={bybitLogo}>
            {BybitLogo}
          </div>
        </Box>
      </MainButton>
    </Box>
  );
};
