import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ActiveDeal } from './fetch-all-deals';

export const calculateAndFormat = (exchangeSymbols: any[], symbol: string, executedQuote: number, executedBase: number): string => {
  const precision = exchangeSymbols.find((element) => element.symbol === symbol);
  const result = executedQuote / executedBase;
  if (isNaN(result) || executedBase === 0) {
    return '-';
  }
  return currencyFormatter(result, precision?.chartPrecision!);
};
  
  
export const getClosePrice = (trade: ActiveDeal, exchangeCode: string, binanceSymbols: CurrentSymbol[], bybitSymbols: CurrentSymbol[], okxSymbols: CurrentSymbol[]): string => {
  if (trade.status !== 'completed') {
    return '-';
  }
  
  const symbol = `${trade.baseSymbol}${trade.quoteSymbol}`;
  
  switch (exchangeCode) {
  case 'binance':
    return calculateAndFormat(binanceSymbols, symbol, +trade.executedQuoteSecond, +trade.executedBaseSecond);
  case 'bybit':
    return calculateAndFormat(bybitSymbols, symbol, +trade.executedQuoteSecond, +trade.executedBaseSecond);
  case 'okx':
    return calculateAndFormat(okxSymbols, symbol, +trade.executedQuoteSecond, +trade.executedBaseSecond);
  default:
    return '';
  }
};
