import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: {
    xs: 'center',
    sm: 'space-between',
  },
  width: '100%',
  maxWidth: '809px',
  gap: {
    xs: 0,
    md: '67px',
  },
};
