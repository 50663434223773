export const More = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_4623_1291)'>
      <path d='M8.00008 4.66764C8.73646 4.66764 9.33341 4.07069 9.33341 3.33431C9.33341 2.59793 8.73646 2.00098 8.00008 2.00098C7.2637 2.00098 6.66675 2.59793 6.66675 3.33431C6.66675 4.07069 7.2637 4.66764 8.00008 4.66764Z' fill='#2ECD99' />
      <path d='M8.00008 9.33431C8.73646 9.33431 9.33341 8.73736 9.33341 8.00098C9.33341 7.2646 8.73646 6.66764 8.00008 6.66764C7.2637 6.66764 6.66675 7.2646 6.66675 8.00098C6.66675 8.73736 7.2637 9.33431 8.00008 9.33431Z' fill='#2ECD99' />
      <path d='M8.00008 14.001C8.73646 14.001 9.33341 13.404 9.33341 12.6676C9.33341 11.9313 8.73646 11.3343 8.00008 11.3343C7.2637 11.3343 6.66675 11.9313 6.66675 12.6676C6.66675 13.404 7.2637 14.001 8.00008 14.001Z' fill='#2ECD99' />
    </g>
    <defs>
      <clipPath id='clip0_4623_1291'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
