import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  padding: {
    xs: '10px',
    md: '16px 14px',
  },
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  gap: {
    xs: '10px',
    md: '14px',
  },
  borderRadius: 4,
  background: '#F8FBF9',
};
