import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getSubscriptionDays = async (planName: string) => {
  const userId = getSkyrexUuid();

  const data = {
    skyrexUserUuid: userId,
    planName,
  };

  const response = await axios.request({
    url: 'subscription/subscriptionDaysCalculate',
    data,
  });

  return response.data;
};
