import { SxProps, Theme } from '@mui/material';

export const switchWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 2,

  '&>div:last-of-type': {
    cursor: 'pointer',
  },
};
