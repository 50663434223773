import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import echarts from '../../widgets/echart/echart-config';

const deepEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

interface EChartProps {
  data?: any;
  style?: React.CSSProperties;
  tooltip: any;
  calculable?: boolean;
  grid?: any;
  xAxis?: any;
  yAxis?: any;
  series?: any;
  graphic?: any;
  dates?: any[];
}

const EChart = ({
  style,
  tooltip,
  calculable,
  grid,
  xAxis,
  yAxis,
  series,
  graphic,
  data,
  dates,
}: EChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<any>(null);

  const options = useMemo(() => ({
    tooltip,
    calculable,
    grid,
    xAxis,
    yAxis,
    series,
    graphic,
  }), [tooltip, calculable, grid, xAxis, yAxis, series, graphic]);

  const updateChart = useCallback(() => {
    if (chartRef.current) {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }
      chartInstance.current.setOption(options);
    }
  }, [options]);

  const handleResize = useCallback(() => {
    if (chartInstance.current) {
      chartInstance.current.resize();
    }
  }, []);

  useEffect(() => {
    updateChart();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartInstance.current) {
        chartInstance.current.dispose();
        chartInstance.current = null;
      }
    };
  }, [options, data, dates, style, updateChart, handleResize]);

  return (
    <div ref={chartRef} style={style} />
  );
};

export default React.memo(EChart, (prevProps, nextProps) => {
  return (
    deepEqual(prevProps.style, nextProps.style) &&
    deepEqual(prevProps.data, nextProps.data) &&
    deepEqual(prevProps.dates, nextProps.dates) &&
    deepEqual(prevProps.tooltip, nextProps.tooltip) &&
    deepEqual(prevProps.grid, nextProps.grid) &&
    deepEqual(prevProps.xAxis, nextProps.xAxis) &&
    deepEqual(prevProps.yAxis, nextProps.yAxis) &&
    deepEqual(prevProps.series, nextProps.series) &&
    deepEqual(prevProps.graphic, nextProps.graphic)
  );
});
