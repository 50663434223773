import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: {
    xs: 'row',
    md: 'column',
  },
  justifyContent: {
    xs: 'center',
    md: 'initial',
  },
  alignItems: 'center',
  gap: {
    xs: '16px',
    md: '47px',
  },
};
