import { Box } from '@mui/material';
import { ExternalLink, Verify } from 'shared/icons';
import { Link, MainButton, Text, Title } from 'shared/ui';
import { Tabs } from '../components';
import { IChooseExchange } from '../interfaces';
import {
  button,
  inner,
  link,
  secondaryTitle,
  subTitle,
  text,
  textWrapper,
  title,
  verifyIcon,
  wrapper,
} from '../styles';

export const ChooseExchange = (props: IChooseExchange) => {
  const {
    onChoose,
  } = props;

  const handleCreateBybitAccount = () => {
    window.open('https://partner.bybit.com/b/skyrexio', '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        Connect an exchange
      </Title>

      <Box sx={inner}>
        <Box sx={subTitle}>
          <Text type='success'>
            Select one of exchanges to connect to Skyrex
          </Text>
        </Box>

        <Tabs onChoose={onChoose} />

        <Box
          display='flex'
          flexDirection='column'
          gap={1}
          maxWidth={676}
          textAlign='center'
        >
          <Box sx={textWrapper}>
            <Text
              type='secondary'
              styles={text}
            >
              Connect exchange securely with encrypted OAuth or API keys
              <span style={verifyIcon}>
                {Verify}
              </span>
            </Text>
          </Box>

          <Box sx={textWrapper}>
            <Text
              type='secondary'
              styles={text}
            >
              Skyrexio is an official non-custodial partner of supported exchanges with IP and access whitelisting. We are not able to transfer or withdraw assets, only you maintain exclusive control
            </Text>
          </Box>
        </Box>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        gap={4}
      >
        <Title
          level={4}
          styles={secondaryTitle}
        >
          Don't have an exchange?
        </Title>

        <Box
          display='flex'
          flexDirection='column'
          textAlign='center'
          gap={2}
        >
          <MainButton
            type='primary'
            ghost
            styles={button}
            onClick={handleCreateBybitAccount}
          >
            Create Bybit account
          </MainButton>

          <Link
            type='success'
            styles={link}
            href='mailto: info@skyrexio.com'
          >
            Suggest an exchange you'd like to use
            {' '}
            {ExternalLink}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
