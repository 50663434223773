import { Fragment } from 'react/jsx-runtime';
import { Helmet } from 'react-helmet';
import { IPageWrapper } from '../interfaces';

export const PageWrapper = (props: IPageWrapper) => {
  const {
    title,
    desciption,
    children,
  } = props;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>

        <meta
          name='description'
          content={desciption || ''}
        />
      </Helmet>

      {children}
    </Fragment>
  );
};
