import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { Title } from 'shared/ui';
import { IMenuItem, IMenuItemChildren } from '../../../interfaces';
import { menuItemChildrenTitle, menuItemTitle } from '../styles';

interface IRenderMenuItems {
  menuItems: IMenuItem[];
  closePopupHandler?: () => void;
  inviteFriends: () => void;
}

export const renderMenuItems = (params: IRenderMenuItems) => {
  const {
    menuItems,
    closePopupHandler,
    inviteFriends,
  } = params;

  const resultMenuItems = menuItems.map((menuItem: IMenuItem) => {
    if (menuItem.children) {
      return (
        <Box
          key={menuItem.key}
          display='flex'
          flexDirection='column'
          gap={1}
        >
          <Title styles={menuItemTitle}>
            {menuItem.label}
          </Title>

          <Box
            display='flex'
            flexDirection='column'
            gap={1}
          >
            {menuItem.children.map((children: IMenuItemChildren) => (
              <Link
                key={`${menuItem.key}-${children.label}`}
                to={children.link}
                onClick={closePopupHandler}
              >
                <Title styles={menuItemChildrenTitle}>
                  {children.label}
                </Title>
              </Link>
            ))}
          </Box>
        </Box>
      );
    }

    if (menuItem.key === 'invite-friends') {
      return (
        <Title
          key={menuItem.key}
          styles={menuItemTitle}
          onClick={inviteFriends}
        >
          {menuItem.label}
        </Title>
      );
    }

    return (
      <Link
        key={menuItem.key}
        to={menuItem.link}
        onClick={closePopupHandler}
      >
        <Title styles={menuItemTitle}>
          {menuItem.label}
        </Title>
      </Link>
    );
  });

  return resultMenuItems;
};
