import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  padding: 0.5,
  backgroundColor: '#F6F6F6',
  borderRadius: 2,
  width: '100%',
  minWidth: 111,
  maxWidth: {
    xs: '100%',
    sm: 'max-content',
  },
  flex: 1,
};
