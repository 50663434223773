import { CSSProperties } from 'react';

export const input: CSSProperties = {
  backgroundColor: '#fff',
  padding: '8px 12px',
  fontSize: 13,
  fontWeight: 400,
  borderRadius: 8,
  border: '1px solid #F2F2F2',
  width: '100%',
  flex: 1,
  display: 'flex',
  gap: 8,
};
