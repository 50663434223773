import { CallbackDataParams } from 'echarts/types/dist/shared';
import { Colors } from 'shared/consts/colors';

export const getSeries = (data: number[]): echarts.SeriesOption[] => {
  const series: echarts.SeriesOption[] = [{
    type: 'bar',
    itemStyle: {
      borderRadius: [26, 26, 0, 0],
      color: (params: CallbackDataParams) => {
        const isNegativeValue = (params?.value as number) < 0;

        return {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: isNegativeValue ? Colors.RED : Colors.GREEN,
          }, {
            offset: 1,
            color: isNegativeValue ? 'rgba(255, 77, 79, 0.00)' : 'rgba(46, 205, 153, 0.00)',
          }],
          global: false,
        };
      },
    },
    barWidth: 6,
    data,
  }];

  return series;
};
