import { SxProps, Theme } from '@mui/material';

export const image: SxProps<Theme> = {
  display: {
    xs: 'none',
    sm: 'flex',
  },
  flex: 1,
  width: '100%',
  maxWidth: 463,
  '&>img': {
    width: '100%',
    height: '100%',
  },
};
