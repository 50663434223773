import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  padding: {
    xs: '24px 10px',
    sm: '35px 52px',
  },
  backgroundColor: '#F8FBF9',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '44px',
  flex: 1,
  width: '100%',
  maxWidth: '100%',
};
