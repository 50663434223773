import { fetchBinanceExchangeInfo, fetchBybitExchangeInfo, fetchOkxExchangeInfo } from '../api/get-exchange-symbols';

export async function getExchangeSymbols(exchangeName: string) {
  switch (exchangeName.toLowerCase()) {
  case 'binance':
    return await fetchBinanceExchangeInfo();
  case 'bybit':
    return await fetchBybitExchangeInfo();
  case 'okx':
    return await fetchOkxExchangeInfo();
  default:
    throw new Error(`Exchange ${exchangeName} is not supported.`);
  }
}
