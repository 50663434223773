import { CSSProperties } from 'react';

export const menuItemChildrenTitle: CSSProperties = {
  fontSize: 13,
  fontWeight: 400,
  lineHeight: 'normal',
  cursor: 'pointer',
  color: '#fff',
  padding: 12,
  maxWidth: 'max-content',
};
