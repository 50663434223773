import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: '10px',
    sm: '20px',
  },
};
