import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const button: CSSProperties = {
  height: 'auto',
  fontSize: getFontSize(14, 18),
  fontWeight: 400,
  lineHeight: 'normal',
  padding: '12px',
  maxWidth: 235,
  alignSelf: 'center',
};
