import { Box } from '@mui/material';
import { Input } from 'antd';
import { ChangeEvent } from 'react';
import { Text } from 'shared/ui';
import { ILabelInputProps } from '../interfaces';
import { input, labelText } from '../styles';

export const LabelInput = (props: ILabelInputProps) => {
  const {
    label,
    value,
    onChange,
    placeholder,
    type,
    autoComplete,
    suffix,
    prefix,
    mask,
    status,
    disabled,
    icon,
  } = props;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={1}
      flex={1}
    >
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}
      {mask
        ? (
          <Input.Password
            disabled={disabled}
            type={type}
            size='large'
            value={value}
            autoComplete={autoComplete || 'new-password'}
            placeholder={placeholder}
            onChange={onChangeHandler}
            style={input}
            suffix={icon || suffix}
            status={status}
            prefix={prefix}
          />
        ) : (
          <Input
            disabled={disabled}
            type={type}
            size='large'
            value={value}
            autoComplete={autoComplete || 'new-password'}
            placeholder={placeholder}
            onChange={onChangeHandler}
            style={input}
            suffix={icon || suffix}
            status={status}
            prefix={prefix}
          />
        )
      }
    </Box>
  );
};
