import { Delete, SecondaryEdit } from 'shared/icons';
import { Title } from 'shared/ui';
import { background, item } from '../styles';

interface IMoreButtonContent {
  handleDelete: () => void;
  handleUpdate: () => void;
}

export const MoreButtonContent = ({
  handleDelete,
  handleUpdate,
}: IMoreButtonContent) => (
  <div style={background}>
    <div style={item}>
      {SecondaryEdit}
      <Title
        onClick={handleUpdate}
        level={5}
      >
        Update API Key
      </Title>
    </div>

    <div style={item}>
      {Delete}
      <Title
        onClick={handleDelete}
        level={5}
      >
        Delete
      </Title>
    </div>
  </div>
);
