import { SxProps, Theme } from '@mui/material';

export const platformLogo: SxProps<Theme> = {
  width: {
    xs: 156,
    md: 208,
  },
  height: {
    xs: 31,
    md: 42,
  },
};
