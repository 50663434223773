import { SxProps, Theme } from '@mui/material';

export const textWrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: {
    xs: '12px',
    md: '47px',
  },
  flex: {
    xs: 'none',
    md: 1,
  },
  width: {
    xs: 'auto',
    md: '100%',
  },
  alignItems: 'center',
  textAlign: {
    xs: 'left !important',
    md: 'center',
  },
};
