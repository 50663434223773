import { Sort } from 'shared/icons';
import { ISortIcon } from '../interfaces';

export const SortIcon = (props: ISortIcon) => {
  const {
    sortOrder,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        transform: `rotateZ(${sortOrder === 'ascend' ? 180 : 0}deg)`,
      }}
    >
      {Sort}
    </div>
  );
};
