import { Box } from '@mui/material';
import { Input } from 'antd';
import { ChangeEvent } from 'react';
import { Search as SearchIcon } from 'shared/icons';
import { ISearchInputProps } from '../interfaces';
import { input, wrapper } from '../styles';

export const Search = (props: ISearchInputProps) => {
  const {
    value,
    onChange,
    placeholder,
    suffix,
    maxWidth,
  } = props;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box sx={wrapper(maxWidth)}>
      <Input
        size='small'
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        style={input}
        suffix={suffix}
        prefix={SearchIcon}
      />
    </Box>
  );
};
