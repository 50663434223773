import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  flex: 1,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 'normal',
  padding: '12px 24px',
};
