import { Box } from '@mui/material';
import { MainButton, Text, Title } from 'shared/ui';
import { IWelcome } from '../interfaces';
import {
  bottomWrapper,
  button,
  description,
  image,
  inner,
  skipButton,
  title,
  topWrapper,
  wrapper,
} from '../styles';

export const Welcome = (props: IWelcome) => {
  const {
    changeStep,
    skipHandler,
  } = props;

  return (
    <Box
      display='flex'
      justifyContent='center'
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Box sx={topWrapper}>
            <Title styles={title}>
              Welcome
            </Title>

            <Text styles={description}>
              Here is where your trading journey starts. Explore features with our onboarding
            </Text>
          </Box>

          <Box sx={bottomWrapper}>
            <MainButton
              type='primary'
              styles={button}
              onClick={changeStep}
            >
              Start onboarding
            </MainButton>

            <Text
              type='success'
              styles={skipButton}
              onClick={skipHandler}
            >
              Skip onboarding
            </Text>
          </Box>
        </Box>

        <Box sx={image}>
          <img src='/images/subscriptions/w-bg.jpg' alt='bg' loading='lazy' />
        </Box>
      </Box>
    </Box>
  );
};
