import dayjs from 'dayjs';
import { exchangeAssetsDateFormat } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-exchange-assets-date-format';
import { getSnapshotsByDate } from '../api/get-snapshots-by-date';
import { AggregatedSnapshot, ChartSnapshot } from '../types/snapshot.types';

export async function getSingleExchangeSnapshotsByDates(skyrexUserUuid: string, exchangeAccountUuid: string, dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null): Promise<AggregatedSnapshot[]> {
  const singleExchangeSnapshotsRequestData = {
    skyrexUserUuid,
    exchangeAccountUuids: [exchangeAccountUuid],
    dates,
  };

  const allAccountsSnapshots = await getSnapshotsByDate(singleExchangeSnapshotsRequestData);
  
  const snapshotsMap: Map<string, AggregatedSnapshot> = new Map();

  allAccountsSnapshots.data.accounts.forEach((snapshot: ChartSnapshot) => {
    const createdDate = exchangeAssetsDateFormat(snapshot.createdDate);
    const btcValue = parseFloat(snapshot.totalBtc);
    const usdtValue = parseFloat(snapshot.totalUsdt);

    if (!snapshotsMap.has(createdDate)) {
      snapshotsMap.set(createdDate, {
        createdDate,
        totalBtc: 0,
        totalUsdt: 0,
      });
    }

    const aggregatedSnapshot = snapshotsMap.get(createdDate);
    if (aggregatedSnapshot) {
      aggregatedSnapshot.totalBtc += btcValue;
      aggregatedSnapshot.totalUsdt += usdtValue;
    }
  });

  const snapshotsArray: AggregatedSnapshot[] = Array.from(snapshotsMap.values()).map(snapshot => ({
    createdDate: snapshot.createdDate,
    totalBtc: parseFloat(snapshot.totalBtc.toFixed(8)),
    totalUsdt: parseFloat(snapshot.totalUsdt.toFixed(8)),
  }));

  return snapshotsArray;
}
