import { SxProps, Theme } from '@mui/material';

export const inner = (isAbsolute: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height:'100vh',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 10,
  };

  if (isAbsolute) {
    styles.position = 'absolute';
    styles.height = '100%';
  }

  return styles;
};
