import { SxProps, Theme } from '@mui/material';

export const okxLogo: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  width: {
    xs: 93,
    md: 140,
  },
  height: {
    xs: 31,
    md: 38,
  },
};
