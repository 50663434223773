export const getTextType = (priceChangePercent: number): 'danger' | 'success' | undefined => {
  if (isNaN(priceChangePercent)) {
    return;
  }
  
  if (priceChangePercent === 0) {
    return;
  }
  
  return priceChangePercent < 0 ? 'danger' : 'success';
};
