import { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#D2D2D2',
  borderRadius: 4,
  height: 8,
  overflow: 'hidden',
};
