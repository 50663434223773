import { CSSProperties } from 'react';

export const closeIcon: CSSProperties = {
  fontSize: 20,
  color: '#fff',
  cursor: 'pointer',
  position: 'absolute',
  right: 30,
  top: 23,
};
