import { CSSProperties } from 'react';

export const button: CSSProperties = {
  height: 'auto',
  alignSelf: 'center',
  padding: '16px 0',
  flex: 1,
  width: '100%',
  maxWidth: 130,
};
