import dayjs from 'dayjs';
import { exchangeAssetsDateFormat } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-exchange-assets-date-format';
import { AggregatedSnapshot } from '../types/snapshot.types';

interface SnapshotsModificatorProps {
  snapshots: AggregatedSnapshot[]; 
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null; 
  totalBtc: number; 
  totalUsdt: number;
}

export const snapshotsModificator = (props: SnapshotsModificatorProps) => {
  const {
    snapshots, 
    dates, 
    totalBtc, 
    totalUsdt, 
  } = props;  
  const todayDate = exchangeAssetsDateFormat(new Date().toISOString());
  const endDateIsToday = dates && dates[1]?.isSame(dayjs(), 'day');
  const endDayIsAfterToday = dates && dates[1]?.isAfter(dayjs(), 'day');
  
  if (snapshots.length && (!dates || endDateIsToday || endDayIsAfterToday)) {
    const overallSnapshotsWithoutLast = snapshots?.slice(0, -1) || [];

    const newSnapshot = {
      createdDate: todayDate,
      totalBtc,
      totalUsdt,
    };

    overallSnapshotsWithoutLast.push(newSnapshot);
    
    return overallSnapshotsWithoutLast;
  }
  
  return snapshots;
};
