export const openTradeCodeItem = {
  'base': '{{syminfo.basecurrency}}',
  'quote': '{{syminfo.currency}}',
  'position': 'entry1',
  'price': '{{close}}',
  'sourceUuid': 'f9ca23de-e411-4aff-ab1d-4rd04dfa112f',
  'secretToken': 'eyJhbGciOiJIUzI1NiIsInfewCI6IkpXVCJ9.eyJpYXQiOjE3MDE5MzI3MDMsImV4cCI6dqwfvjAxOTEwM30.CvCaWGE4dzrpIOTdRH537YzAEONTs86kbw_NajHnhbA',
};

export const additinalEntryCodeItem = {
  'base': '{{syminfo.basecurrency}}',
  'quote': '{{syminfo.currency}}',
  'position': 'entry2',
  'price': '{{close}}',
  'sourceUuid': 'f9ca23de-e411-4aff-ab1d-4rd04dfa112f',
  'secretToken': 'eyJhbGciOiJIUzI1NiIsInfewCI6IkpXVCJ9.eyJpYXQiOjE3MDE5MzI3MDMsImV4cCI6dqwfvjAxOTEwM30.CvCaWGE4dzrpIOTdRH537YzAEONTs86kbw_NajHnhbA',
};

export const closeTradeCodeItem = {
  'base': '{{syminfo.basecurrency}}',
  'quote': '{{syminfo.currency}}',
  'position': 'close',
  'price': '{{close}}',
  'sourceUuid': 'f9ca23de-e411-4aff-ab1d-4rd04dfa112f',
  'secretToken': 'eyJhbGciOiJIUzI1NiIsInfewCI6IkpXVCJ9.eyJpYXQiOjE3MDE5MzI3MDMsImV4cCI6dqwfvjAxOTEwM30.CvCaWGE4dzrpIOTdRH537YzAEONTs86kbw_NajHnhbA',
};
