// @ts-nocheck

import { ExchangeSymbolInfo, getPrecisionNumber, sanitizeSymbol } from './common-utils';

export function adaptBinanceSymbol(symbol) {
  const priceFilter = symbol.filters.find(
    (el) => el.filterType === 'PRICE_FILTER',
  );
  const lotFilter = symbol.filters.find((el) => el.filterType === 'LOT_SIZE');
  const notionalFilter = symbol.filters.find(
    (el) => el.filterType === 'NOTIONAL',
  );
  
  const marketLotFilter = symbol.filters?.find(
    (el) => el.filterType === 'MARKET_LOT_SIZE',
  );
  
  return {
    symbol: symbol.symbol,
    baseAsset: symbol.baseAsset,
    baseAssetPrecision: symbol.baseAssetPrecision,
    quoteAsset: symbol.quoteAsset,
    quoteAssetPrecision: symbol.quoteAssetPrecision,
    priceTickSize: priceFilter ? priceFilter.tickSize : undefined,
    priceMin: priceFilter ? priceFilter.minPrice : undefined,
    priceMax: priceFilter ? priceFilter.maxPrice : undefined,
    minNotional: notionalFilter ? notionalFilter.minNotional : undefined,
    maxNotional: notionalFilter ? notionalFilter.maxNotional : undefined,
    lotMax: lotFilter ? lotFilter.maxQty : undefined,
    lotMin: lotFilter ? lotFilter.minQty : undefined,
    lotTickSize: lotFilter ? lotFilter.stepSize : undefined,
    marketLotMax: marketLotFilter ? marketLotFilter.maxQty : 99999999,
    marketLotMin: marketLotFilter ? marketLotFilter.minQty : 99999999,
    chartPrecision: getPrecisionNumber(priceFilter.tickSize),
    baseCurrencyChartPrecision: getPrecisionNumber(lotFilter.stepSize),
  };
}
  
export function adaptBybitSymbol(symbol) {
  // Destructure the necessary fields from the Bybit response
  const {
    symbol: symbolName,
    baseCoin,
    quoteCoin,
    lotSizeFilter,
    priceFilter,
  } = symbol;
  
  // Extract values from the lotSizeFilter and priceFilter objects
  const {
    basePrecision,
    quotePrecision,
    minOrderQty,
    maxOrderQty,
    minOrderAmt,
    maxOrderAmt,
  } = lotSizeFilter;
  
  const {
    tickSize, 
  } = priceFilter;
  
  // Adapt the Bybit response to the common data model
  return {
    symbol: symbolName,
    baseAsset: baseCoin,
    baseAssetPrecision: getPrecisionNumber(basePrecision),
    quoteAsset: quoteCoin,
    quoteAssetPrecision: getPrecisionNumber(quotePrecision),
    priceTickSize: tickSize,
    priceMin: undefined,
    priceMax: undefined,
    minNotional: minOrderAmt,
    maxNotional: maxOrderAmt,
    lotMax: maxOrderQty,
    lotMin: minOrderQty,
    lotTickSize: basePrecision,
    chartPrecision: getPrecisionNumber(tickSize),
    baseCurrencyChartPrecision: getPrecisionNumber(basePrecision),
  };
}
  
export function adaptOkxSymbol(symbol) {
  // Extract the necessary fields from the OKX response
  const {
    instId,
    baseCcy,
    quoteCcy,
    lotSz,
    tickSz,
    minSz,
    maxLmtSz, // Assuming this is equivalent to maxOrderQty in your model
    maxLmtAmt, // Assuming this is equivalent to maxNotional in your model
  } = symbol;
  
  // Adapt the OKX response to the common data model
  return {
    symbol: sanitizeSymbol(instId),
    baseAsset: baseCcy,
    baseAssetPrecision: getPrecisionNumber(lotSz), // OKX response does not provide a direct equivalent
    quoteAsset: quoteCcy,
    quoteAssetPrecision: getPrecisionNumber(tickSz), // OKX response does not provide a direct equivalent
    priceTickSize: tickSz,
    priceMin: undefined, // These values are not directly available in the OKX response
    priceMax: undefined, // These values are not directly available in the OKX response
    minNotional: undefined,
    maxNotional: undefined,
    lotMax: maxLmtSz,
    lotMin: minSz,
    lotTickSize: lotSz,
    okxInstId: instId,
  };
}
