import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useState } from 'react';
import { LabelInput, MainButton, Title } from 'shared/ui';
import { disableTwoFactorRequest } from '../../two-factor/api/disable-two-factor';
import { button, buttons, modal, modalChildren, title, wrapper } from '../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSetTwoFactorDisabled: () => void;
  handleSetTwoFactorDisableFailedOpen: () => void;
  handleSetTwoFactorResetModalOpen: () => void;
}

export const TwoFactorDisable = ({
  isOpen,
  onClose,
  handleSetTwoFactorDisabled,
  handleSetTwoFactorDisableFailedOpen,
  handleSetTwoFactorResetModalOpen,
}: Props) => {
  const [otp, setOtp] = useState<string>('');
  
  const handleSetOtp = (value: string) => {
    setOtp(value);    
  };
  
  const handleReset = () => {
    handleSetTwoFactorResetModalOpen();
  };

  const handleDisableTwoFactor = async () => {
    try {
      const responseFromDisableTwoFactor = await disableTwoFactorRequest(otp);
      
      if (!responseFromDisableTwoFactor.success) {
        if (responseFromDisableTwoFactor.data.message === '2FA is already disabled') {
          onClose();
          handleSetTwoFactorDisabled();
          setOtp('');
          return;
        }
        
        onClose();
        setOtp('');
        handleSetTwoFactorDisableFailedOpen();
        return;
      }
      
      onClose();
      handleSetTwoFactorDisabled();
      setOtp('');
    } catch (error) {
      setOtp('');
      handleSetTwoFactorDisableFailedOpen();
    }
  };
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={onClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          Disable 2FA
        </Title>

        <LabelInput
          label='Enter OTP  to confirm'
          value={otp}
          placeholder=''
          onChange={handleSetOtp}
        />

        <Box sx={buttons}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
            onClick={handleReset}
          >
            Reset 2FA
          </MainButton>
          
          <MainButton
            type='primary'
            styles={button}
            onClick={handleDisableTwoFactor}
          >
            Okay
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
