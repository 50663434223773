import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

interface GetExchangeTradingStatisticProps {
  exchangeAccountUuids: string[];
  sideFirstStep: string;
  dateCloseFrom: string | null;
  dateCloseTo: string | null;
}

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getStatByDates = async ({
  exchangeAccountUuids,
  sideFirstStep,
  dateCloseFrom,
  dateCloseTo,
}: GetExchangeTradingStatisticProps) => {
  try {
    const response = await axios.request({
      url: 'exchangeAccount/accountsTradingStatistic',
      data: {
        exchangeAccountUuids,
        sideFirstStep,
        dateCloseFrom,
        dateCloseTo,
      },
    });

    return response.data.data.calculations;
  } catch (error) {
    console.error('Error fetching trading statistics:', error);
    return [];
  }
};
