import { Box } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { items } from '../consts';
import {
  item,
  itemIcon,
  itemList,
  itemListValue,
  itemTitle,
  list,
  title,
  wrapper,
} from '../styles';

export const StartTrading = () => {
  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        Start trading
      </Title>

      <Box sx={list}>
        {items.map((currentItem: any) => (
          <Box
            key={currentItem.title}
            sx={item}
          >
            <Box sx={itemIcon}>
              <img src={currentItem.icon} alt='i' />
            </Box>

            <Title styles={itemTitle}>
              {currentItem.title}
            </Title>

            <Box sx={itemList}>
              {currentItem.list.map((value: string) => (
                <Box
                  key={value}
                  sx={itemListValue}
                >
                  <Text>
                    {value}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
