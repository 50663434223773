import { Modal } from 'antd';
import { useState } from 'react';
import { ChooseExchange, Exchanges } from 'widgets';
import { IExchanges } from 'widgets/exchanges/interfaces';
import { modal, modalChildren } from '../styles';

interface ConnectNewExchangeProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export const ConnectNewExchange = ({
  isModalOpen, setIsModalOpen,
}: ConnectNewExchangeProps) => {
  const [platform, setPlatform] = useState<string>('');

  const onChooseExchange = (platform: string) => {
    setPlatform(platform);
  };

  const returnToAllExchanges = () => {
    setPlatform('');
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
    setPlatform('');
  };

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      style={modal}
      closable
      destroyOnClose
      onCancel={handleCancel}
      styles={modalChildren}
      width='100%'
      centered={true}
    >
      {!platform && (
        <ChooseExchange onChoose={onChooseExchange} />
      )}

      {platform && (
        <Exchanges
          handleClose={handleCancel}
          returnToAllExchanges={returnToAllExchanges}
          platform={platform as IExchanges['platform']}
          isModalOpen={isModalOpen}
        />
      )}
    </Modal>
  );
};
