import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetSnapshotsByDateParams {
  skyrexUserUuid: string;
  exchangeAccountUuids: string[];
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
}

export const getSnapshotsByDate = async (props: GetSnapshotsByDateParams) => {
  const {
    skyrexUserUuid,
    exchangeAccountUuids,
    dates, 
  } = props;
  
  const response = await axios.request({
    url: '/exchangeAccount/getByFilter',
    data: {
      table: 'exchange_account_snapshots',
      skyrexUserUuids: [skyrexUserUuid],
      exchangeAccountUuids: exchangeAccountUuids,
      createdDateFrom: dates && dates[0],
      createdDateTo: dates && dates[1],
    },
  });
    
  return response.data;
};
