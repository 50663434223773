import { CSSProperties } from 'react';

export const background: CSSProperties = {
  padding: 17,
  borderRadius: 8,
  border: '1px solid #B8B8B8',
  background: '#FFF',
  boxShadow: '0px 12px 31.8px 0px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
};
