import { Box } from '@mui/material';
import { RangePicker, Search, TooltipSingleSelect } from 'shared/ui';
import { tooltip } from '../../../styles';
import { inner, wrapper } from '../styles';

export const ChartAction = () => (
  <Box sx={wrapper}>
    <Box sx={inner}>
      <TooltipSingleSelect
        tooltip={(
          <div style={tooltip}>
            Select the statistic you wish to view: manual trades, Bot deals, or a comprehensive summary
          </div>
        )}
        options={[{
          label: 'All',
          value: 'all',
        }, {
          label: 'Manual',
          value: 'manual',
        }, {
          label: 'Bots',
          value: 'bots',
        }]}
        select={{
          value: 'all',
          placeholder: 'Source',
          onChange: () => {},
        }}
      />

      <TooltipSingleSelect
        tooltip={(
          <div style={tooltip}>
            Select the market statistic you wish to view: All Markets of specific asset, like USDT
          </div>
        )}
        options={[{
          label: 'USDT',
          value: 'USDT',
        }, {
          label: 'BTC',
          value: 'BTC',
        }, {
          label: 'ETH',
          value: 'ETH',
        }, {
          label: 'BNB',
          value: 'BNB',
        }]}
        select={{
          value: 'USDT',
          placeholder: 'Market',
          onChange: () => {},
        }}
        dropdown={(menu) => (
          <Box
            display='flex'
            flexDirection='column'
            gap={0.5}
          >
            <Search
              value=''
              maxWidth={450}
              placeholder='Search'
              onChange={() => {}}
            />

            {menu}
          </Box>
        )}
      />
    </Box>

    <RangePicker
      value={[null, null]}
    />
  </Box>
);
