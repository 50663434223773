import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getSubscriptionsByBotUuid = async (botUuid: string) => {
  const skyrexUserUuid = getSkyrexUuid();
  
  const response = await axios.request({
    url: 'subscription/getByFilter',
    data: {
      table: 'bot_success_subscriptions',
      botUuids: [botUuid],
      skyrexUserUuids: [skyrexUserUuid],
    },
  });

  return response.data;
};
