import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotDealsUuidsParams {
  botUuid: string;
}

export interface Deal {
  dealUuid: string;
  botUuid: string;
  skyrexUserUuid: string;
  exchangeAccountUuid: string;
  smartTradeUuid: string;
  sourceUuid: string;
  copyCommissionPercent: number;
  status: string;
  botType: string;
  base: string;
  quote: string;
  position: number;
  tradingAmount: number;
  maxActiveDeals: number;
  martingaleVolumeCoefficient: number;
  maxSafetyOrders: number;
  priceDeviationPercentSafety: number;
  martingalePriceStepCoefficient: number;
  baseOrderVolume: number;
  strategy: string;
  baseOrderType: string;
  baseOrderConditionalTriggerType: string;
  additionalBaseOrderType: string;
  additionalBaseConditionalTriggerType: string;
  takeProfitPriceRecalculation: string;
  takeProfitType: string;
  takeProfitConditionalTriggerType: string;
  takeProfitPricePercent: number;
  takeProfitPriceStepCoefficient: number;
  takeProfitTargetsQuantity: number;
  takeProfitVolumeStepCoefficient: number;
  stopLossPriceRecalculation: string;
  stopLossType: string;
  stopLossConditionalTriggerType: string;
  stopLossPercent: number;
  alertAdditionalBaseOrderType: string;
  alertCloseType: string;
  openPrice: number;
  averagingPrices: any[];
  closePrice: number;
  closedDate: string | null;
  createdDate: string;
  lastChangeDate: string;
}

export const getBotDealsUuids = async (params: GetBotDealsUuidsParams) => {
  const {
    botUuid, 
  } = params;
  const skyrexUserUuid = getSkyrexUuid();

  const response = await axios.request({
    url: '/bot/getByFilterDeal',
    data: {
      skyrexUserUuids: [skyrexUserUuid],
      botUuids: [botUuid],
    },
  });

  return response.data.data.deals as Deal[];
};
