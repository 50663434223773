import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Title } from 'shared/ui';
import { IBotAction } from '../interfaces';
import { buttonStyles, buttonWrapper, modal, modalChildren, titleStyles, wrapper } from '../styles';

export const BotAction = (props: IBotAction) => {
  const {
    isOpened,
    title,
    children,
    buttons,
    handleCancel,
  } = props;

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleCancel}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={titleStyles}>
          {title}
        </Title>

        {children}

        <Box sx={buttonWrapper}>
          {buttons.map((button: IBotAction['buttons'][0]) => (
            <MainButton
              key={button.title}
              onClick={button.action}
              styles={buttonStyles}
            >
              {button.title}
            </MainButton>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
