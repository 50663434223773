export const getXAxis = (data: any): echarts.XAXisComponentOption => {
  const xAxis: echarts.XAXisComponentOption = {
    type: 'category',
    data,
    axisLine: {
      lineStyle: {
        color: '#9E9E9E',
        cap: 'round',
        join: 'round',
      },
      show: false,
    },
    axisTick: {
      show: false,
    },
    splitLine: {
      show: false,
    },
  };

  return xAxis;
};
