import { Box } from '@mui/material';
import { getCalculatedWinRate } from 'pages/trading-bots/marketplace/helpers/get-calculated-winrate';
import { getRuntimePeriod } from 'pages/trading-bots/marketplace/helpers/get-runtime-period';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import React, { useState } from 'react';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { Account } from 'shared/icons';
import { CopyBot, MainButton, Text, Title } from 'shared/ui';
import { LineChart } from '../components/line-chart/ui/line-chart';
import {
  background,
  bottomInner,
  bottomWrapper,
  button,
  middleInner,
  middleItem,
  middleItems,
  middleItemTitle,
  middleItemValue,
  middleValue,
  middleValueTitle,
  middleValueWrapper,
  middleWrapper,
  tip,
  tips,
  title as titleStyle,
  titleInner,
  titleWrapper,
  topWrapper,
} from '../styles';

interface CardProps {
  bot: Bot;
  isSignalSource?: boolean;
}

export const Card: React.FC<CardProps> = ({ 
  bot, 
  isSignalSource,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box sx={background}>
      <Box sx={topWrapper}>
        <Box sx={titleWrapper}>
          <Title styles={titleStyle}>{bot.name}</Title>

          <Box sx={titleInner}>
            {Account}
            <Text type='success'>{bot.activeCopyBots}</Text>
          </Box>
        </Box>

        <Box sx={tips}>
          <Box sx={tip}>{bot.quote}</Box>
          <Box sx={tip}>{getCapitalizedExchangeTitle(bot.exchangeCode)}</Box>
        </Box>
      </Box>

      <Box sx={middleWrapper}>
        <Box sx={middleInner}>
          <Box sx={middleValueWrapper}>
            <Text type='secondary' styles={middleValueTitle}>
              ROI
            </Text>
            <Text type='success' styles={middleValue}>
              {bot?.statistics.roi ? `${bot?.statistics.roi.toFixed(2)}%` : '0%'}
            </Text>
          </Box>

          <LineChart data={bot?.statistics.sumChartData || []} totalPnl={bot.statistics.totalPnl} roi={bot.statistics.roi} />
        </Box>

        <Box sx={middleItems}>
          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>Win rate</Text>
            <Text type='secondary' styles={middleItemValue}>
              {(getCalculatedWinRate(bot.statistics?.totalTrades || 0, bot.statistics?.positiveTrades || 0))}
            </Text>
          </Box>

          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>Max drawdown</Text>
            <Text
              styles={middleItemValue}
              type='danger'
            >
              {bot?.statistics?.maxDrawdown?.toFixed(2)}%
            </Text>
          </Box>

          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>Runtime</Text>
            <Text type='secondary' styles={middleItemValue}>
              {getRuntimePeriod(bot.createdDate)}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box sx={bottomWrapper}>
        <Box sx={bottomInner}>
          <Text styles={middleItemTitle}>Min investment</Text>
          <Text styles={middleItemValue} type='secondary'>
            {`$${bot.minInvestment || 0}`}
          </Text>
        </Box>

        <Box sx={bottomInner}>
          <Text styles={middleItemTitle}>Fee</Text>
          <Text styles={middleItemValue} type='secondary'>
            {`${bot.copyCommissionPercent || 0}%`}
          </Text>
        </Box>

        <MainButton
          type='primary'
          styles={button}
          onClick={handleOpenModal}
          size='large'
        >
          {isSignalSource ? 'View bot' : 'Copy bot'}
        </MainButton>
      </Box>

      {isModalOpen && (
        <CopyBot
          bot={bot}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          isSignalSource={isSignalSource}
        />
      )}
    </Box>
  );
};
