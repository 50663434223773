import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const linkWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  justifyContent: 'center',
  '&>span': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  '&>span, &>span>a': {
    fontSize: getFontSize(12, 16),
    fontWeight: 500,
  },
  '&>div': {
    cursor: 'pointer',
    display: 'flex',
  },
};
