import React from 'react';
import { Navigate } from 'react-router-dom';
import { getRefreshToken } from 'shared/helpers/storage-helper';
import { RouteGuardProps } from '../model/types/route-guard';

export const ProtectedRoute: React.FC<RouteGuardProps> = ({
  children,
}) => {
  const isAuthenticated = getRefreshToken();

  if (!isAuthenticated) {
    return <Navigate to='/login' replace />;
  }

  return (
    <>
      {children}
    </>
  );
};
