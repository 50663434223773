import { SxProps, Theme } from '@mui/material';

export const wrapper = (maxWidth?: number) => {
  const styles: SxProps<Theme> = {
    width: '100%',
    flex: 1,
    maxWidth: maxWidth || 'max-content',
  };

  return styles;
};
