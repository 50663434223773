import { SxProps, Theme } from '@mui/material';

export const content: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: {
    xs: '20px',
    md: '34px',
  },
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};
