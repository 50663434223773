export function currencyFormatter(num: number, decimalPlaces: number): string {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  let formattedNumber = formatter.format(num);

  if (decimalPlaces > 0) {
    formattedNumber = formattedNumber.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
  }

  formattedNumber = formattedNumber.replace(/,/g, ' ');

  return formattedNumber;
}
