import { Box } from '@mui/material';
import { BaseType } from 'antd/es/typography/Base';
import { getBinanceSymbols } from 'entities/terminal/model/selectors/get-binance-symbols.ts/get-binance-symbols';
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { formatterByQuote } from 'pages/home/helpers/formatter-by-quote';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaChart, BarChart, ChartWrapper } from 'widgets';
import { SecondaryInfo, Update } from 'shared/icons';
import { Loader, Text, Title, Tooltip } from 'shared/ui';
import { ChartAction } from '../components';
import { ITradingStatistic } from '../interfaces';
import {
  item,
  itemInner,
  left,
  leftInner,
  result,
  text,
  titleWrapper,
  tooltip,
  wrapper,
} from '../styles';

export const TradingStatistic = (props: ITradingStatistic) => {
  const {
    loading,
    listItems,
    chartData,
    chartAction,
    handleUpdateStatistic,
    selectedSegment,
    setSelectedSegment,
    disableTitle,
    selectedQuote,
    isCopyBot,
    dates,
  } = props;

  const dispatch = useDispatch();

  const binanceSymbols = useSelector(getBinanceSymbols);
  const fetchExchangeSymbols = async () => {
    try {
      const binanceSymbols = await getExchangeSymbols('binance');
      dispatch(terminalActions.setBinanceSymbols(binanceSymbols));
    } catch (error) {
      dispatch(terminalActions.setBinanceSymbols([]));
    }
  };

  useEffect(() => {
    fetchExchangeSymbols();
  }, []);

  const getChart = (selectedSegment: string) => {
    if (selectedSegment === 'summary') {
      return (
        <AreaChart data={chartData} dates={dates} loading={loading} quoteAsset={selectedQuote} isCopyBot={isCopyBot} />
      );
    }

    return (
      <BarChart data={chartData} quoteAsset={selectedQuote} isCopyBot={isCopyBot} />
    );
  };

  const renderListItem = (listItem: ITradingStatistic['listItems'][0]) => {
    let type: BaseType = 'secondary';
    let value = `${listItem.value}`;

    const isFundsValue = listItem.type === 'funds';
    const isPercentageValue = listItem.type === 'roi' || listItem.type === 'max-drawdown';
    const isCalculatedItem = listItem.type === 'total' || listItem.type === 'unrealised' || listItem.type === 'today';
  
    const symbol = listItem.currency ?? '%';
  
    const isZero = +formatterByQuote(+value, listItem.currency!, binanceSymbols) === 0;
  
    if (isCalculatedItem) {
      if (isZero) {
        type = 'secondary';
        value = `0 ${symbol}`;
      } else if (+listItem.value > 0) {
        type = 'success';
        value = `+${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      } else if (+listItem.value < 0) {
        type = 'danger';
        value = `${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      } else {
        type = 'secondary';
        value = `${value} ${symbol}`;
      }
    }

    if (isFundsValue) {
      if (isZero) {
        type = 'secondary';
        value = `0 ${symbol}`;
      } else {
        value = `${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      }
    }

    if (isPercentageValue) {
      if (isZero) {
        type = 'secondary';
        value = `0 ${symbol}`;
      } else if (+listItem.value > 0) {
        type = 'success';
        value = `+${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      } else if (+listItem.value < 0) {
        type = 'danger';
        value = `${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      } else {
        type = 'secondary';
        value = `${formatterByQuote(+value, listItem.currency!, binanceSymbols)} ${symbol}`;
      }
    }

    return (
      <Box
        key={listItem.title}
        sx={item}
      >
        <Box sx={itemInner}>
          <Tooltip
            title={listItem.tooltip}
            styles={tooltip}
          >
            {SecondaryInfo}
          </Tooltip>

          <Text
            styles={text}
            type='secondary'
          >
            {listItem.title}
          </Text>
        </Box>

        <Text
          styles={result}
          type={type}
        >
          {value}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={wrapper}>
      {loading && (
        <Loader isContentOverflow={true} />
      )}

      <Box sx={left}>
        {!disableTitle && (
          <Box sx={titleWrapper}>
            <Title level={5}>Trading statistic</Title>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={handleUpdateStatistic}
            >
              {Update}
            </div>
          </Box>
        )}

        <Box sx={leftInner}>
          {listItems.map((listItem: ITradingStatistic['listItems'][0]) => renderListItem(listItem))}
        </Box>
      </Box>

      <ChartWrapper
        segments={
          [{
            label: 'Sum',
            value: 'summary',
          }, {
            label: 'Day',
            value: 'day',
          }, {
            label: 'Pair',
            value: 'pair',
          }]
        }
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        defaultSegment='summary'
        isEmpty={!chartData?.length}
        action={chartAction || (
          <ChartAction />
        )}
      >
        {(selectedSegment: string) => getChart(selectedSegment)}
      </ChartWrapper>
    </Box>
  );
};
