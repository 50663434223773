export const FaceBook = (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4408_5056)'>
      <path d='M6.87225 11.6413V6.49565H8.59593L8.85453 4.48971H6.87225V3.20921C6.87225 2.62863 7.03255 2.23297 7.8647 2.23297L8.9243 2.23253V0.438353C8.74106 0.414502 8.11205 0.359818 7.37995 0.359818C5.8512 0.359818 4.80459 1.29447 4.80459 3.01055V4.48971H3.07568V6.49565H4.80459V11.6413H6.87225Z' fill='#9E9E9E' />
    </g>
    <defs>
      <clipPath id='clip0_4408_5056'>
        <rect width='11.2632' height='11.2815' fill='white' transform='translate(0.368408 0.359268)' />
      </clipPath>
    </defs>
  </svg>
);
