export const Unlimited = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
    <g clipPath="url(#clip0_158_3462)">
      <path d="M9.5784 3.06504C8.08003 3.41239 6.70188 4.15431 5.5869 5.21383C4.47191 6.27335 3.66071 7.61189 3.23742 9.09061C2.81413 10.5693 2.79417 12.1344 3.17962 13.6234C3.56507 15.1124 4.34188 16.4712 5.42949 17.5588C6.5171 18.6464 7.87588 19.4232 9.36491 19.8087C10.8539 20.1941 12.419 20.1742 13.8977 19.7509C15.3764 19.3276 16.7149 18.5164 17.7745 17.4014C18.834 16.2864 19.5759 14.9083 19.9233 13.4099C19.9233 13.1559 19.8223 12.9122 19.6427 12.7326C19.4631 12.553 19.2194 12.452 18.9654 12.452H15.3255C15.1514 13.1216 14.8035 13.7332 14.317 14.2251C13.8305 14.717 13.2227 15.0716 12.5552 15.2532C11.8876 15.4347 11.1839 15.4366 10.5153 15.2588C9.84674 15.081 9.23701 14.7297 8.74782 14.2405C8.25863 13.7513 7.90734 13.1416 7.72952 12.473C7.55169 11.8044 7.55363 11.1007 7.73514 10.4331C7.91665 9.76556 8.27129 9.15777 8.76317 8.67129C9.25505 8.1848 9.86672 7.83689 10.5363 7.66275V3.83132C10.5244 3.71784 10.49 3.60785 10.4353 3.50773C10.3806 3.40761 10.3065 3.31935 10.2174 3.24807C10.1283 3.17679 10.026 3.1239 9.91626 3.09248C9.80657 3.06107 9.69173 3.05174 9.5784 3.06504Z" stroke="#2ECD99" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.3676 3.35241C15.5838 3.78065 16.6884 4.47626 17.6002 5.38801C18.5119 6.29975 19.2075 7.40441 19.6358 8.62062H15.3254C15.0317 8.27671 14.7115 7.95643 14.3676 7.66277V3.35241Z" stroke="#2ECD99" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_158_3462">
        <rect width="22.9886" height="22.9886" fill="white" transform="translate(-0.000244141 -8.96454e-05)" />
      </clipPath>
    </defs>
  </svg>
);
