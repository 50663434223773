import { useState } from 'react';

export const useSearch = (filterField: string | string[], defaultValue?: string) => {
  const [search, setSearch] = useState<string>(defaultValue || '');

  const changeSearchValue = (value: string) => {
    setSearch(value);
  };

  const compareValues = (value: string) => {
    return String(value).toLowerCase().includes(search.toLowerCase());
  };

  const filterItemsBySearch = (item: any) => {
    if (Array.isArray(filterField)) {
      const values: string[] = [];

      filterField.forEach((key: string) => {
        values.push(item[key]);
      });

      const value = values.join(' ');
      return compareValues(value);
    }

    const value = item[filterField];
    return compareValues(value);
  };

  return {
    search,
    changeSearchValue,
    filterItemsBySearch,
  };
};
