export const xAxis: echarts.XAXisComponentOption = {
  type: 'category',
  axisLine: {
    lineStyle: {
      color: '#9E9E9E',
      cap: 'round',
      join: 'round',
    },
    show: false,
  },
  axisTick: {
    show: false,
  },
  splitLine: {
    show: false,
  },
};
