import { Box } from '@mui/material';
import { Modal } from 'antd';
import { FailedIcon } from 'shared/icons';
import { MainButton, Text, Title } from 'shared/ui';
import { ITwoFactorFailed } from '../interfaces';
import {
  button,
  icon,
  inner,
  modal,
  modalChildren,
  wrapper,
  title as titleStyles,
  text,
} from '../styles';

export const TwoFactorFailed = (props: ITwoFactorFailed) => {
  const {
    isOpen,
    title,
    description,
    closeHandler,
  } = props;

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Box sx={icon}>
            {FailedIcon}
          </Box>
          
          <Title styles={titleStyles}>
            {title}
          </Title>

          <Text styles={text}>
            {description}
          </Text>
        </Box>

        <MainButton
          type='primary'
          ghost={true}
          styles={button}
          onClick={closeHandler}
        >
          Okay
        </MainButton>
      </Box>
    </Modal>
  );
};
