export const Logo = (
  <svg xmlns="http://www.w3.org/2000/svg" width="124" height="39" viewBox="0 0 124 39" fill="none">
    <path d="M7.80457 32.3997L6.44292 33.9599L2.59362 39.0045H0.000110626L5.08126 32.3997L6.41673 30.7875L10.0565 26.0029H12.6489L7.80457 32.3997Z" fill="#2ECD99" />
    <path d="M27.9325 6.39675L26.5708 7.95693L22.7215 13.0015H20.128L25.2092 6.39675L26.5447 4.78456L30.1845 0H32.7769L27.9325 6.39675Z" fill="#2ECD99" />
    <path d="M10.0448 26.0026H12.6637L16.5135 20.958L19.4522 24.8783H22.0711L17.8753 19.3978L22.7203 13.0011H20.1276L16.5397 17.7857L12.343 12.3149H9.75024L15.1517 19.3978L10.0448 26.0026Z" fill="#2ECD99" />
    <path d="M7.10083 24.8847C10.147 24.8847 12.0316 23.086 12.0316 20.5101C12.0316 18.2895 10.4757 16.9794 8.37188 16.0467L6.94743 15.425C5.30384 14.6922 4.6464 13.915 4.6464 12.7603C4.6464 11.4501 5.80788 10.6951 7.12275 10.6951C7.88491 10.6951 8.48847 10.8628 9.01466 11.1825H11.6129C10.8785 9.81206 9.0074 8.76318 7.18849 8.76318C4.88746 8.76318 2.65218 10.3176 2.65218 12.7158C2.65218 14.6922 3.68216 16.1578 6.04893 17.1792L7.47338 17.801C9.13889 18.5338 10.0155 19.2888 10.0155 20.7322C10.0155 22.1089 8.87591 22.9528 7.07892 22.9528C6.0269 22.9528 5.09157 22.5336 4.36886 21.9494H1.79956C2.74651 23.8859 4.84363 24.8847 7.10083 24.8847Z" fill="#2ECD99" />
    <path d="M42.7945 26.3109C45.7471 26.3109 47.5891 24.6165 47.5891 22.4649C47.5891 20.4746 46.3701 19.2643 44.0677 18.4305L42.9571 18.0271C41.386 17.4623 40.9797 16.8168 40.9797 15.9831C40.9797 15.0417 41.8736 14.5038 42.9029 14.5038C43.9593 14.5038 44.7178 15.0148 45.1512 15.9024L46.9661 14.8535C46.1263 13.2128 44.6365 12.4329 42.9029 12.4329C40.4379 12.4329 38.6772 13.9928 38.6772 16.01C38.6772 17.6237 39.5169 19.1029 41.9006 19.9905L42.9571 20.3939C44.5552 20.9587 45.2595 21.5235 45.2595 22.5455C45.2595 23.5676 44.176 24.2399 42.8487 24.2399C41.4131 24.2399 40.5463 23.5676 39.842 22.3842L38 23.6214C39.0835 25.4233 40.79 26.3109 42.7945 26.3109Z" fill="#2ECD99" />
    <path d="M52.4898 26.0957H54.8194V19.3719L61.3475 26.0957H64.4897L57.5823 18.9685L63.8667 12.648H60.8058L54.8194 18.7264V7H52.4898V26.0957Z" fill="#2ECD99" />
    <path d="M69.1141 31.0445H71.5791L79.8951 12.648H77.3759L73.1231 22.438L68.8974 12.648H66.3783L71.9042 24.9661L69.1141 31.0445Z" fill="#2ECD99" />
    <path d="M83.8725 26.0957H86.2021V16.9513C86.798 15.5527 88.1253 14.8266 89.588 14.8266C90.0756 14.8266 90.509 14.8804 90.8882 14.9879V12.5942C90.5903 12.5405 90.2652 12.5136 89.9402 12.5136C88.4233 12.5136 87.1231 13.0784 86.2021 14.2349V12.648H83.8725V26.0957Z" fill="#2ECD99" />
    <path d="M100.883 26.3109C103.239 26.3109 105.162 25.3427 106.327 24.1324L104.837 22.5186C103.971 23.3793 102.589 24.1324 100.883 24.1324C98.5801 24.1324 96.5756 22.5186 96.3318 19.9367H107.329C107.329 15.5258 104.783 12.4329 100.72 12.4329C96.9278 12.4329 93.921 15.472 93.921 19.3719C93.921 23.2448 96.8736 26.3109 100.883 26.3109ZM100.585 14.5576C102.643 14.5307 104.377 15.7141 104.702 17.9195H96.4673C97.009 15.5527 98.9052 14.5576 100.585 14.5576Z" fill="#2ECD99" />
    <path d="M109.923 26.0957H112.632L116.614 20.878L120.596 26.0957H123.305L118.023 19.2643L123.034 12.648H120.352L116.641 17.5968L112.876 12.648H110.194L115.205 19.2643L109.923 26.0957Z" fill="#2ECD99" />
  </svg>
);
