export const LogoMobile = (
  <svg xmlns='http://www.w3.org/2000/svg' width='100' height='29' viewBox='0 0 100 29' fill='none'>
    <path d='M4.55182 17.2338C6.50446 17.2338 7.71257 16.0808 7.71257 14.4296C7.71257 13.0061 6.71517 12.1663 5.36659 11.5684L4.45348 11.1698C3.3999 10.7001 2.97846 10.2019 2.97846 9.4617C2.97846 8.62186 3.723 8.13788 4.56586 8.13788C5.05443 8.13788 5.44133 8.24538 5.77863 8.45033H7.44416C6.97342 7.57183 5.77397 6.89948 4.60801 6.89948C3.13299 6.89948 1.70012 7.8959 1.70012 9.43323C1.70012 10.7001 2.36036 11.6396 3.87752 12.2944L4.79063 12.6929C5.85826 13.1627 6.42017 13.6467 6.42017 14.5719C6.42017 15.4545 5.68969 15.9954 4.53777 15.9954C3.8634 15.9954 3.26383 15.7267 2.80055 15.3522H1.15356C1.76058 16.5935 3.10489 17.2338 4.55182 17.2338Z' fill='white' />
    <path d='M6.439 17.9504H8.11779L10.5856 14.7167L12.4694 17.2297H14.1482L11.4586 13.7166L14.5643 9.61608H12.9023L10.6024 12.6831L7.91218 9.17624H6.25018L9.71264 13.7166L6.439 17.9504Z' fill='white' />
    <path d='M17.9054 5.38254L17.0326 6.38266L14.5651 9.61637H12.9026L16.1597 5.38254L17.0158 4.34908L19.349 1.28206H21.0108L17.9054 5.38254Z' fill='white' />
    <path d='M5.00292 22.0511L4.13007 23.0512L1.66257 26.2849H6.10352e-05L3.25721 22.0511L4.11328 21.0176L6.44649 17.9506H8.10828L5.00292 22.0511Z' fill='white' />
    <path d='M31.7242 19.2119C33.6169 19.2119 34.7977 18.1257 34.7977 16.7465C34.7977 15.4707 34.0163 14.6948 32.5403 14.1604L31.8284 13.9018C30.8213 13.5397 30.5609 13.1259 30.5609 12.5915C30.5609 11.9881 31.1339 11.6433 31.7937 11.6433C32.4709 11.6433 32.9571 11.9708 33.2349 12.5398L34.3983 11.8674C33.86 10.8157 32.905 10.3157 31.7937 10.3157C30.2136 10.3157 29.0849 11.3157 29.0849 12.6087C29.0849 13.6432 29.6232 14.5914 31.1512 15.1603L31.8284 15.419C32.8529 15.781 33.3044 16.1431 33.3044 16.7982C33.3044 17.4533 32.6098 17.8844 31.759 17.8844C30.8387 17.8844 30.283 17.4533 29.8316 16.6948L28.6508 17.4878C29.3454 18.643 30.4393 19.2119 31.7242 19.2119Z' fill='white' />
    <path d='M37.9392 19.074H39.4325V14.7638L43.6172 19.074H45.6314L41.2036 14.5052L45.232 10.4536H43.2699L39.4325 14.35V6.83311H37.9392V19.074Z' fill='white' />
    <path d='M48.5958 22.2462H50.1759L55.5066 10.4536H53.8918L51.1656 16.7292L48.4569 10.4536H46.842L50.3843 18.3499L48.5958 22.2462Z' fill='white' />
    <path d='M58.0563 19.074H59.5496V13.2122C59.9316 12.3156 60.7824 11.8501 61.7201 11.8501C62.0326 11.8501 62.3104 11.8846 62.5535 11.9536V10.4192C62.3625 10.3847 62.1542 10.3674 61.9458 10.3674C60.9734 10.3674 60.14 10.7295 59.5496 11.4708V10.4536H58.0563V19.074Z' fill='white' />
    <path d='M68.9602 19.2119C70.4708 19.2119 71.7037 18.5912 72.4503 17.8154L71.4953 16.781C70.9397 17.3327 70.0541 17.8154 68.9602 17.8154C67.4842 17.8154 66.1993 16.781 66.043 15.1259H73.0928C73.0928 12.2984 71.4606 10.3157 68.856 10.3157C66.425 10.3157 64.4976 12.2639 64.4976 14.7638C64.4976 17.2465 66.3903 19.2119 68.9602 19.2119ZM68.7692 11.6777C70.0888 11.6605 71.2001 12.4191 71.4085 13.8328H66.1298C66.4771 12.3156 67.6926 11.6777 68.7692 11.6777Z' fill='white' />
    <path d='M74.7555 19.074H76.4919L79.0444 15.7293L81.5969 19.074H83.3333L79.9473 14.6948L83.1597 10.4536H81.4407L79.0618 13.6259L76.6482 10.4536H74.9292L78.1415 14.6948L74.7555 19.074Z' fill='white' />
  </svg>
);
