import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  padding: {
    xs: '20px 0',
    md: '50px 0',
  },
};
