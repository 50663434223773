import { Tooltip as RootTooltip } from 'antd';
import { ITooltip } from '../interfaces';
import { overlayStyle } from '../styles';

export const Tooltip = (props: ITooltip) => {
  const {
    title,
    styles,
    children,
  } = props;

  return (
    <RootTooltip
      title={title}
      style={styles}
      overlayStyle={overlayStyle}
    >
      {children}
    </RootTooltip>
  );
};
