import { Box } from '@mui/material';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { enableTwoFactor } from '../api/enable-two-factor';
import { button, buttons, inner, modal, modalChildren, text, title, wrapper } from '../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  generatedToken: string;
  qrImageSource: string;
  handleSetTwoFactorEnabled: () => void;
  handleSetTwoFactorDisabled: () => void;
  handleSetTwoFactorEnableFailedOpen: () => void;
}

export const TwoFactor = ({
  isOpen,
  onClose,
  generatedToken,
  qrImageSource,
  handleSetTwoFactorEnabled,
  handleSetTwoFactorEnableFailedOpen,
}: Props) => {
  const [otp, setOtp] = useState<string>('');
  
  const handleSetOtp = (value: string) => {
    setOtp(value);    
  };
  
  const handleEnableTwoFactor = async () => {
    try {
      const responseFromEnableTwoFactor = await enableTwoFactor(otp);
      
      if (!responseFromEnableTwoFactor.success) {
        setOtp('');
        handleSetTwoFactorEnableFailedOpen();
        return;
      }
      
      onClose();
      setOtp('');
      handleSetTwoFactorEnabled();
    } catch (error) {
      handleSetTwoFactorEnableFailedOpen();
      setOtp('');
    }
  };
  
  const handleClose = () => {
    setOtp('');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={onClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          Connect Google authenticator
        </Title>

        <Box sx={inner}>
          <Text styles={text}>
            Don’t forget to back up your secret key: <br />
            {generatedToken}
          </Text>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <QRCode value={qrImageSource} size={200} />
          </div>
        </Box>

        <LabelInput
          label='Enter OTP  to confirm connection'
          value={otp}
          placeholder='123456'
          onChange={handleSetOtp}
        />

        <Box sx={buttons}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
            onClick={handleClose}
          >
            Cancel
          </MainButton>
          
          <MainButton
            type='primary'
            styles={button}
            onClick={handleEnableTwoFactor}
          >
            Okay
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
