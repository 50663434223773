import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { NoData } from 'shared/icons';
import { Loader, Text } from 'shared/ui';
import { IChartWrapper } from '../interfaces';
import { inner, wrapper } from '../styles';

export const ChartWrapper = (props: IChartWrapper) => {
  const {
    loading,
    action,
    segments,
    children,
    isEmpty,
    selectedSegment,
    setSelectedSegment,
  } = props;
  
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };
  
  const getContent = () => {
    if (isEmpty) {
      return (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flex={1}
        >
          <Box
            display='flex'
            flexDirection='column'
            gap={1}
          >
            {NoData}

            <Text type='secondary'>No Data</Text>
          </Box>
        </Box>
      );
    }

    return children(selectedSegment);
  };

  return (
    <Box sx={wrapper}>
      {loading && (
        <Loader isContentOverflow={true} />
      )}
      <Box sx={inner}>
        <div>
          <Segmented
            value={selectedSegment}
            defaultValue={segments[0].value}
            options={segments}
            onChange={changeSegmentHandler}
            size='middle'
            block
          />
        </div>

        {action}
      </Box>

      {getContent()}
    </Box>
  );
};
