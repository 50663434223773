import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  width: '100%',
  minWidth: {
    xs: 240,
    sm: 320,
  },
  border: '1px solid #727373',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)',
  borderRadius: 4,
  padding: '11px 14px',
  zIndex: 100,
  backgroundColor: '#fff',
};
