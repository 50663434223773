import { CSSProperties } from 'react';

export const cancelButton: CSSProperties = {
  fontSize: 12,
  fontWeight: 400,
  height: 'auto',
  width: '100%',
  padding: 0,
  maxWidth: 'max-content',
  alignSelf: 'center',
  textDecoration: 'underline',
};
