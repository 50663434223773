export const Play = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_6240_1758)'>
      <path fillRule='evenodd' clipRule='evenodd' d='M13.7025 5.88192L4.57338 0.324998C3.04499 -0.604762 1.14307 0.571553 1.14307 2.4433V13.5566C1.14307 15.4308 3.04499 16.6046 4.57338 15.6749L13.7025 10.1208C15.2423 9.18368 15.2423 6.81899 13.7025 5.88192Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_6240_1758'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
