import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ActiveDeal } from './fetch-all-deals';

export const getReturnPercent = (trade: ActiveDeal) => {
  const percent = trade.returnPercent;
  
  if (percent === null || percent === undefined) {
    return '-';
  }
  
  return `${currencyFormatter(percent, 1)}`;
};
