import { SxProps, Theme } from '@mui/material';

export const getWrapperStyles = (isModalView: boolean): SxProps<Theme> => {
  const styles: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: {
      xs: '40px 0',
      md: isModalView ? '0' : '70px 0',
    },
  };

  return styles;
};
