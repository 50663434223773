import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { ChooseExchange } from 'widgets';
import { getPlatform } from 'widgets/exchanges/helpers';
import { wrapper } from '../styles';

export const ConnectExchangeApiKeysPage = () => {
  const [platform, setPlatform] = useState<string>('');

  const handleChoose = (platform: string) => {
    setPlatform(platform);
  };

  const returnToAllExchanges = () => {
    setPlatform('');
  };
  
  useEffect(() => () => {
    setPlatform('');
  }, []);

  if (!platform) {
    return (
      <Box sx={wrapper}>
        <ChooseExchange onChoose={handleChoose} />
      </Box>
    );
  }

  return getPlatform({
    platform,
    isModalOpen: false,
    returnToAllExchanges,
  });
};
