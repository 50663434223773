import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { CreateOauthProps } from '../types/oauth.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const connectOauth = async (props: CreateOauthProps) => {
  const {
    skyrexUserUuid,
    userAccessToken,
    accountName,
    exchangeCode,
  } = props;

  const response = await axios.request({
    url: '/exchangeAccount/createOauth',
    data: {
      skyrexUserUuid,
      userAccessToken,
      accountName,
      exchangeCode,
    },
  });

  return response.data;
};

