import { AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

export function applyRetryConfiguration(axiosInstance: AxiosInstance): void {
  const retryConfig: IAxiosRetryConfig = {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
  };
  axiosRetry(axiosInstance, retryConfig);
}
