import { SxProps, Theme } from '@mui/material';

export const list: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(auto-fit, minmax(200px, 1fr))',
  },
  gap: '5px',
};
