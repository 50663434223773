import { SxProps, Theme } from '@mui/material';

export const itemListValue: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  '&>span': {
    fontSize: 11,
    fontWeight: 400,
  },
  '&:before': {
    content: '""',
    width: 9,
    height: 9,
    display: 'block',
    borderRadius: '50%',
    backgroundColor: '#2ECD99',
  },
};
