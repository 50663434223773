import { Colors } from 'shared/consts/colors';
import { hexToRgb, interpolateColor, rgbToHex } from '.';

export const generateColors = (numColors: number, startColor: string, endColor: string) => {
  if (numColors === 0) {
    return ['#D2D2D2'];
  }

  if (numColors === 1) {
    return [Colors.GREEN];
  }

  const startRgb = hexToRgb(startColor);
  const endRgb = hexToRgb(endColor);

  const colors = [];
  const stepFactor = numColors > 1 ? 1 / (numColors - 1) : 1;

  for (let i = 0; i < numColors; i++) {
    const factor = i * stepFactor;

    const interpolatedColor = interpolateColor(startRgb, endRgb, factor);
    colors.push(rgbToHex(interpolatedColor));
  }

  return colors;
};
