import { LoadingOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { Spin } from 'antd';
import { inner, wrapper } from '../styles';

export const Loader = (props: any) => {
  const {
    isContentOverflow,
  } = props;

  return (
    <Box sx={wrapper(isContentOverflow)}>
      <Box sx={inner(isContentOverflow)}>
        <Spin
          indicator={(
            <LoadingOutlined
              style={{
                fontSize: 48,
              }}
              spin
            />
          )}
        />
      </Box>
    </Box>
  );
};
