import { SxProps, Theme } from '@mui/material';

export const topWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'initial',
    lg: 'flex-start',
  },
  gap: '24px',
  flexDirection: {
    xs: 'column',
    lg: 'row',
  },
  backgroundColor: '#F8FBF9',
  flex: 1,
  padding: {
    xs: '40px 25px',
    md: '40px 50px',
  },
};
