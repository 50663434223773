import { Box } from '@mui/material';
import { Space, Switch } from 'antd';
import { logout } from 'features/auth/model/actions/logout';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { socials, userMenuLinks } from 'widgets/header/consts';
import { ISocial, IUserMenuLink, IUserMenuProps } from 'widgets/header/interfaces';
import { Colors } from 'shared/consts/colors';
import { getSkyrexUuid, removeAuthToken, removeSkyrexUuid, removeUserEmail } from 'shared/helpers/storage-helper';
import { AccountSolid, Lock, LogOut, Locker } from 'shared/icons';
import { Link, Loader, MainButton, Text, TwoFactor, TwoFactorDisable, TwoFactorFailed, TwoFactorMessage } from 'shared/ui';
import { getTwoFactorToken } from '../api/get-two-factor-token';
import { getUser } from '../api/get-user';
import { privacyPolicy, terms } from '../consts';
import { background, contentWrapepr, emailStyles, socialStyles } from '../styles';

export const UserMenuDropdown = (props: IUserMenuProps) => {
  const {
    handleOpenChangePasswordModal,
  } = props;
  
  const [email, setEmail] = useState<string>('');
  const [faModalOpen, setFaModalOpen] = useState<boolean>(false);
  const [twoFactorDisableModalOpen, setTwoFactorDisableModalOpen] = useState<boolean>(false);
  const [generatedToken, setGeneratedToken] = useState<string>('');
  const [qrImageSource, setQrImageSource] = useState<string>('');
  const [twoFactorSuccessFullyEnabled, setTwoFactorSuccessFullyEnabled] = useState<boolean>(false);
  const [twoFactorSuccessFullyDisabled, setTwoFactorSuccessFullyDisabled] = useState<boolean>(false);
  const [isTwoFactorEnabled, setTwoFactorEnabled] = useState<boolean>(false);
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [twoFactorEnableFailed, setTwoFactorEnableFailed] = useState<boolean>(false);
  const [twoFactorDisableFailed, setTwoFactorDisableFailed] = useState<boolean>(false);
  const [twoFactorResetModalOpen, setTwoFactorResetModalOpen] = useState<boolean>(false);
  const [generateTokenFailedOpen, setGenerateTokenFailedOpen] = useState<boolean>(false);
  
  const handleCloseTwoFactorEnableFailed = () => setTwoFactorEnableFailed(false); 
  const handleCloseTwoFactorDisableFailed = () => setTwoFactorDisableFailed(false);
  const handleSetTwoFactorEnableFailedOpen = () => setTwoFactorEnableFailed(true);
  const handleSetTwoFactorDisableFailedOpen = () => setTwoFactorDisableFailed(true);
  const handleSetTwoFactorResetModalOpen = () => setTwoFactorResetModalOpen(true);
  const handleSetTwoFactorResetModalClose = () => setTwoFactorResetModalOpen(false);
  const handleSetGenerateTokenErrorOpen = () => setGenerateTokenFailedOpen(true);
  const handleSetGenerateTokenErrorClose = () => setGenerateTokenFailedOpen(false);  
  
  const handleCloseReset = () => {
    handleSetTwoFactorModalClose();
    handleSetTwoFactorResetModalClose();
  };
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socialClickHandler = (socialLink: string) => () => {
    window.open(socialLink, '_blank');
  };
  
  const handleSetTwoFactorEnabled = () => {
    setTwoFactorSuccessFullyEnabled(true);
    setSwitchChecked(true);
  };

  const handleSetTwoFactorDisabled = () => {
    setTwoFactorSuccessFullyDisabled(true);
    setSwitchChecked(false);
  };

  const handleCloseTwoFactorEnabledModal = () => {
    setTwoFactorSuccessFullyEnabled(false);
  };

  const handleCloseTwoFactorDisabledModal = () => {
    setTwoFactorSuccessFullyDisabled(false);
  };

  const generateTokenTwoFactor = async () => {
    try {
      const responseFromGenerateToken = await getTwoFactorToken();

      if (!responseFromGenerateToken.success) {
        handleSetFaModalClose();
        handleSetGenerateTokenErrorOpen();
        return;
      }

      const qrImageSource = responseFromGenerateToken.data.uri2fa;

      setQrImageSource(qrImageSource);
      setGeneratedToken(responseFromGenerateToken.data.token2fa);
    } catch (error) {
      handleSetFaModalClose();
      handleSetGenerateTokenErrorOpen();
      return;
    }
  };

  const setUserData = async () => {
    setIsLoading(true);
    try {
      const skyrexUserUuid = getSkyrexUuid();
      const responseFromUserGet = await getUser([skyrexUserUuid ?? '']);
      
      if ((!responseFromUserGet?.success || !responseFromUserGet.data?.users?.length)) {
        return;
      }
      
      const { 
        email, 
        is2faEnabled, 
      } = responseFromUserGet.data.users[0];

      if (email) {
        setEmail(email);
      }

      if (is2faEnabled) {
        setTwoFactorEnabled(is2faEnabled);
        setSwitchChecked(is2faEnabled);
      }
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    setUserData();
  }, []);
  
  useEffect(() => {
    if (faModalOpen) {
      generateTokenTwoFactor();
    }
  }, [faModalOpen]);

  const handleSetFaModalClose = () => {
    setFaModalOpen(false);
    setSwitchChecked(!!isTwoFactorEnabled);
  };
  
  const handleSetTwoFactorModalClose = () => {
    setTwoFactorDisableModalOpen(false);
    setSwitchChecked(!!isTwoFactorEnabled);
  };

  const logOutHandler = () => {
    removeAuthToken();
    removeUserEmail();
    removeSkyrexUuid();
    dispatch(logout());
    navigate('/login');
  };

  const changeModeHandler = (checked: boolean) => {
    if (checked) {
      setFaModalOpen(true);
      return;
    } 
    
    setTwoFactorDisableModalOpen(true);
  };

  return (
    <Box sx={background}>
      {isLoading && <Loader isContentOverflow={true} />}
      <Box display='flex' flexDirection='column' gap={7}>
        <Box display='flex' flexDirection='column' gap={4}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='center'
            alignItems='center'
            gap={2.4}
            bgcolor='#F2F2F2'
            borderRadius={2}
            padding='20px 18px'
          >
            {AccountSolid}

            <Text styles={emailStyles}>{email}</Text>
          </Box>

          <Box display='flex' justifyContent='space-between'>
            <Box sx={contentWrapepr}>
              <Space size={20}>
                <Box display='flex' alignItems='center'>
                  {Locker}
                </Box>

                <Text>2FA authentication</Text>
              </Space>

              <Space
                onClick={handleOpenChangePasswordModal}
                size={20}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Box display='flex' alignItems='center'>
                  {Lock}
                </Box>

                <Text>Change password</Text>
              </Space>

              <Space
                size={20}
                onClick={logOutHandler}
                style={{
                  cursor: 'pointer',
                }}
              >
                <Box display='flex' alignItems='center'>
                  {LogOut}
                </Box>
                <Text>Log out</Text>
              </Space>
            </Box>

            <Switch checked={switchChecked} onChange={changeModeHandler} />
          </Box>
        </Box>

        <Box display='flex' flexDirection='column' gap={4}>
          <Space
            size={4}
            wrap
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: 188,
              alignSelf: 'center',
            }}
          >
            {socials.map((social: ISocial) => (
              <MainButton
                key={social.link}
                styles={socialStyles}
                type='text'
                onClick={socialClickHandler(social.link)}
              >
                {social.icon}
              </MainButton>
            ))}
          </Space>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            textAlign='center'
            gap={3}
          >
            <Space
              wrap
              size={16}
              style={{
                maxWidth: 235,
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            >
              {userMenuLinks.map((userMenuLink: IUserMenuLink) => (
                <Link key={userMenuLink.label} href={userMenuLink.link} type='success'>
                  {userMenuLink.label}
                </Link>
              ))}
            </Space>

            <Space
              size={3}
              style={{
                justifyContent: 'center',
              }}
            >
              <Link href={terms} type='success'>
                Terms
              </Link>

              <Text type='secondary'>and</Text>

              <Link href={privacyPolicy} type='success'>
                Privacy Policy
              </Link>
            </Space>
          </Box>
        </Box>
      </Box>

      <TwoFactor
        isOpen={faModalOpen}
        onClose={handleSetFaModalClose}
        generatedToken={generatedToken}
        qrImageSource={qrImageSource}
        handleSetTwoFactorEnabled={handleSetTwoFactorEnabled}
        handleSetTwoFactorDisabled={handleSetTwoFactorDisabled}
        handleSetTwoFactorEnableFailedOpen={handleSetTwoFactorEnableFailedOpen}
      />
      
      <TwoFactorDisable
        isOpen={twoFactorDisableModalOpen}
        onClose={handleSetTwoFactorModalClose}
        handleSetTwoFactorDisabled={handleSetTwoFactorDisabled}
        handleSetTwoFactorDisableFailedOpen={handleSetTwoFactorDisableFailedOpen}
        handleSetTwoFactorResetModalOpen={handleSetTwoFactorResetModalOpen}
      />

      <TwoFactorMessage
        isOpen={twoFactorSuccessFullyDisabled}
        title='Google authenticator disabled'
        handleClose={handleCloseTwoFactorDisabledModal}
      />

      <TwoFactorMessage
        isOpen={twoFactorSuccessFullyEnabled}
        title='Google authenticator enabled'
        handleClose={handleCloseTwoFactorEnabledModal}
      />

      <TwoFactorMessage
        isOpen={twoFactorResetModalOpen}
        withIcon={false}
        handleClose={handleCloseReset}
        title='Verify account ownership'
        text={
          <>
            Contact support at 
            <a 
              style={{
                color: Colors.GREEN, 
                textDecoration: 'underline', 
              }} 
              href='mailto:info@skyrexio.com'> info@skyrexio.com
            </a> to proceed with 2FA reset.
          </>
        }
      />

      <TwoFactorFailed
        isOpen={generateTokenFailedOpen}
        title='Failed to generate 2FA'
        description='Something went wrong. Try again or contact support'
        closeHandler={handleSetGenerateTokenErrorClose}
      />
      
      <TwoFactorFailed
        isOpen={twoFactorDisableFailed}
        title='Failed to disable 2FA'
        closeHandler={handleCloseTwoFactorDisableFailed}
      />

      <TwoFactorFailed
        isOpen={twoFactorEnableFailed}
        title='Failed to enable 2FA'
        closeHandler={handleCloseTwoFactorEnableFailed}
      />
    </Box>
  );
};
