import { configureStore } from '@reduxjs/toolkit';
import { botReducer } from 'entities/bot/model/slices/bot-slice';
import { exchangeReducer } from 'entities/exchange/model/slices/exchange-slice';
import { terminalReducer } from 'entities/terminal/model/slices/terminal-slice';
import { userReducer } from 'entities/user/model/slices/user-slice';
import { StateSchema } from '../types/state-schema';

export function createReduxStore(initialState?: StateSchema) {
  return configureStore<StateSchema>({
    reducer: {
      user: userReducer,
      exchange: exchangeReducer,
      terminal: terminalReducer,
      bot: botReducer,
    },
    devTools: true,
    preloadedState: initialState,
  });
}
