import { CSSProperties } from 'react';

export const wrapper: CSSProperties = {
  padding: 8,
  borderRadius: '5px',
  border: '1px solid #E9E9E9',
  backgroundColor: '#fff',
  cursor: 'pointer',
  alignSelf: 'flex-start',
  display: 'flex',
};
