import { Navigate } from 'react-router-dom';
import { getAuthToken } from 'shared/helpers/storage-helper';
import { RouteGuardProps } from '../model/types/route-guard';

export const GuestRoute: React.FC<RouteGuardProps> = ({
  children,
}) => {
  const isAuthenticated = getAuthToken();

  if (!isAuthenticated) {
    return (
      <>
        {children}
      </>
    );
  }

  return <Navigate to='/home' replace />;
};
