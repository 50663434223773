import { SxProps, Theme } from '@mui/material';

export const itemIcon: SxProps<Theme> = {
  display: 'flex',
  alignSelf: 'center',
  width: 50,
  height: 50,
  '&>img': {
    width: '100%',
    height: '100%',
  },
};
