export const HOME = 'home';
export const MY_ACCOUNTS = 'my-accounts';
export const MANUAL_TRADING = 'manual-trading';
export const TRADING_TERMNIAL = 'trading-terminal';
export const TRADING_HISTORY = 'trading-diary';
export const INTRODUCTION = 'introduction';
export const TRADING_BOTS = 'trading-bots';
export const MY_BOTS = 'my-bots';
export const ACTIVE_TRADES = 'active-trades';
export const MARKETPLACES = 'marketplace';
export const SALES = 'sales';
export const SUBSCRIPTIONS = 'subscriptions';
export const INVITE_FRIENDS = 'invite-friends';
export const SUPPORT = 'support';
export const SETTINGS = 'settings';
export const EXIT = 'exit';
export const CHANGE_PASSWORD = 'change-password';
