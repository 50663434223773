import { Box } from '@mui/material';
import { Modal } from 'antd';
import { MainButton, Text, Title } from 'shared/ui';
import { IBotMessage } from '../interfaces';
import { button, modal, modalChildren, text, titleStyles, wrapper } from '../styles';

export const BotMessage = (props: IBotMessage) => {
  const {
    isOpened,
    title,
    description,
    closeHandler,
  } = props;

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={titleStyles}>
          {title}
        </Title>

        <Text styles={text}>
          {description}
        </Text>

        <MainButton
          onClick={closeHandler}
          styles={button}
        >
          Ok
        </MainButton>
      </Box>
    </Modal>
  );
};
