export const Search = (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <g clipPath='url(#clip0_51_6428)'>
      <path d='M14.7368 15.2365L10.9473 11.4471M12.6315 7.23653C12.6315 8.01064 12.479 8.77717 12.1828 9.49235C11.8866 10.2075 11.4524 10.8574 10.905 11.4047C10.3576 11.9521 9.70778 12.3863 8.9926 12.6826C8.27741 12.9788 7.51089 13.1313 6.73678 13.1313C5.96267 13.1313 5.19614 12.9788 4.48096 12.6826C3.76578 12.3863 3.11595 11.9521 2.56857 11.4047C2.02119 10.8574 1.58699 10.2075 1.29075 9.49235C0.994513 8.77717 0.842041 8.01064 0.842041 7.23653C0.842041 5.67315 1.46309 4.1738 2.56857 3.06833C3.67405 1.96285 5.1734 1.3418 6.73678 1.3418C8.30016 1.3418 9.79951 1.96285 10.905 3.06833C12.0105 4.1738 12.6315 5.67315 12.6315 7.23653Z' stroke='#2ECD99' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_51_6428'>
        <rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>
);
