import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { BotTradingParameters } from '../types/create-new-bot.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const createNewCopyBot = async ({
  tradingAmount, botUuid, exchangeCode, exchangeAccountUuid, botName, 
}: BotTradingParameters) => {
  const skyrexUserUuid = getSkyrexUuid();
    
  const response = await axios.request({
    url: 'bot/createBot',
    data: {
      name: botName,
      skyrexUserUuid: skyrexUserUuid,
      exchangeAccountUuid: exchangeAccountUuid,
      exchangeCode: exchangeCode,
      botType: 'COPY',
      copyFromBotUuid: botUuid,
      tradingAmount: +tradingAmount,
    },
  });
    
  return response?.data;
};
