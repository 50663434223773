import { SxProps, Theme } from '@mui/material';
import { Colors } from 'shared/consts/colors';

export const item = (isSelected: boolean) => {
  const styles: SxProps<Theme> = {
    cursor: 'pointer',
    flex: 1,
    maxWidth: {
      xs: '84px',
      sm: '95px',
    },
    '&>svg': {
      width: '100%',
      '&>rect:last-of-type': {
        stroke: isSelected ? Colors.GREEN : Colors.DARK_GREY,
        strokeOpacity: isSelected ? '1' : '0.10',
      },
    },
  };

  return styles;
};
