import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const notify: SxProps<Theme> = {
  padding: '9px 16px',
  fontSize: getFontSize(10, 12),
  fontWeight: 400,
  textAlign: 'center',
  backgroundColor: '#F2F2F2',
  borderRadius: '4px',
  '&>strong': {
    fontWeight: 700,
  },
};
