export const LogoAuth = (
  <svg width='185' height='61' viewBox='0 0 185 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M11.0388 38.6871C15.7744 38.6871 18.7043 35.8908 18.7043 31.8863C18.7043 28.4341 16.2854 26.3973 13.0148 24.9474L10.8004 23.9808C8.2452 22.8416 7.22313 21.6333 7.22313 19.8382C7.22313 17.8014 9.02878 16.6276 11.0729 16.6276C12.2578 16.6276 13.1961 16.8883 14.0141 17.3854H18.0534C16.9117 15.2548 14.0028 13.6242 11.1751 13.6242C7.59789 13.6242 4.12287 16.0408 4.12287 19.7691C4.12287 22.8416 5.7241 25.12 9.40354 26.708L11.618 27.6746C14.2072 28.8139 15.57 29.9876 15.57 32.2315C15.57 34.3719 13.7984 35.6837 11.0048 35.6837C9.36929 35.6837 7.9152 35.032 6.79165 34.1239H2.79736C4.26952 37.1344 7.52975 38.6871 11.0388 38.6871Z' fill='white' />
    <path d='M15.6158 40.4235H19.6872L25.6721 32.5811L30.2408 38.6756H34.3122L27.7893 30.1556L35.3214 20.2111H31.2907L25.7129 27.6492L19.1885 19.1443H15.1578L23.555 30.1556L15.6158 40.4235Z' fill='white' />
    <path d='M43.4243 9.94453L41.3074 12.37L35.3232 20.2125H31.2913L39.1906 9.94453L41.2667 7.43819L46.9253 0H50.9554L43.4243 9.94453Z' fill='white' />
    <path d='M12.1329 50.3693L10.0161 52.7948L4.03185 60.6372H-7.55045e-05L7.89919 50.3693L9.97535 47.8629L15.6339 40.4247H19.6641L12.1329 50.3693Z' fill='white' />
    <path d='M59.837 42.0212C64.4271 42.0212 67.2907 39.387 67.2907 36.042C67.2907 32.9479 65.3957 31.0664 61.8162 29.7702L60.0897 29.143C57.6472 28.2649 57.0155 27.2615 57.0155 25.9653C57.0155 24.5018 58.4052 23.6656 60.0054 23.6656C61.6478 23.6656 62.8269 24.46 63.5007 25.8398L66.3221 24.2092C65.0167 21.6586 62.7006 20.4461 60.0054 20.4461C56.1733 20.4461 53.4361 22.8712 53.4361 26.0071C53.4361 28.5158 54.7415 30.8155 58.4473 32.1953L60.0897 32.8225C62.5742 33.7005 63.6691 34.5786 63.6691 36.1675C63.6691 37.7563 61.9847 38.8016 59.9212 38.8016C57.6893 38.8016 56.3418 37.7563 55.2469 35.9166L52.3833 37.8399C54.0678 40.6414 56.7208 42.0212 59.837 42.0212Z' fill='white' />
    <path d='M74.9095 41.6867H78.5311V31.2336L88.6799 41.6867H93.5648L82.8265 30.6064L92.5963 20.7806H87.8377L78.5311 30.2301V12H74.9095V41.6867Z' fill='white' />
    <path d='M100.754 49.3801H104.586L117.514 20.7806H113.598L106.987 36.0002L100.417 20.7806H96.5008L105.091 39.9306L100.754 49.3801Z' fill='white' />
    <path d='M123.698 41.6867H127.319V27.4705C128.246 25.2963 130.309 24.1674 132.583 24.1674C133.341 24.1674 134.015 24.251 134.605 24.4182V20.6969C134.141 20.6133 133.636 20.5715 133.131 20.5715C130.772 20.5715 128.751 21.4496 127.319 23.2475V20.7806H123.698V41.6867Z' fill='white' />
    <path d='M150.142 42.0212C153.806 42.0212 156.796 40.5159 158.606 38.6344L156.29 36.1256C154.943 37.4636 152.795 38.6344 150.142 38.6344C146.563 38.6344 143.446 36.1256 143.067 32.1117H160.164C160.164 25.2545 156.206 20.4461 149.889 20.4461C143.994 20.4461 139.319 25.1708 139.319 31.2336C139.319 37.2546 143.91 42.0212 150.142 42.0212ZM149.679 23.7492C152.879 23.7074 155.574 25.5472 156.08 28.9758H143.278C144.12 25.2963 147.068 23.7492 149.679 23.7492Z' fill='white' />
    <path d='M164.197 41.6867H168.408L174.598 33.5751L180.789 41.6867H185L176.788 31.0664L184.579 20.7806H180.41L174.641 28.474L168.787 20.7806H164.618L172.409 31.0664L164.197 41.6867Z' fill='white' />
    {' '}

  </svg>
);
