import { Box } from '@mui/material';
import { Input as BaseInput } from 'antd';
import { ChangeEvent } from 'react';
import { IInputProps } from '../interfaces';
import { input } from '../styles';

export const Input = (props: IInputProps) => {
  const {
    value,
    onChange,
    placeholder,
    icon,
    disabled,
    maxWidth,
    styles,
    addonBefore,
    addonAfter,
  } = props;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box
      flex={1}
      maxWidth={maxWidth}
      sx={styles}
    >
      <BaseInput
        size='large'
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        style={input}
        suffix={(
          <div style={{
            fontSize: 12,
            fontWeight: 400,
          }}>
            {icon}
          </div>
        )}
        disabled={disabled}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
      />
    </Box>
  );
};
