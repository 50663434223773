import { Box } from '@mui/material';
import { Modal } from 'antd';
import { IBotSubscription, ISkyrexSubscription } from 'pages/subscriptions/subscription/interfaces';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { termService } from 'shared/consts';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { CheckBox, Link, MainButton, Text, Title } from 'shared/ui';
import { SubscriptionMessage } from '../../subscription-message';
import { createCoinBaseCheckoutPayment, createSkyrexSubscription, createStripeTransaction } from '../api';
import { getSubscriptionPaymentInfo, openRedirectUrl } from '../helpers';
import { ICreateSkyrexSubscription, IPayment, ISubscriptionAction } from '../interfaces';
import { inner, modal, modalChildren, purchase, text, title, wrapper } from '../styles';
import { Bot, Skyrex } from '../views';

export const SubscriptionAction = (props: ISubscriptionAction) => {
  const {
    isOpen,
    contentType,
    subscription,
    isUpdate,
    closeHandler,
    plan,
    openCoinPaymentModal,
  } = props;

  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [promo, setPromo] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<IPayment['title'] | null>(null);
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();

  const resetError = () => {
    setError('');
  };

  const changePaymentMethod = (method: IPayment['title']) => {
    return () => {
      setPaymentMethod(method);
    };
  };

  const changePromo = (value: string) => {
    setPromo(value);
  };

  const changeAgree = (value: boolean) => {
    setIsAgree(value);
  };

  const createCoinBaseCheckoutPaymentHandler = async (subscriptionUuid: string, table?: string) => {
    const response = await createCoinBaseCheckoutPayment(subscriptionUuid, table);
    if (!response?.data?.statusUrl) {
      return;
    }
    
    openRedirectUrl(response?.data?.statusUrl, '_blank');
    closeHandler();
    openCoinPaymentModal?.();
  };

  const makePayemnt = async () => {
    if (contentType === 'skyrex') {
      const {
        method,
        asset,
      } = getSubscriptionPaymentInfo(paymentMethod!);

      const params: ICreateSkyrexSubscription = {
        skyrexUserUuid: getSkyrexUuid()!,
        planName: plan?.planName! || subscription?.planName,
        paymentMethod: method,
        paymentAsset: asset,
      };
      
      if (promo) {
        params.promocode = promo;
      }

      const response = await createSkyrexSubscription(params);
      const skyrexSubscriptionFields = response.data?.fields;
      if (!skyrexSubscriptionFields || response.status === 403) {
        setError(response.data.message);

        return;
      }

      const [createdSubscription] = skyrexSubscriptionFields;

      if (paymentMethod === 'crypto') {
        createCoinBaseCheckoutPaymentHandler(createdSubscription.subscriptionUuid, 'skyrex_subscriptions');

        return;
      }

      const stripeResponseData = await createStripeTransaction(createdSubscription.subscriptionUuid);
      openRedirectUrl(stripeResponseData?.data?.redirectUrl);

      return;
    }

    navigate(location.pathname);
    createCoinBaseCheckoutPaymentHandler(subscription?.subscriptionUuid!);
  };

  const getModalContent = () => {
    switch (contentType) {
    case 'skyrex':
      return (
        <Skyrex
          subscription={subscription as ISkyrexSubscription}
          isUpdate={!!isUpdate}
          plan={plan!}
          promo={promo}
          changePromo={changePromo}
          selectedPayentMethod={paymentMethod}
          changePaymentMethod={changePaymentMethod}
        />
      );
    case 'bot':
      return (
        <Bot subscription={subscription as IBotSubscription} />
      );
    default:
      return null;
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsAgree(false);
      setPromo('');
      setPaymentMethod(null);
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          Order details
        </Title>

        {getModalContent()}

        <Box sx={inner}>
          <CheckBox
            value={isAgree}
            onChange={changeAgree}
            aligment='flex-start'
            suffix={(
              <Box sx={text}>
                <Text>
                  I accept {' '}
                  <Link
                    href={termService}
                    target='_blank'
                    type='success'
                  >
                    Terms of use
                  </Link>, {' '}
                  agree to payment amount and understand it can’t be refunded according to {' '}
                  <Link
                    href={termService}
                    target='_blank'
                    type='success'
                  >
                    Refund Policy
                  </Link>
                </Text>
              </Box>
            )}
          />

          <MainButton
            type='primary'
            size='large'
            styles={purchase}
            disabled={!isAgree || (contentType === 'skyrex' && !paymentMethod)}
            onClick={makePayemnt}
          >
            Purchase
          </MainButton>
        </Box>
      </Box>

      <SubscriptionMessage
        isOpen={!!error}
        closeHandler={resetError}
        text={[
          'Can’t downgrade',
          'Subscription can’t be downgraded please choose higher plan to upgrade',
        ]}
      />
    </Modal>
  );
};
