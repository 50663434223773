import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { theme } from './shared/api/consts';
import './shared/styles/index.css';
import { StoreProvider } from 'app/providers/store-provider/ui/store-provider';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <StoreProvider>
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </StoreProvider>,
);
