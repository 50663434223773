import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 7,
  padding: {
    xs: '40px 0',
    md: '70px 0',
  },
};
