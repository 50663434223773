export const Candles = (
  <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='2.25' y='12.25' width='6.75' height='11.75' rx='1' stroke='#2ECD99' strokeWidth='2' />
    <line x1='5.75' y1='12' x2='5.75' y2='9.25' stroke='#2ECD99' strokeLinecap='round' />
    <line x1='5.75' y1='27' x2='5.75' y2='24.25' stroke='#2ECD99' strokeLinecap='round' />
    <rect x='12.25' y='3.5' width='6.75' height='11.75' rx='1' stroke='#2ECD99' strokeWidth='2' />
    <line x1='15.75' y1='3.25' x2='15.75' y2='0.5' stroke='#2ECD99' strokeLinecap='round' />
    <line x1='15.75' y1='18.25' x2='15.75' y2='15.5' stroke='#2ECD99' strokeLinecap='round' />
    <rect x='22.25' y='14.75' width='6.75' height='11.75' rx='1' stroke='#2ECD99' strokeWidth='2' />
    <line x1='25.75' y1='14.5' x2='25.75' y2='11.75' stroke='#2ECD99' strokeLinecap='round' />
    <line x1='25.75' y1='29.5' x2='25.75' y2='26.75' stroke='#2ECD99' strokeLinecap='round' />
  </svg>
);
