import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getFinalHeaders } from './headers';
import { setupRequestInterceptors, setupResponseInterceptors } from './interceptors';
import { applyRetryConfiguration } from './retry-config';
import { API_URL, DEFAULT_METHOD, DEFAULT_RESPONSE_TYPE, DEFAULT_TIMEOUT } from './settings';

interface AxiosConfig extends AxiosRequestConfig {
  baseURL?: string;
  timeout?: number;
  headers?: {
    'Content-Type'?: string;
    [header: string]: string | undefined;
  };
  withCredentials?: boolean;
}

const createAxiosInstance = (config: AxiosConfig): AxiosInstance => {
  const {
    baseURL = API_URL,
    method = DEFAULT_METHOD,
    timeout = DEFAULT_TIMEOUT,
    headers = {},
    withCredentials = false,
    responseType = DEFAULT_RESPONSE_TYPE,
    onDownloadProgress,
  } = config;

  const proxyBaseURL = baseURL;
  
  const finalHeaders = getFinalHeaders(headers);

  const axiosInstance = axios.create({
    baseURL: proxyBaseURL,
    method,
    timeout,
    headers: finalHeaders,
    withCredentials,
    responseType,
    onDownloadProgress,
  });

  applyRetryConfiguration(axiosInstance);
  setupRequestInterceptors(axiosInstance);
  setupResponseInterceptors(axiosInstance);

  return axiosInstance;
};

export default createAxiosInstance;
