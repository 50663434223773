import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { UpdateExchangeRequestProps } from '../types/update-exchange';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const updateExchange = async (props: UpdateExchangeRequestProps) => {
  const {
    exchangeAccountUuid,
    accountName,
    exchangeCode,
    apiKey,
    apiSecret,
    passphrase,
  } = props;

  const response = await axios.request({
    url: '/exchangeAccount/update',
    data: {
      exchangeAccountUuid,
      exchangeCode,
      accountName,
      apiKey,
      apiSecret,
      passphrase,
    },
  });

  return response.data;
};
