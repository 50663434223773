import { Button } from 'antd';
import { IProps } from '../interfaces';

export const MainButton = (props: IProps) => {
  const {
    loading,
    children,
    size,
    styles,
    icon,
    type,
    onClick,
    ghost,
    iconPosition,
    disabled,
    htmlType,
  } = props;

  return (
    <Button
      loading={loading}
      disabled={disabled || loading}
      type={type}
      size={size}
      style={styles}
      ghost={ghost}
      onClick={onClick}
      icon={icon}
      htmlType={htmlType}
      iconPosition={iconPosition}
    >
      {children}
    </Button>
  );
};
