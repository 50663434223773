import dayjs from 'dayjs';
import { getExchanges } from '../../../widgets/exchanges/api/get-exchanges';
import { getSnapshots } from '../api/get-snapshots';
import { ExchangeAccount } from '../model/types/exchange-account';
import { ExchangeSnapshot } from '../types/snapshot.types';

// @TODO optimize and unify similar helper in home page
export async function getSingleExchangePageData(skyrexUserUuid: string, exchangeAccountUuid: string) {
  const singleAccountData = await getExchanges([exchangeAccountUuid]);
  
  const singleAccountSnapshots = await getSnapshots(skyrexUserUuid, [exchangeAccountUuid]);
  
  if (!singleAccountData || !singleAccountData?.data?.accounts?.length) {
    return [];
  }
  
  const now = dayjs();
  const twentyFourHoursAgo = now.subtract(24, 'hour');
  
  const snapshotMapping = singleAccountSnapshots.data.accounts.reduce((acc: any, snapshot: ExchangeSnapshot) => {
    const snapshotDate = dayjs(snapshot.createdDate);
    
    if (snapshotDate.isAfter(twentyFourHoursAgo)) {
      if (!acc[snapshot.exchangeAccountUuid] || snapshotDate.isAfter(dayjs(acc[snapshot.exchangeAccountUuid].createdDate))) {
        acc[snapshot.exchangeAccountUuid] = snapshot;
      }
    }
    
    return acc;
  }, {});
    
  const mergedAccounts = singleAccountData.data.accounts.map((account: ExchangeAccount) => {
    const snapshot = snapshotMapping[account.exchangeAccountUuid];
    
    if (snapshot) {
      const usdtDailyChangeValue = parseFloat(account.totalUsdt) - parseFloat(snapshot.totalUsdt);
      const usdtDailyChangePercent = ((usdtDailyChangeValue / parseFloat(snapshot.totalUsdt)) * 100).toFixed(2);
      const btcDailyChangeValue = parseFloat(account.totalBtc) - parseFloat(snapshot.totalBtc);
      const btcDailyChangePercent = ((btcDailyChangeValue / parseFloat(snapshot.totalBtc)) * 100).toFixed(2);

      account.usdtDailyChangePercent = usdtDailyChangePercent;
      account.usdtDailyChangeValue = usdtDailyChangeValue.toFixed(2);
      account.btcDailyChangePercent = btcDailyChangePercent;
      account.btcDailyChangeValue = btcDailyChangeValue.toFixed(8);
  
      return account;  
    }
    
    return account;
  });
  
  return mergedAccounts?.[0];
}
