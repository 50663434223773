import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { ConnectExchangeProps } from '../types/connect-exchange.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const connectExchange = async (props: ConnectExchangeProps) => {
  const {
    uuid,
    exchangeTitle,
    exchangeName,
    apiKey,
    apiSecret,
    passphrase,
  } = props;

  const response = await axios.request({
    url: '/exchangeAccount/create',
    data: {
      skyrexUserUuid: uuid,
      exchangeCode: exchangeName,
      accountName: exchangeTitle,
      apiKey,
      apiSecret,
      passphrase,
    },
  });

  return response.data;
};
