import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  border: '1px solid rgba(58, 58, 60, 0.15)',
  borderRadius: '10px',
  padding: 2,
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: '0px 1px 25px 0px rgba(0, 0, 0, 0.08)',
  flex: 1,
};
