export const getCapitalizedExchangeTitle = (exchange: string) => {
  if (typeof exchange !== 'string' || exchange.length === 0) {
    return '';
  }
    
  if (exchange === 'okx') {
    return exchange.toUpperCase();
  }
    
  return exchange.charAt(0).toUpperCase() + exchange.slice(1);
};
