import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { UpdateOauthProps } from '../types/oauth.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const updateOauth = async (props: UpdateOauthProps) => {
  const {
    exchangeAccountUuid,
    userAccessToken,
    accountName,
    exchangeCode,
  } = props;

  const response = await axios.request({
    url: '/exchangeAccount/updateOauth',
    data: {
      exchangeAccountUuid,
      userAccessToken,
      accountName,
      exchangeCode,
    },
  });

  return response.data;
};

